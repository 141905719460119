import { Container } from '@mui/material';
import { PositionCard } from '~/components/ui/position-card';
import { H2 } from '~/components/ui/typography';
import { PositionType } from '~/pages';

export type PositionsProps = {
  data: PositionType[];
};

export const Positions = ({ data }: PositionsProps) => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient2 from-gradient1">
      <Container fixed>
        <H2 className="mb-10 text-center">Open Positions</H2>
        <div className="flex flex-wrap gap-8 justify-center">
          {data.map((position, index) => {
            const { positionTitle, location, positionType, slug, date } = position;
            return (
              <PositionCard
                key={index}
                positionTitle={positionTitle}
                location={location}
                positionType={positionType}
                date={date}
                slug={slug}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};
