import { Box, SxProps } from '@mui/material';
import { match } from 'ts-pattern';

import { ColorDot } from '~/components/ColorDot';

const ALL_STATUSES = {
  processed: {
    value: 'processed',
    label: 'Processed',
    color: 'green',
  },
  possible_fraud: {
    value: 'possible_fraud',
    label: 'Possible Fraud',
    color: 'yellow',
  },
  processing: {
    value: 'processing',
    label: 'Processing...',
    color: 'blue',
  },
  failed: {
    value: 'failed',
    label: 'Failed',
    color: 'red',
  },
  Active: {
    value: 'active',
    label: 'Active',
    color: 'green'
  },
  Inactive: {
    value: 'inactive',
    label: 'Inactive',
    color: 'red'
  },
  Regular: {
    value: 'regular',
    label: 'Regular',
    color: 'blue'
  },
  Premium: {
    value: 'premium',
    label: 'Premium',
    color: 'yellow'
  },
  Diesel: {
    value: 'diesel',
    label: 'Diesel',
    color: 'green'
  },
  Gas: {
    value: 'gas',
    label: 'Gas',
    color: 'red'
  },
  DEF: {
    value: 'def',
    label: 'DEF',
    color: 'gray'
  },
  DEFD: {
    value: 'defd',
    label: 'DEFD',
    color: 'gray'
  },
  ULSD: {
    value: 'ulsd',
    label: 'ULSD',
    color: 'green'
  },
  ULSR: {
    value: 'ulsr',
    label: 'ULSR',
    color: 'green'
  },
  RFR: {
    value: 'rfr',
    label: 'RFR',
    color: 'red'
  },
  SupremePlus: {
    value: 'Supreme Plus',
    label: 'Supreme Plus',
    color: 'yellow'
  },
  GAS: {
    value: 'gas',
    label: 'Gas',
    color: 'red'
  },
  SSSale: {
    value: 'sssale',
    label: 'SS Sale',
    color: 'green'
  },
  NULL: {
    value: 'null',
    label: 'NULL',
    color: 'gray'
  },
  UNPAID: {
    value: 'unpaid',
    label: 'Unpaid',
    color: 'red'
  },
  PAID: {
    value: 'paid',
    label: 'Paid',
    color: 'green'
  },
  Cancelled : {
    value: 'cancelled',
    label: 'Cancelled',
    color: 'red'
  },
  NSF: {
    value: 'nsf',
    label: 'NSF',
    color: 'red'
  },
  MidGrade: {
    value: 'midgrade',
    label: 'Mid-Grade',
    color: 'yellow'
  },
  Yes: {
    value: 'yes',
    label: 'Yes',
    color: 'green'
  },
  No: {
    value: 'no',
    label: 'No',
    color: 'red'
  },
} as const;
export const ALL_STATUSES_LIST = Object.values(ALL_STATUSES);
export type StatusLabel = keyof typeof ALL_STATUSES;

const normalizeStatusKey = (input: string): keyof typeof ALL_STATUSES | undefined => {
  if (!input) {
      return ALL_STATUSES.NULL.label;
  }
  const normalizedInput = input.replace(/[\s-]+/g, ''); // Remove spaces
  const keys = Object.keys(ALL_STATUSES) as Array<keyof typeof ALL_STATUSES>;

  const key = keys.find(k => k.replace(/\s+/g, '') === normalizedInput);
  return key;
};


export const statusMatcher = (value: string) => {
  const key = normalizeStatusKey(value);
  if (!key) {
      throw new Error(`No status found for the value: ${value}`);
  }
  return ALL_STATUSES[key];
};

export function StatusBadge({
  status,
  customSx = {},
}: {
  status: StatusLabel;
  customSx?: SxProps;
}) {
  const statusMeta = statusMatcher(status);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        padding: 0,
        backgroundColor: `${statusMeta.color}.50`,
        color: `${statusMeta.color}.800`,
        fontWeight: 500,
        borderRadius: 1,
        borderColor: `${statusMeta.color}.200`,
        borderWidth: 1,
        borderStyle: 'solid',
        px: 0.75,
        py: 0.25,
        fontSize: 'xs',
        ...customSx,
      }}
    >
      <ColorDot color={statusMeta.color} />
      {statusMeta.label}
    </Box>
  );
}
