import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer, Header } from '~/components/sections';
import { PositionType } from './CareersLanding';
import client from '~/sanity-client';
import { H4, PL } from '~/components/ui/typography';
import { Container, Divider } from '@mui/material';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { richTextComponents } from '~/components/ui/rich-components';
import { FileUpload } from '~/components/FileUpload';
import { Button } from '~/components/ui/button';

const formData = [
  {
    key: 'name',
    label: 'Name*',
    placeholder: 'Enter your name',
    type: 'text',
  },
  {
    key: 'email',
    label: 'Email*',
    placeholder: 'Enter your email',
    type: 'email',
  },
  {
    key: 'phone',
    label: 'Phone number',
    placeholder: '+1 (555) 000-0000',
    type: 'tel',
  },
  {
    key: 'city',
    label: 'City',
    placeholder: 'Enter your city',
    type: 'text',
  },
  {
    key: 'linkedin',
    label: 'LinkedIn Profile',
    placeholder: 'Type here...',
    type: 'text',
  },
  {
    key: 'website',
    label: 'Website',
    placeholder: 'Type here...',
    type: 'text',
  },
  {
    key: 'resume',
    label: 'Resume',
    placeholder: 'Select resume',
    fileUpload: true,
  },
  {
    key: 'coverLetter',
    label: 'Cover letter',
    placeholder: 'Select cover letter',
    fileUpload: true,
  },
];

export const CareersPositionLanding = () => {
  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const [data, setData] = useState<PositionType | null>(null);
  const [loading, setLoading] = useState(true);

  const GROQ_QUERY_BY_SLUG = `
    *[_type == "job" && slug.current == '${slug}'][0] {
    _id,
    "slug": slug.current,
    positionTitle,
    location,
    department,
    positionType,
    compensation,
    date,
    content
    }`;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      return await client.fetch<PositionType | null>(GROQ_QUERY_BY_SLUG);
    };
    fetchData()
      .then((data) => setData(data))
      .finally(() => setLoading(false));
  }, [GROQ_QUERY_BY_SLUG]);

  if (loading) {
    return 'loading';
  }

  return (
    <div>
      <Header title={data?.positionTitle ?? ''} />
      <div>
        <Container fixed>
          <div className="flex flex-wrap gap-8 pb-16 justify-center">
            <div className="flex flex-col gap-4 w-[300px]">
              <div>
                <p className="text-sm">Location</p>
                <PL className="text-black">{data?.location}</PL>
              </div>
              <Divider />
              <div>
                <p className="text-sm">Type</p>
                <PL className="text-black">{data?.positionType}</PL>
              </div>
              <Divider />
              <div>
                <p className="text-sm">Department</p>
                <PL className="text-black">{data?.department}</PL>
              </div>
              <Divider />
              <div>
                <p className="text-sm">Compensation</p>
                <PL className="text-black">{data?.compensation}</PL>
              </div>
              <Divider />
              <div>
                <p className="text-sm">Date</p>
                <PL className="text-black">{data?.date}</PL>
              </div>
            </div>
            <div className="w-[80%] md:w-[60%]">
              <H4 className="mb-10">Overview</H4>
              <PortableText
                components={richTextComponents as Partial<PortableTextReactComponents>}
                value={data?.content ?? []}
              />
              <H4 className="my-10">Application</H4>
              <div className="flex flex-wrap flex-col justify-between gap-y-5">
                {formData.map((input) => {
                  return (
                    <div key={input.key} className="text-left gap-1 grid">
                      <p className="font-semibold text-sm text-darkGrey mb-1">{input.label}</p>
                      {input.fileUpload ? (
                        <FileUpload placeholder={input.placeholder} />
                      ) : (
                        <input
                          className="border w-full border-[#D0D5DD] rounded-md py-2 px-3 placeholder-[#667085]"
                          type={input.type}
                          placeholder={input.placeholder}
                        />
                      )}
                    </div>
                  );
                })}
                <Button
                  buttonSize="large"
                  buttonType="solid"
                  className="mt-5 w-full rounded-3xl"
                  textColor="white"
                >
                  Submit application
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
