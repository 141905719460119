
import { useEffect, useState } from 'react';
import { DashboardHeader } from '~/components/DashboardHeader';
import InvoiceTable from '~/components/InvoiceTable';
import { PageContainer } from '~/components/PageContainer';
import { useUser } from '~/context/UserContext';
import { Invoice } from '~/data/Invoice';
import DBManager from '~/manager/DBManager';
import { Link } from 'react-router-dom';
import { MoneyCode } from '~/data/MoneyCode';
import MoneyCodeTable from '~/components/MoneyCodeTable';
import { Box, Button, FilledInput, FormControl, FormLabel, Stack } from '@mui/material';
import DrawerSubtle from '~/components/kit/application/overlays/DrawerSubtleActions';


export function MoneyCodes() {

  const [moneyCodeList, setMoneyCodeList] = useState<MoneyCode[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const { user } = useUser();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [codeAmount, setCodeAmount] = useState(0);
  const [codeNotes, setCodeNotes] = useState('');
  const [createCode, setCreateCode] = useState(false);



  useEffect(() => {
    const fetchInfo = async () => {
      try {
        if (!user?.companyID) {
          return;
        }
        const dbManager = new DBManager();
        const moneyCodes = await dbManager.getMoneyCodes(user.companyID, pageSize, page);

        setMoneyCodeList(moneyCodes.codes);
        setTotalRows(moneyCodes.total);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    if (user?.companyID) { // Only fetch invoices if companyID exists
      fetchInfo();

    }
  }, [user?.companyID]); // Include companyID in the dependency array



  const handleCreateCode = async () => {
    const dbManager = new DBManager();
    if (!user?.companyID) {
      return;
    }
    dbManager.createMoneyCode(user.companyID, codeAmount, codeNotes);
    setCreateCode(false);
    setCodeAmount(0);
    setCodeNotes('');

  }

  const handleCloseDrawer = () => {
    setCreateCode(false);
    setCodeAmount(0);
    setCodeNotes('');

  }

  const createDisabled = () => {
    if (codeAmount <= 0 || codeNotes == '') {
      return true;
    }
    return false;
  }





  return (
    <PageContainer>
      <DashboardHeader
        title='Money Codes'
      />

      <Stack
        direction="row"
        justifyContent={'space-between'}
      >
        <Box id="filter-panel" />

        {user?.moneyCodePermission && user.companyStatus == 'ACTIVE' && (
          <Button variant="contained" color="primary" onClick={() => setCreateCode(true)}>
            Create Money Code
          </Button>
        )}
      </Stack>

      {createCode && (
        <DrawerSubtle
          drawerTitle='Create Money Code'
          confirmAction={handleCreateCode}
          closeDrawer={handleCloseDrawer}
          disableAction={createDisabled()}
          buttonTitle='Create Code'

        >

          <FormControl
            fullWidth={true}
          >

            <FormLabel>Notes</FormLabel>
            <FilledInput
              type="text"
              name="Notes"
              value={codeNotes}
              required={true}
              onChange={(e) => setCodeNotes(e.target.value)}
            />


          </FormControl>


          <FormControl
            fullWidth={true}
          >

            <FormLabel>Amount</FormLabel>
            <FilledInput
              type="number"
              name="Code Amount"
              required={true}
              value={codeAmount}
              onChange={(e) => setCodeAmount(Number(e.target.value))}
            />


          </FormControl>


        </DrawerSubtle>

      )}

      <MoneyCodeTable
        tableName=''
        tableData={moneyCodeList}
        totalRows={totalRows}
        handlePageChange={(newPage: number, newPageSize: number) => {
          setPage(newPage);
          setPageSize(newPageSize);
        }}


      />




    </PageContainer>
  );
}

export default MoneyCodes;