import {
  BusinessPotential,
  ClientPortal,
  CustomerReview,
  Header,
  Payout,
} from '~/components/pages/factoring';
import { Pricing } from '~/components/pages/factoring/pricing';
import { CallToAction, Contact, FAQ, Footer } from '~/components/sections';

export const FactoringLanding = () => {
  return (
    <div>
      <Header />
      <BusinessPotential />
      <Payout />
      <ClientPortal />
      <Pricing />
      <CustomerReview />
      <CallToAction
        title="Unlock Financial Freedom with REV"
        text="Discover how our competitive rates and instant payout can <br/> fuel your success."
      />
      <FAQ className="rounded-none" />
      <Contact className="bg-gradient1 to-inherit from-inherit" />
      <Footer />
    </div>
  );
};
