export const CubeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.82 5.24658L13.7267 5.07992C13.6063 4.87838 13.4365 4.71087 13.2333 4.59325L8.76 2.01325C8.55746 1.89575 8.32753 1.83367 8.09333 1.83325H7.9C7.6658 1.83367 7.43586 1.89575 7.23333 2.01325L2.76 4.59992C2.55798 4.71676 2.39017 4.88456 2.27333 5.08658L2.18 5.25325C2.0625 5.45581 2.00041 5.68574 2 5.91992V11.0866C2.00041 11.3208 2.0625 11.5507 2.18 11.7533L2.27333 11.9199C2.39319 12.1196 2.56033 12.2867 2.76 12.4066L7.24 14.9866C7.44153 15.1065 7.67213 15.1688 7.90666 15.1666H8.09333C8.32753 15.1662 8.55746 15.1041 8.76 14.9866L13.2333 12.3999C13.4373 12.2857 13.6058 12.1173 13.72 11.9133L13.82 11.7466C13.9361 11.5435 13.9981 11.3139 14 11.0799V5.91325C13.9996 5.67908 13.9375 5.44914 13.82 5.24658ZM7.9 3.16659H8.09333L12 5.41992L8 7.72658L4 5.41992L7.9 3.16659ZM8.66666 13.4999L12.5667 11.2466L12.6667 11.0799V6.57325L8.66666 8.88658V13.4999Z"
        fill="white"
      />
    </svg>
  );
};
