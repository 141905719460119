import { Box, Divider, List } from '@mui/material';
import {
  TbAd2,
  TbBook,
  TbCalendar,
  TbChartAreaLine,
  TbChartDonut2,
  TbFlask2,
  TbHome,
  TbListDetails,
  TbLockAccess,
  TbMicrophone2,
  TbShoppingCart,
  TbSpeakerphone,
  TbTicket,
  TbUnlink,
  TbCreditCardFilled,
  TbUser,
  TbCurrencyDollar,
  TbInvoice,
  TbSettings,
  TbCode,
  TbTruck,
  TbCashBanknoteFilled,
  TbLocation,
  TbSend,
  TbTruckDelivery,
  TbRoute,
  TbGasStation
} from 'react-icons/tb';
import { ProfileButton } from '~/components/ProfileButton';
import { SidebarItem } from '~/components/SidebarItem';
import Logo from './Logo';
import { Stack } from '@mui/material';
export const SIDEBAR_WIDTH = 250;
const ICON_SIZE = 18;

export function Sidebar() {
  return (
    <Box
      component="nav"
      data-tour="sidebar"
      sx={{
        display: { xs: 'none', sm: 'flex' },
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100vh',
        width: SIDEBAR_WIDTH,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          width: SIDEBAR_WIDTH,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mx: 1.5,
            mt: 1.5,
          }}
        >
          <Stack direction='column' spacing={3}>
            <Logo height={20} color='black' />

          </Stack>
        </Box>
        <SidebarDivider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <List
            sx={{
              pt: 0.5,
              pb: 0,
            }}
          >
            <Box
              component="li"
              sx={{
                px: 1.5,
                mb: 1.5,
              }}
            >
            </Box>
            <SidebarItem href="/dashboard/home" title="Home" icon={<TbHome size={ICON_SIZE} />} />
            <SidebarItem
              href="/dashboard/Users"
              title="Users"
              icon={<TbUser size={ICON_SIZE} />}
            />
            <SidebarDivider />
            <SidebarItem
              href="/dashboard/locations"
              title="Locations"
              icon={<TbGasStation size={ICON_SIZE} />}
            />
            <SidebarItem
              href="/dashboard/cards"
              title="Cards"
              icon={<TbCreditCardFilled size={ICON_SIZE} />}
            />
            <SidebarItem
              href="/dashboard/transactions"
              title="Transactions"
              icon={<TbListDetails size={ICON_SIZE} />}
            />
            <SidebarItem
              href="/dashboard/money-codes"
              title="Money Codes"
              icon={<TbCode size={ICON_SIZE} />}
            />
            <SidebarItem
              href="/dashboard/invoices"
              title="Invoices"
              icon={<TbInvoice size={ICON_SIZE} />}
            />
            <SidebarDivider />
            <SidebarItem
              href="/dashboard/factoring"
              title="Factoring"
              icon={<TbCashBanknoteFilled size={ICON_SIZE} />}
            />
            <SidebarItem
              href="/dashboard/fleet-management"
              title="Fleet Management"
              icon={<TbTruck size={ICON_SIZE} />}
            />
            <SidebarItem
              href="/dashboard/dispatch"
              title="Dispatch"
              icon={<TbRoute size={ICON_SIZE} />}
            />
            <SidebarDivider />
            <SidebarItem
              href="/dashboard/payment-settings"
              title="Payment Settings"
              icon={<TbCurrencyDollar size={ICON_SIZE} />}
            />
              <SidebarItem
              href="/dashboard/account"
              title="Profile Settings"
              icon={<TbSettings size={ICON_SIZE} />}
            />
          </List>
          <ProfileButton />
        </Box>
      </Box>
    </Box>
  );
}

function SidebarDivider() {
  return (
    <Box sx={{ py: 1.5 }}>
      <Divider />
    </Box>
  );
}
