
import { useEffect, useState } from 'react';
import { DashboardHeader } from '~/components/DashboardHeader';
import InvoiceTable from '~/components/InvoiceTable';
import { PageContainer } from '~/components/PageContainer';
import { useUser } from '~/context/UserContext';
import { Invoice } from '~/data/Invoice';
import DBManager from '~/manager/DBManager';
import { Link } from 'react-router-dom';
import StripeManager from '~/manager/StripeManager';


export function Invoices() {

  const [invoiceList, setInvoiceList] = useState<Invoice[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const { user } = useUser();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const handleDownload = async (invoiceID: string) => {
    console.log("Download button clicked for invoice ID:", invoiceID);

    const dbManager = new DBManager();
    const downloadLink = await dbManager.getDownloadLink(invoiceID);
    console.log("Download Link:", downloadLink);
    // Get Invoice 
    const invoice = invoiceList.find((invoice) => invoice.getID() === invoiceID);
    // Create a temporary anchor tag to trigger the download
    if (!invoice) {
      console.error('Invoice not found:', invoiceID);
      return
    }
    window.open(downloadLink)
    window.URL.revokeObjectURL(downloadLink);
};
  

  const handlePay = async (invoiceID: string) => {
    console.log("Pay button clicked for invoice ID:", invoiceID);
    const stripeManager = new StripeManager();
    if (user?.userID && user?.stripeID && user?.companyID) {
      const checkoutURL = await stripeManager.createPaymentCheckoutSession(invoiceID, user.stripeID, user.companyID);
      // Open the checkout URL in the current tab
      window.location.href = checkoutURL;
    }
  }

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const dbManager = new DBManager();
        const invoiceCount = await dbManager.getInvoiceCount(user?.companyID); // Get invoice count

        setTotalRows(invoiceCount[0]); // Set total rows

        const invoices = await dbManager.getInvoices(user?.companyID); // Get invoices
        setInvoiceList(invoices);

      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    if (user?.companyID) { // Only fetch invoices if companyID exists
      fetchInfo();
      
    }
  }, [user?.companyID]); // Include companyID in the dependency array



  
    return (
        <PageContainer>
            <DashboardHeader
            title='Invoices'
            />


        <InvoiceTable
        tableName=""
        tableData={invoiceList}
        paginationMode='server'
        totalRows={totalRows}
        handlePageChange={(newPage: number, newPageSize: number) => {
          setPage(newPage);
          setPageSize(newPageSize);
        }}
        handleDownload={handleDownload}
        handlePay={handlePay}
        />

            
      </PageContainer>
    );
  }

export default Invoices;