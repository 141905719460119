import * as React from "react"
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material"
import { HiX } from "react-icons/hi"
// Drawer with a title and empty panel


export default function DrawerSubtle({
  drawerTitle,
  children,
  confirmAction,
  closeDrawer,
  disableAction = false,
  buttonTitle,
}: {
  drawerTitle: string;
  children: React.ReactNode;
  confirmAction: () => void;
  closeDrawer: () => void;
  disableAction: boolean;
  buttonTitle: string;
}) {

  const [open, setOpen] = React.useState(true)
  const toggleDrawer = (open: boolean) => (_event: React.SyntheticEvent) => {
    setOpen(open)
    if (!open) {
      closeDrawer()
    }
  }

  const handleSave = () => (_event: React.SyntheticEvent) => {
    confirmAction()
    toggleDrawer(false)
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        disablePortal={true}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            maxWidth: "26rem",
            width: "100vw",
            py: 3,
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: 3,
            }}
          >
            <Typography variant="h2">{drawerTitle}</Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <HiX size={20} />
            </IconButton>
          </Box>
          <Box
            sx={{
              px: 3,
              flex: 1,
            }}
          >
           {children}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            bgcolor: "gray.100",
            px: 3,
            py: 2,
          }}
        >


          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleDrawer(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color='success' onClick={handleSave()} disabled = {disableAction}>
            {buttonTitle}
          </Button>
        </Box>
      </Drawer>
    </>
  )
}
