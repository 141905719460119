import { Feature, Provider, SafetyFeature, Table } from '~/components/pages/fuel-car';
import { ReportingFeature } from '~/components/pages/fuel-car/reportingFeature';
import { SavingFeature } from '~/components/pages/fuel-car/savingFeature';
import { Contact, Footer, Header } from '~/components/sections';

const fuelCardHeaderData = {
  title: 'Join the Largest Fuel Provider Network',
  text: 'Experience unparalleled access to the largest fuel provider network across North America. Fuel your journey with confidence and convenience, ensuring you never run out of fuel.',
};

export const FuelCardLanding = () => {
  return (
    <div>
      <Header {...fuelCardHeaderData} />
      <Feature />
      <SafetyFeature />
      <SavingFeature />
      <ReportingFeature />
      <Provider />
      <Table />
      <Contact />
      <Footer />
    </div>
  );
};
