import AuthManager from "./AuthManager";

export default class StripeManager {
    environment: string = window.location.hostname === 'localhost' 
        ? 'dev' 
        : window.location.hostname.includes('lazarus') 
        ? 'staging' 
        : 'production';

    apiLink: string = this.environment === 'dev' 
        ? 'http://127.0.0.1:5000' 
        : this.environment === 'staging' 
        ? 'https://staging.api.withmotion.com' 
        : 'https://api.withmotion.com';

    getAccessToken = () => {
        return localStorage.getItem('accessToken');
    }

    async createSetupCheckoutSession(stripeID: string, currency: string): Promise<string> {

        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/createSetupCheckoutSession`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    },
                    body: JSON.stringify({
                        stripeID: stripeID,
                        currency: currency
                    })
    
                });

                this.checkResponse(response);
                const data = await response.json();
                console.log('Checkout Data:', data);
                if (!data.checkoutURL) {
                    throw new Error('Client secret not found');
                }

                return data.checkoutURL

            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }

    }


    async getPaymentMethod(stripeID: string, currency: string): Promise<any> {

        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getPaymentMethod?stripeID=${stripeID}&currency=${currency}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    },
                });

                this.checkResponse(response);
                const data = await response.json();
                
                var currentObject = data.paymentObject.currentPaymentMethod;
                var setupObject = data.paymentObject.setupPaymentMethod;

                return {
                    currentPaymentMethod: currentObject,
                    setupPaymentMethod: setupObject
                };

            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }

    }

    async createPaymentCheckoutSession(invoiceID: string, stripeID: string, companyID: string): Promise<string> {

        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/createPaymentCheckoutSession`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    },
                    body: JSON.stringify({
                        stripeID: stripeID,
                        invoiceID: invoiceID,
                        companyID: companyID
                    })
    
                });

                this.checkResponse(response);
                const data = await response.json();
                console.log('Checkout Data:', data);
                if (!data.checkoutURL) {
                    throw new Error('Client secret not found');
                }

                return data.checkoutURL

            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }

    }


    async cancelSetup(stripeID: string, currency: string): Promise<void> {
            
            try {
                // Build the URL with the query parameter
                const url = `${this.apiLink}/cancelSetup`;
    
                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.getAccessToken()}`
                        },
                        body: JSON.stringify({
                            stripeID: stripeID,
                            currency: currency
                        })
        
                    });
    
                    this.checkResponse(response);
    
                } catch (error) {
                    throw error;
                }
    
            } catch (error) {
                throw error;
            }
    
        }


    async checkResponse(response: Response) {
        // Check if response code is 401 (Unauthorized) 
        if (response.status === 401) {
            // Redirect to login page
            const manager = new AuthManager();
            manager.logout();
            window.location.href = '/login';
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }


}