import { ComparisonTable } from '~/components/ui/comparison-table';
import { columns, data } from './table-data';
import { H3 } from '~/components/ui/typography';

export const Table = () => {
  return (
    <div className='pb-4'>
      <H3 className="text-center">Compare Motion with competitors</H3>
      <ComparisonTable data={data} columns={columns} />
    </div>
  );
};
