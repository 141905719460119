import { Container } from '@mui/material';
import { PortableTextBlock } from '@portabletext/react';
import { useEffect, useState } from 'react';
import { Advantages, Benefits, OurStory, Positions } from '~/components/pages/careers';
import { Header } from '~/components/pages/home';
import { Footer } from '~/components/sections';
import client from '~/sanity-client';

export type PositionType = {
  positionTitle: string;
  location: string;
  slug: string;
  positionType: string;
  department: string;
  compensation: string;
  date: string;
  content: PortableTextBlock;
};

const GROQ_QUERY = `
*[_type == "job"] {
  _id,
  "slug": slug.current,
  positionTitle,
  location,
  positionType,
  compensation,
  date,
  content
}`;

export const CareersLanding = () => {
  const [data, setData] = useState<PositionType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      return await client.fetch<PositionType[]>(GROQ_QUERY);
    };
    fetchData()
      .then((data) => setData(data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Header
        title="Build the future of transport with us"
        description="Join us to modernize transport and help every business thrive."
        careersHeader={true}
      />
      <OurStory />
      <Advantages />
      <Benefits />

      {loading ? (
        <Container sx={{ py: '20px' }}>
          <div>Loading...</div>
        </Container>
      ) : (
        <Positions data={data} />
      )}
      <Footer />
    </div>
  );
};
