import { OfferImage } from '~/components/ui/offer-image';
import { FeatureId, offerFeaturesData } from './offer-features-data';
import { useState } from 'react';
import { Feature } from '~/components/pages/fleeting-management/feature';

export const Offer = () => {
  const [activeSectionId, setActiveSectionId] = useState<FeatureId>('recoverRevenue');

  return (
    <div className="bg-gradient-to-b to-gradient1 from-gradient2 py-20">
      <div className="container flex lg:gap-10">
        <OfferImage imageId={activeSectionId} />
        <div className="flex flex-col gap-10">
          {offerFeaturesData.map((feature) => {
            return (
              <Feature featureId={feature.id} onVisible={setActiveSectionId} key={feature.id}>
                {feature.content}
              </Feature>
            );
          })}
        </div>
      </div>
    </div>
  );
};
