
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PageContainer } from '~/components/PageContainer';
import { useUser } from '~/context/UserContext';
import DBManager from '~/manager/DBManager';
import { styled } from '@mui/system';
import FeaturesSimpleColumn from '~/components/kit/marketing/page-sections/FeaturesSimpleColumn';
import FeaturesSmallColumn from '~/components/kit/marketing/page-sections/FeaturesSmallColumn';
import { TbAdjustments, TbCar, TbCheck, TbClockBolt, TbCurrencyDollar, TbHeadset, TbQuestionMark } from 'react-icons/tb';


export function Factoring() {

    const { user } = useUser();
    const [onWaitlist, setOnWaitlist] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const dbManager = new DBManager();
            if (!user?.companyID) {
                return;
            }
            const waitlistValue = await dbManager.getWaitlist('factoring', user?.companyID)
            setOnWaitlist(waitlistValue);
        };

        fetchData();
    }, []);


    const joinWaitlist = async () => {
        const dbManager = new DBManager();
        if (!user?.companyID) {
            return;
        }
        await dbManager.updateWaitlist('factoring', user?.companyID);
        setOnWaitlist(true);
    }

    const GradientText = styled('span')({
        background: 'linear-gradient(to right, #0061ff, #60efff)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    });


    return (
        <PageContainer>


            <Typography
                variant="h1"
                sx={{ fontSize: { xs: '2xl', sm: '3xl' }, textAlign: 'center' }}
            >
                Join the Waitlist for <br />
                <Typography
                    variant="h1"
                    sx={{ fontSize: { xs: '2xl', sm: '3xl' }, textAlign: 'center', display: 'inline' }}
                >
                    <GradientText>Invoice Factoring</GradientText>
                </Typography>
            </Typography>

            <Box>
            <FeaturesSmallColumn 
            
            features={[
                {
                    title: 'Get Paid Faster',
                    description: 'Turn your unpaid invoices into instant cash flow in as little as 24 hours.',
                    icon: <TbClockBolt/>,
            },
            {
                title: 'No Hidden Fees',
                description: 'Simple, transparent pricing with no surprises—just straightforward service.',
                icon: <TbCheck/>,
            },
            {
                title: 'Flexible Terms',
                description: 'Customizable funding options tailored to meet your business needs.',
                icon: <TbAdjustments/>,
            },
            {
                title: 'Dedicated Support',
                description: 'Expert guidance and personalized support from our team, every step of the way.',
                icon: <TbHeadset/>,
            },
        ]}
            />
            </Box>

            {(!onWaitlist) && (
                <Button
                    variant='contained'
                    color='primary'
                    onClick={joinWaitlist}
                    // Center the button on the page
                    sx={{ margin: 'auto', width: '25%' }}
                >
                    Join Waitlist
                </Button>
            )}

            {(onWaitlist) && (

                <Typography
                    // center text on page
                    sx={{ textAlign: 'center' }}
                >
                    You are on the waitlist for Motion Factoring. We will notify you once it is available.
                </Typography>


            )}
        </PageContainer>
    );
}

export default Factoring;