import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { FrameworkLink } from '~/framework/FrameworkLink';
import { useUser } from '~/context/UserContext'; 
import { BarChartComposition } from '~/components/BarChartComposition';
import StatCardIcon from '~/components/kit/application/elements/StatCardIcon';
import { useEffect, useState } from 'react';
import DBManager from '~/manager/DBManager';
import { TbInvoice, TbCreditCardFilled } from 'react-icons/tb';
import { Select } from "@mui/material"
import { SelectChangeEvent } from '@mui/material/Select';
import { ReactNode } from 'react';
import TransactionTable from '~/components/TransactionTable';
import { Transaction } from '~/data/Transaction';

export function Home() {

  const { user } = useUser();
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [cardCount, setCardCount] = useState(0);
  const [countryValue, setCountryValue] = useState(0);
  const [timeRange, setTimeRange] = useState(14);
  const [chartXData, setchartXData] = useState<any[]>([]);
  const [chartYData, setchartYData] = useState<number[]>([]);
  const [transactionList, setTransactionList] = useState<Transaction[]>([]);


  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const dbManager = new DBManager();
        const invoiceCount = await dbManager.getInvoiceCount(user?.companyID);
        const cardCount = await dbManager.getCardCount(user?.companyID);
        setInvoiceCount(invoiceCount);
        setCardCount(cardCount);
        getFuelReport();
        getRecentTransactions();
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    if (user?.companyID) { // Only fetch invoices if companyID exists
      fetchInfo();
      
    }
  }, [user?.companyID]); // Include companyID in the dependency array

  useEffect(() => {
    console.log('Country Value Changed:', countryValue); 
    getFuelReport();
  }, [countryValue, timeRange]); // Include 'countryValue' in the dependency array
  


  const handleTimeChange = async (event: SelectChangeEvent<number>, child: ReactNode) => {
    console.log(event.target.value);
    setTimeRange(event.target.value as number);
  };

  const handleCountryChange = async (event: SelectChangeEvent<number>, child: ReactNode) => {
    console.log(event.target.value);
    setCountryValue(event.target.value as number);
  };

  const getFuelReport = async () => {
    try {
      const dbManager = new DBManager();
      if (countryValue == 0) {
        var country= 'CA';
      } else {
        var country = 'US';
      }

      const fuelData = await dbManager.getFuelReport(user?.companyID, timeRange, country); // Await the promise first
      const { date, quantity } = fuelData; // Then destructure the returned object
      setchartXData(date);
      setchartYData(quantity);     
    } catch (error) {
      console.error('Error fetching fuel report:', error);
    }

  };

  const getRecentTransactions = async () => {
    try {
      const dbManager = new DBManager();
      const transactionList = await dbManager.getRecentTransactions(user?.companyID); // Await the promise first
      console.log('Get Recent List:', transactionList);
      setTransactionList(transactionList.transactions);
    } catch (error) {
      console.error('Error fetching fuel report:', error);
    }

  };


  return (
    <PageContainer>
      <DashboardHeader
        title="Home"
        subtitle= {`Welcome back, ${user?.username}!`}
      />

<Stack direction="row" spacing={2}>
<FrameworkLink to="/dashboard/invoices" style={{ textDecoration: 'none' }}>
<StatCardIcon
  title = "Invoices"
  description=''
  value = {invoiceCount}
  valueSuffix = ' due'
  titleIcon = {<TbInvoice/>}
/>
</FrameworkLink>

<FrameworkLink to="/dashboard/cards" style={{ textDecoration: 'none' }}>
<StatCardIcon
  title = "Cards"
  description=''
  value = {cardCount}
  valueSuffix = ' cards'
  titleIcon = {<TbCreditCardFilled/>}
/>
</FrameworkLink>
  </Stack>

<Divider />
<Stack direction="row" spacing={2}>
<Select native
    value={countryValue}
    onChange={handleCountryChange}
    
    >
      <option value={0}>Canada Transactions</option>
      <option value={1}>US Transactions</option>
    </Select>

    <Select native
    value={timeRange}
    onChange={handleTimeChange}
    
    >
      <option value={7}>Last 7 days</option>
      <option value={14}>Last 14 days</option>
      <option value={30}>Last 30 days</option>
    </Select>

  </Stack>
  <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '2xl', sm: '3xl' },
            lineHeight: 1.25,
          }}
        >
          Fuel Report
        </Typography>
                <BarChartComposition
            title=""
            totalValue={(parseFloat(chartYData.reduce((sum, value) => Number(sum) + Number(value), 0).toFixed(2)).toLocaleString() + (countryValue == 0 ? " Litres" : " Gallons"))}
            chartHeight={250}
            chartProps={{
              xAxis: [
                {
                  id: 'barCategories',
                  data: chartXData,
                  scaleType: 'band',
                },
              ],
              series: [
                {
                  data: chartYData,
                  label: countryValue == 0 ? "Litres" : "Gallons",
                },
              ],
            }}
          />
      <Divider />
      <TransactionTable 
      tableName='Recent Transactions'
      tableData={transactionList}
      />
    
   
    </PageContainer>
  );
}
