
import { Box, Button, Checkbox, Divider, FilledInput, FormControl, FormControlLabel, FormLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import { useState, useEffect, SyntheticEvent } from 'react';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import UserTable from '~/components/UserTable';
import { User } from '~/data/User';
import { useUser } from '~/context/UserContext';
import DBManager from '~/manager/DBManager';
import DrawerSubtle from '~/components/kit/application/overlays/DrawerSubtleActions';
import { LabeledDivider } from '~/components/LabeledDivider';
import { TbEye, TbEyeOff } from 'react-icons/tb';
import SimpleNotification from '~/components/kit/application/overlays/SimpleNotification';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import { toast } from 'react-toastify';



export function Users() {

  const [userList, setUserList] = useState<User[]>([]);
  const { user } = useUser();
  const [editUser, setEditUser] = useState(false);
  const [newUser, setNewUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  // Edit User Variables
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [eightLong, setEightLong] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneCapital, setOneCapital] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);



  // Create User Variables




  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const dbManager = new DBManager();
        const userList = await dbManager.getUsers(user?.companyID);
        console.log('User List:', userList);
        setUserList(userList.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (user?.companyID) { // Only fetch invoices if companyID exists
      fetchInfo();

    }
  }, [user?.companyID]); // Include companyID in the dependency array


  const handleEditUser = (newState: boolean, userID: string) => {
    setEditUser(newState);
    const user = userList.find((user) => user.getId() === userID);
    console.log('Selected User:', user);
    setSelectedUser(user);
    setUsername(user?.getUsername() || '');
    setFirstName(user?.getFirstName() || '');
    setLastName(user?.getLastName() || '');
    setEmail(user?.getEmail() || '');
    setPhoneNumber(user?.getPhoneNumber() || '');
    setIsActive(user?.isActive() || false);

  };

  const handleSaveUser = async () => {
    // Add save user logic here
    console.log('Save user');
    setEditUser(false);
    const updatedUser = selectedUser;
    updatedUser?.setUsername(username);
    updatedUser?.setFirstName(firstName);
    updatedUser?.setLastName(lastName);
    updatedUser?.setEmail(email);
    updatedUser?.setPhoneNumber(phoneNumber);
    updatedUser?.setActive(isActive);

    const dbManager = new DBManager();
    const message = await dbManager.updateUser(updatedUser, password);
    toast.success(message.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    );


    // Update the user in the list
    if (message.code == 200) {
      const updatedList = userList.map((user) => {
        if (user.getId() === updatedUser?.getId()) {
          return updatedUser;
        } else {
          return user;
        }
      });

      setUserList(updatedList);

      // Clear the form
      setUsername('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setIsActive(false);
      setPassword('');
      setConfirmPassword('');
      setPasswordMatch(false);
      setEightLong(false);
      setOneNumber(false);
      setOneCapital(false);
      setPasswordVisibility(false);
      
  }


  

  };

  const handleCreateUser = async () => {

    // Create User Object
    const newUser = new User('', email, firstName, lastName, true, phoneNumber, username);
    const dbManager = new DBManager();
    const message = await dbManager.createUser(newUser, password, user?.companyID);
    setNewUser(false);
    console.log(message);
    if (message.code == 200) {
      toast.success(message.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
      );
    } else if (message.code == 400) {
      toast.error(message.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
      );
    }
    // Update the user in the list
    if (message.code == 200) {
      const updatedList = [...userList, newUser];
      setUserList(updatedList);
    }

    // Clear the form
    setUsername('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setIsActive(false);
    setPassword('');
    setConfirmPassword('');
    setPasswordMatch(false);
    setEightLong(false);
    setOneNumber(false);
    setOneCapital(false);
    setPasswordVisibility(false);



  };

  const handleCloseDrawer = () => {
    // Add close drawer logic here
    console.log('Close drawer');
    setEditUser(false);
    setNewUser(false);
    // Clear the form
    setUsername('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setIsActive(false);
    setPassword('');
    setConfirmPassword('');
    setPasswordMatch(false);
    setEightLong(false);
    setOneNumber(false);
    setOneCapital(false);
    setPasswordVisibility(false);
  };

  const handleUserActive = (event: SyntheticEvent, checked: boolean) => {
    setIsActive(checked);
  };

  const passwordChanged = (password: string, confirmPassword: string) => {
    // Add close drawer logic here
    console.log('Password Values Changed');
    console.log('Password', password);
    setPassword(password);
    console.log('Confirm Password', confirmPassword);
    setConfirmPassword(confirmPassword);
    // Check for password match
    if (password == confirmPassword && password != '' && confirmPassword != '') {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

    // Check for 8 characters

    if (password.length >= 8) {
      setEightLong(true);
    } else {
      setEightLong(false);
    }

    // Check for one number

    if (/\d/.test(password)) {
      setOneNumber(true);
    } else {
      setOneNumber(false);
    }

    // Check for one capital

    if (/[A-Z]/.test(password)) {
      setOneCapital(true);
    } else {
      setOneCapital(false);
    }
  };

  const saveDisabled = () => {
    // First Check is password or confirm password is empty
    if (password == '' && confirmPassword == '') {
      
      // Check that username and email are not empty
      if (username == '' || email == '') {
        return true;
      } else {
        return false;
      }
    } else {
      // Check that username and email are not empty
      if (username == '' || email == '') {
        return true;
      } else {
        // Check password match, 8 characters long, one number, one capital

        if (passwordMatch && eightLong && oneNumber && oneCapital) {
          return false;
        } else {
          return true;
        }

      }
    }


  }

  const createDisabled = () => {
  

      // Check that username and email are not empty
      if (username == '' || email == '' || password == '' || confirmPassword == '') {
        return true;
      } else {

        // Check if email is valid
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(email)) {
          return true;
        }
      
        if (passwordMatch && eightLong && oneNumber && oneCapital) {
          return false;
        } else {
          return true;
        }

      }

  }


  return (
    <PageContainer>

      <DashboardHeader title="Users" />

      {newUser && (
        <DrawerSubtle
        drawerTitle='New User'
        confirmAction={handleCreateUser}
        closeDrawer={handleCloseDrawer}
        disableAction={createDisabled()}
        buttonTitle='Create User'
        >
         <Stack
            spacing={2}
            component="form"
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
            sx={{
              maxWidth: '24rem',
              width: '100%',
              mb: 0,
            }}
          >

            <FormControl>

              <FormLabel>Username</FormLabel>
              <FilledInput
                type="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />


              <FormLabel>Password</FormLabel>
              <FilledInput
                type={passwordVisibility ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                value={password}
                onChange={(e) => passwordChanged(e.target.value, confirmPassword)}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? <TbEye /> : <TbEyeOff />}
                  </IconButton>
                }
              />

              <FormLabel>Confirm Password</FormLabel>
              <FilledInput
                type={passwordVisibility ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                value={confirmPassword}
                onChange={(e) => passwordChanged(password, e.target.value)}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? <TbEye /> : <TbEyeOff />}
                  </IconButton>
                }
              />

              <FormControlLabel
                control={<Checkbox />}
                label="Password Match"
                checked={passwordMatch}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="8 Characters Long"
                checked={eightLong}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Number"
                checked={oneNumber}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Capital"
                checked={oneCapital}
              />


              <LabeledDivider>

                Personal Details
              </LabeledDivider>




              <FormLabel>First Name</FormLabel>
              <FilledInput
                type="firstName"
                name="firstName"
                autoComplete="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />

              <FormLabel>Last Name</FormLabel>
              <FilledInput
                type="lastName"
                name="lastName"
                autoComplete="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />


              <FormLabel>Email</FormLabel>
              <FilledInput
                type="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <FormLabel>Phone Number</FormLabel>
              <FilledInput
                type="phoneNumber"
                name="phoneNumber"
                autoComplete="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

            </FormControl>


          </Stack>
          
      </DrawerSubtle>
        )}
 


      {/* Conditional Button based on editUser state */}
      {editUser && (
        <DrawerSubtle
          drawerTitle='Edit User'
          buttonTitle='Save User'
          confirmAction={handleSaveUser}
          closeDrawer={handleCloseDrawer}
          disableAction={saveDisabled()}
        >
          <Stack
            spacing={2}
            component="form"
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
            sx={{
              maxWidth: '24rem',
              width: '100%',
              mb: 0,
            }}
          >

            <FormControl>

              <FormLabel>Username</FormLabel>
              <FilledInput
                type="username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />


              <FormLabel>Password</FormLabel>
              <FilledInput
                type={passwordVisibility ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                value={password}
                onChange={(e) => passwordChanged(e.target.value, confirmPassword)}
                autoComplete="password"
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? <TbEye /> : <TbEyeOff />}
                  </IconButton>
                }
              />

              <FormLabel>Confirm Password</FormLabel>
              <FilledInput
                type={passwordVisibility ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                value={confirmPassword}
                onChange={(e) => passwordChanged(password, e.target.value)}
                autoComplete="password"
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? <TbEye /> : <TbEyeOff />}
                  </IconButton>
                }
              />

              <FormControlLabel
                control={<Checkbox />}
                label="Password Match"
                checked={passwordMatch}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="8 Characters Long"
                checked={eightLong}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Number"
                checked={oneNumber}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Capital"
                checked={oneCapital}
              />


              <LabeledDivider>

                Personal Details
              </LabeledDivider>




              <FormLabel>First Name</FormLabel>
              <FilledInput
                type="firstName"
                name="firstName"
                autoComplete="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />

              <FormLabel>Last Name</FormLabel>
              <FilledInput
                type="lastName"
                name="lastName"
                autoComplete="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />


              <FormLabel>Email</FormLabel>
              <FilledInput
                type="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <FormLabel>Phone Number</FormLabel>
              <FilledInput
                type="phoneNumber"
                name="phoneNumber"
                autoComplete="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />


              <FormControlLabel
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: 'md',
                    padding: 1,
                  },
                  padding: 1,
                }}
                control={<Switch defaultChecked />}
                label='User is Active'
                value={isActive}
                checked={isActive}
                onChange={handleUserActive}
              />

            </FormControl>


          </Stack>

        </DrawerSubtle>
      )}


      <Button
        variant="contained"
        color="primary"
        style={{ width: '15%', alignSelf: 'flex-end' }}
        onClick={() => setNewUser(true)}

      >
        New User +
      </Button>

      <Box id="filter-panel" />


      <UserTable
        tableName=''
        tableData={userList}
        setEditUser={handleEditUser}

      />


    </PageContainer>
  );
}

export default Users;