import { CheckmarkIcon } from '~/components/icons';
import { Button } from '~/components/ui/button';
import { H2, PL, PM, Tag } from '~/components/ui/typography';

export type FeatureId = (typeof offerFeaturesData)[number]['id'];

export const offerFeaturesData = [
  {
    id: 'recoverRevenue',
    content: (
      <>
        <div className="flex flex-col gap-6">
          <H2>Recover Your Lost Revenue with Fleetio</H2>
          <PL>
            Don't let inefficiencies in your maintenance system drain your profits. Fleetio provides
            the tools and insights you need to optimize your fleet operations and save money.
          </PL>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 m-auto lg:m-0">
          <Button buttonSize="large" buttonType="solid" textColor="white">
            Join Us
          </Button>
          <Button buttonSize="large" buttonType="invisible" textColor="primary">
            Contact Us
          </Button>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {[0, 1, 2, 3].map((item, i) => {
            return (
              <PM key={i} className="flex font-semibold gap-2 items-center">
                <CheckmarkIcon />
                Lorem ipsum dolor sit vet
              </PM>
            );
          })}
        </div>
      </>
    ),
  },
  {
    id: 'saveTime',
    content: (
      <>
        <div className="flex flex-col gap-6">
          <Tag>Efficiency</Tag>
          <H2>Save Time and Money with Fleetio</H2>
          <PL>
            Reduce inspection time by 83% with Fleetio. Smartwatt Energy saved 48% on maintenance
            costs. Newkirk Electric reduced fleet report time by 10X.
          </PL>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <PM className="flex font-semibold gap-2">
            <span>
              <CheckmarkIcon />
            </span>
            Stanley Steemer reduced inspection time by 83% with Fleetio.
          </PM>
          <PM className="flex font-semibold gap-2">
            <span>
              <CheckmarkIcon />
            </span>
            Smartwatt Energy saved 48% on maintenance costs with Fleetio.
          </PM>
        </div>
      </>
    ),
  },
  {
    id: 'streamline',
    content: (
      <>
        <div className="flex flex-col gap-6">
          <Tag>Revolutionize</Tag>
          <H2>Streamline Your Fleet Maintenance with Motion</H2>
          <PL>
            Motion is the modern fleet maintenance platform that brings all your tools and workflows
            together, providing visibility, insights, and automations to optimize your fleet
            operations.
          </PL>
        </div>
        <div className="flex flex-col gap-4">
          <PM className="flex font-semibold gap-2 items-center">
            <CheckmarkIcon />
            Reduce Costs and Improve Efficiency with Motion
          </PM>
          <PM className="flex font-semibold gap-2 items-center">
            <CheckmarkIcon />
            Maximize Fleet Performance and Minimize Downtime
          </PM>
          <PM className="flex font-semibold gap-2 items-center">
            <CheckmarkIcon />
            Lorem ipsum dolor sit vet
          </PM>
        </div>
      </>
    ),
  },
  {
    id: 'optimization',
    content: (
      <>
        <div className="flex flex-col gap-6">
          <Tag>Efficiency</Tag>
          <H2>Integrate, Optimize, and Automate Your Fleet</H2>
          <PL>
            Fleetio integrates all your tools and workflows, providing visibility, insights, and
            automations that your outdated system lacks.
          </PL>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <PM className="flex font-semibold gap-2">
            <span>
              <CheckmarkIcon />
            </span>
            Gain insights into your fleet operations and make data-driven decisions.
          </PM>
          <PM className="flex font-semibold gap-2">
            <span>
              <CheckmarkIcon />
            </span>
            Automate manual tasks and streamline your fleet management processes for increased
            efficiency.
          </PM>
        </div>
      </>
    ),
  },
] as const;
