import {
  Header,
  AdvantageSection,
  Streamline,
  Partners,
  CallToAction,
} from '~/components/pages/home';
import { FAQ, Footer } from '~/components/sections';

export const HomeLanding = () => {
  return (
    <div>
      <Header />
      <AdvantageSection />
      <Streamline />
      <FAQ />
      <Footer />
    </div>
  );
};
