import { BiLogoFacebookCircle, BiLogoLinkedinSquare } from 'react-icons/bi';
import { FaXTwitter } from 'react-icons/fa6';
import { H4, PL, PM } from '~/components/ui/typography';
import { getData } from '~/lib/utils/toLocaleDate';

export type BlogCardProps = {
  date: string | null;
  title: string;
  description: string | null;
  link: string;
};

export const BlogCard = ({ date, title, description, link }: BlogCardProps) => {
  return (
    <div className="border-b border-solid border-gray-300 md:max-w-[800px] mx-auto py-8 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <PM className="text-sm mb-2">{getData(date)}</PM>
        <div className="grid grid-flow-col grid-cols-[max-content] items-start justify-start gap-x-3 gap-y-0">
          <BiLogoLinkedinSquare color="white" className="size-6" />
          <FaXTwitter color="white" className="size-6 p-0.5" />
          <BiLogoFacebookCircle color="white" className="size-6" />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <H4 className="text-xl font-semibold mb-2">{title}</H4>
        <PL>{description}</PL>
      </div>
      <a href={`/blog/${link}`} className="text-blue-600 hover:underline">
        Read more
      </a>
    </div>
  );
};
