import { ReviewCard } from '~/components/ui/review-card';
import { H3, PL } from '~/components/ui/typography';

export const CustomerReview = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient1 from-gradient2">
      <div className="container">
        <div className="mb-6 text-center">
          <H3>Customer reviews</H3>
          <PL>See what our clients have to say abotu us</PL>
        </div>
        <div className="flex flex-wrap gap-4 justify-center xl:justify-between mt-12">
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
        </div>
      </div>
    </div>
  );
};
