import {
  Box,
  Button,
  Checkbox,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  Switch,
} from '@mui/material';
import * as React from 'react';
import { TbEye, TbEyeOff } from 'react-icons/tb';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { TitleDescriptionBlock } from '~/components/TitleDescriptionBlock';
import { useUser } from '~/context/UserContext';
import DBManager from '~/manager/DBManager';
import { useState, useEffect, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';

export function Account() {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [currentPasswordVisibility, setCurrentPasswordVisibility] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordVisibility, setNewPasswordVisibility] = React.useState(false);
  const passwordsMatch = currentPassword === newPassword;
  const { user } = useUser();
  const { setMarketingNotification } = useUser();
  const { setTransactionNotification } = useUser();
  const [transactionLimit, setTransactionLimit] = useState(0);
  const [eightLong, setEightLong] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneCapital, setOneCapital] = useState(false);

  const [updateMessage, setUpdateMessage] = useState('');


  useEffect(() => {
    if (user?.transactionLimit) {
      setTransactionLimit(user.transactionLimit);
    }
  }, [user?.transactionLimit]);



  const transactionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Notification change", event.target.checked);
    const dbManager = new DBManager();
    if (!user?.companyID) {
      return;
    }

    setTransactionNotification(event.target.checked, transactionLimit);
    console.log("User", user.transactionNotification);
    dbManager.updateNotification(user.companyID, event.target.checked, user.transactionLimit, user.marketingNotification);


  }

  const transactionLimitChange = (value: string) => {
    console.log("Transaction Limit change", value);
    const dbManager = new DBManager();
    if (!user?.companyID) {
      return;
    }
    if (!user.transactionNotification || value == '') {
      return;
    }

    setTransactionNotification(user.transactionNotification, value as unknown as number);

    dbManager.updateNotification(user.companyID, user.transactionNotification, value as unknown as number, user.marketingNotification);


  }

  const marketingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dbManager = new DBManager();
    if (!user?.companyID) {
      return;
    }

    setMarketingNotification(event.target.checked);
    console.log("User", user.marketingNotification);
    dbManager.updateNotification(user.companyID, user.transactionNotification, user.transactionLimit, event.target.checked);
  }


  const passwordChanged = (password: string) => {
    if (password.length >= 8) {
      setEightLong(true);
    } else {
      setEightLong(false);
    }

    // Check for one number

    if (/\d/.test(password)) {
      setOneNumber(true);
    } else {
      setOneNumber(false);
    }

    // Check for one capital

    if (/[A-Z]/.test(password)) {
      setOneCapital(true);
    } else {
      setOneCapital(false);
    }


  }

  const changeDisabled = () => {


    // Check that username and email are not empty
    if (newPassword == '' || currentPassword == '') {
      return true;
    } else {

      if (eightLong && oneNumber && oneCapital) {
        return false;
      } else {
        return true;
      }

    }

  }


  const changePasswordSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const dbManager = new DBManager();
    if (!user?.email) {
      return;
    }
    const message = await dbManager.changePasswordConfirmed(user.email, currentPassword, newPassword);
    if (message.code == 200) {
      toast.success(
        message.message, {

        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light"

      });
      setCurrentPassword('');
      setNewPassword('');
      setEightLong(false);
      setOneNumber(false);
      setOneCapital(false);
    } else {
      toast.error(
        message.message, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
  }

  return (
    <PageContainer>
      <DashboardHeader
        title="Profile"
        subtitle="Manage your account details, notifications, and authentication settings."
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

        <Stack
          component="form"
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            console.log('Form submitted');
            changePasswordSubmit(event);

          }}

        >
          <TitleDescriptionBlock
            title="Password"
            description="Change your password used for login with this account."
          />
          <Box sx={{ mt: 3 }}>
            <FormControl sx={{ minWidth: { xs: 250, sm: 350 } }}>
              <FormLabel>Current password</FormLabel>
              <FilledInput
                type={currentPasswordVisibility ? 'text' : 'password'}
                name="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="password"
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setCurrentPasswordVisibility(!currentPasswordVisibility)}
                    edge="end"
                  >
                    {currentPasswordVisibility ? <TbEye /> : <TbEyeOff />}
                  </IconButton>
                }
              />
            </FormControl>
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControl sx={{ minWidth: { xs: 250, sm: 350 } }}>
              <FormLabel>New password</FormLabel>
              <FilledInput
                type={newPasswordVisibility ? 'text' : 'password'}
                name="newPassword"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  passwordChanged(e.target.value);
                }}
                autoComplete="password"
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setNewPasswordVisibility(!newPasswordVisibility)}
                    edge="end"
                  >
                    {newPasswordVisibility ? <TbEye /> : <TbEyeOff />}
                  </IconButton>
                }
              />

              <FormControlLabel
                control={<Checkbox />}
                label="8 Characters Long"
                checked={eightLong}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Number"
                checked={oneNumber}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Capital"
                checked={oneCapital}
              />
            </FormControl>
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              borderRadius: 1,
              py: 1.25,
              px: 2,
              textTransform: 'none',
              boxShadow: 2,
              width: '25%'

            }}
            color="success"
            disabled={changeDisabled()}

          >
            Save new password
          </Button>
        </Stack>




        <Divider />
        <TitleDescriptionBlock
          title="Notifications"
          description="Manage the content you get sent to your inbox."
        />
        <Stack spacing={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Transaction Notifications</FormLabel>
            <Switch
              checked={user?.transactionNotification}
              value={user?.transactionNotification}
              onChange={(event) => {
                transactionChange(event);
                toast.success(
                  event.target.checked
                    ? 'You will now receive transaction notifications'
                    : 'You will no longer receive transaction notifications', {

                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"

                });

              }}
            />
            <FormLabel sx={{ marginTop: '5px' }}>Transaction Notification Limit ($)</FormLabel>
            <FilledInput
              type='number'
              name="transactionLimit"
              value={transactionLimit}
              sx={{ width: '25%' }}
              onChange={(e) => {
                setTransactionLimit(e.target.value as unknown as number);
                transactionLimitChange(e.target.value);
              }}


            />
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Marketing Notifications</FormLabel>
            <Switch
              checked={user?.marketingNotification}
              value={user?.marketingNotification}
              onChange={(event) => {
                marketingChange(event);
                toast.success(
                  event.target.checked
                    ? 'You will now receive marketing messages'
                    : 'You will no longer receive marketing messages',
                    {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light"
                    }
                );
              }}
            />
          </FormControl>
        </Stack>
        <Divider />
      </Box>
    </PageContainer>
  );
}
