import { Box, Portal, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React from 'react';
import {
  currencyColumnFactory,
  dateColumnFactory,
  genericColumnFactory,
  timeColumnFactory
} from '~/components/ColumnDefinitions';

import { StatusBadge, StatusLabel } from '~/components/StatusBadge';
import { Table } from '~/components/Table';
import { clientFiltering } from '~/core/filters';
import { useFilters } from '~/hooks/useFilters';
import { Transaction } from '~/data/Transaction';
import { useState, useEffect } from 'react';

export function TransactionTable({
  tableHeight,
  allowCheckboxSelection = false,
  tableData,
  tableName,
  paginationMode = 'client',
  totalRows,
  handlePageChange,
}: {
    tableHeight?: number;
    allowCheckboxSelection?: boolean;
    tableName: string;
    tableData: Transaction[] | undefined; 
    pageSize?: number;
    page?: number;
    paginationMode?: 'client' | 'server';
    totalRows?: number;
    handlePageChange?: (newPage: number, newPageSize: number) => void;
}) {
  const { filters } = useFilters();
  const [transformedData, setTransformedData] = useState<any[]>([]); // Use state for transformedData

  useEffect(() => {
    // Update transformedData whenever tableData changes
    if (tableData) {
      console.log('Table Data:', tableData);
      console.log('Transformed Data:', tableData.map((item: Transaction) => item.toJSON()));  
      setTransformedData(tableData.map((item: Transaction) => item.toJSON()));
    } else {
      setTransformedData([]);
    }
    console.log('Pagination Mode:', paginationMode);
  }, [tableData]); // Include tableData in dependency array


  const paginationModelChanged = (model: any) => {
    if (handlePageChange) {
    handlePageChange(model.page, model.pageSize);
    }
  }


  const columnDefinitions: GridColDef[] = [
    dateColumnFactory({
        field: 'transactionDate',
        headerName: 'Transaction Date',
        flex: 1.5,
        minWidth: 50,
        maxWidth: 100,
      }),

      timeColumnFactory({
        field: 'transactionTime',
        headerName: 'Transaction Time',
        flex: 1.5,
        minWidth: 50,
        maxWidth: 100,
      }),
      genericColumnFactory({
        field: 'location',
        headerName: 'Location',
        minWidth: 150,
        flex: 2,
      }),
      genericColumnFactory({
          field: 'product',
          headerName: 'Product',
          minWidth: 50,
          maxWidth: 125,
          flex: 2,
          renderCell: (params) => (
            <Box
              sx={{
                lineHeight: 1.2,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <StatusBadge status={params.value as StatusLabel} />
            </Box>
          ),
        }),
      genericColumnFactory({
        field: 'cardNumber',
        headerName: 'Card #',
        minWidth: 125,
        flex: 2,
      }),

    genericColumnFactory({
        field: 'quantity',
        headerName: 'Qty.',
        flex: 0.5,
      }),
      genericColumnFactory({
      field: 'price',
      headerName: 'Price',
    }),
    genericColumnFactory({
        field: 'amount',
        headerName: 'Amount',
      }),

  ];

  return (
    
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        gap: 1.5,
      }}
    >
                <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '2xl', sm: '3xl' },
            lineHeight: 1.25,
          }}
        >
          {tableName}
        </Typography>
      <Table
        tableHeight={tableHeight}
        columns={columnDefinitions}
        rows={clientFiltering(transformedData, filters)}
        initialState={{
          sorting: { sortModel: [{ field: 'transactionDate', sort: 'desc' }] },
        }}
        pagination
        hideFooter={false}
        paginationMode={paginationMode}
        onPaginationModelChange={(model) => paginationModelChanged(model)}
        rowCount={totalRows || transformedData.length}
        disableColumnFilter
        checkboxSelection={allowCheckboxSelection}
        getRowClassName={(params) => {
          if (params.row.status === 'failed') {
            return 'datagrid-row-error';
          }
          return '';
        }}
      />
    </Box>
  );
}


export default TransactionTable;
