import { StarIcon } from '~/components/icons';
import { PL } from '~/components/ui/typography';
import { Avatar } from '~/components/ui/avatar';
import avatar from '~/assets/customer_avatar.png';

export const ReviewCard = () => {
  return (
    <div className="flex flex-col bg-darkBlue1 border border-solid max-w-[380px] gap-6 border-white rounded-xl p-8">
      <div className="flex gap-1">
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>
      <PL>REV Capital has exceeded our expectations with their exceptional factoring services.</PL>
      <Avatar name="John Doe" position="CEO, ABC Company" imageSrc={avatar} />
    </div>
  );
};
