import { Container } from '@mui/material';
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from '@tanstack/react-table';
import { ComparisonData } from '~/components/pages/fuel-car/table-data';

export type ComparisonTableProps = {
  data: ComparisonData[];
  columns: ColumnDef<ComparisonData>[];
};

export const ComparisonTable = ({ data, columns }: ComparisonTableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container className="max-w-6xl mx-auto" fixed>
      <div className="w-full rounded-3xl overflow-x-auto font-['TTNormsPro']">
        <table className="min-w-full border-collapse table-auto">
          <thead className="bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-6 py-3 font-bold">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="bg-gray-100">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-6 py-4 text-center border-t">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
};
