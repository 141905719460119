import { H3, H5, PM } from '~/components/ui/typography';

const providerData = [
  {
    key: 'features',
    title: 'Key Features of Our Fuel Card',
    text: 'Access to thousands of fuel stations nationwide for your convenience.',
    imageSrc: '',
  },
  {
    key: 'security',
    title: 'Enhanced Security Features',
    text: 'Fraud detection technology to protect your transactions at all times.',
    imageSrc: '',
  },
  {
    key: 'team',
    title: '24/7 Customer Support',
    text: 'Our dedicated team is here to assist you, day or night.',
    imageSrc: '',
  },
  {
    key: 'fees',
    title: 'No Hidden Fees',
    text: 'Enjoy a straightforward pricing model with no monthly or annual fees.',
    imageSrc: '',
  },
];

export const Provider = () => {
  return (
    <div className="bg-gradient-to-b to-gradient2 from-gradient1 py-20">
      <div className="container">
        <H3 className="mb-12 text-center">
          Unlock the Power of North America's Largest Fuel Provider Network
        </H3>
        <div className="flex gap-6 flex-wrap justify-center">
          {providerData.map((provider) => {
            return (
              <div className="flex flex-col gap-6 text-center w-[281px]" key={provider.key}>
                <figure className="min-w-full bg-gray-400 h-40 rounded-lg">{/* <img /> */}</figure>
                <div className="flex flex-col gap-4">
                  <H5>{provider.title}</H5>
                  <PM>{provider.text}</PM>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
