import { ReactNode, useEffect, useRef } from 'react';
import { cn } from '~/lib/utils/style';
import { FeatureId } from '~/components/pages/fleeting-management/offer-features-data';

export type FeatureProps = {
  children: ReactNode;
  reversed?: boolean;
  onVisible: (id: FeatureId) => void;
  featureId: FeatureId;
};

export const Feature = ({ children, reversed, onVisible, featureId }: FeatureProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const section = ref.current;
    if (!section) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onVisible(featureId);
        }
      },
      { threshold: 0.6 },
    );

    observer.observe(section);

    return () => {
      observer.unobserve(section);
    };
  }, [featureId, onVisible, ref]);

  return (
    <div
      ref={ref}
      className="min-h-screen flex flex-col lg:flex-row gap-12 justify-between 2xl:justify-center"
    >
      <figure
        className={cn(
          'lg:hidden block mx-auto min-w-full sm:min-w-[400px] lg:min-w-[500px] h-full lg:h-auto min-h-[250px] sm:min-h-[300px] xl:min-w-[570px] bg-gray-500 xl:h-[600px] rounded-lg overflow-hidden',
          reversed && 'lg:order-1',
        )}
      >
        {/* <img src={imageSrc} className='object-cover' alt='Motion picture' /> */}
      </figure>
      <div className="flex flex-col gap-8 justify-center text-center w-full lg:text-left lg:w-[616px]">
        {children}
      </div>
    </div>
  );
};
