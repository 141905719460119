import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { LabeledDivider } from '~/components/LabeledDivider';
import Autocomplete from 'react-google-autocomplete';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import DBManager from '~/manager/DBManager';
import { Header } from '~/components/pages/get-started/header';
import { Footer } from '~/components/sections';

export function GetStarted() {
  const [locationSelected, setLocationSelected] = useState('');
  const [fuelCard, setFuelCard] = useState(false);
  const [factoring, setFactoring] = useState(false);
  const [fleetManagement, setFleetManagement] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  let companyName = '';
  let ownerName = '';
  let unitCount = '';
  let dieselVolume = '';
  const [email, setEmail] = useState('');
  let phoneNumber = '';
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
      console.log('Email:', email);
    }
  }, []);

  const handleFormSubmit = () => {
    console.log('Company Name:', companyName);
    console.log('Owner Name:', ownerName);
    console.log('Location:', locationSelected);
    console.log('Unit Count:', unitCount);
    console.log('Diesel Volume:', dieselVolume);
    console.log('Email:', email);
    console.log('Phone Number:', phoneNumber);

    const dbManager = new DBManager();
    dbManager.createLead(
      companyName,
      ownerName,
      locationSelected,
      unitCount,
      dieselVolume,
      email,
      phoneNumber,
      fuelCard,
      factoring,
      fleetManagement,
    ).then(() => {
      setIsSubmitted(true);
    });
  };

  const handleVerify = (token: string) => {
    console.log('Token:', token);
  };

  return (
    <div className="text-black">
      <Header>
        <GoogleReCaptchaProvider reCaptchaKey={'6LcQ3i0qAAAAAJHt9XyNw4PslCWW_YgENLkzvAX2'}>
          <Container
            sx={{
              backgroundColor: 'white',
              padding: '5px',
              borderRadius: '5px',
              width: '100%',
              maxWidth: '800px', // Make sure to set a max-width
              margin: 'auto',
            }}
          >
            <Box
              sx={{
                minHeight: '60vh', // Ensure the content box maintains the same height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {isSubmitted ? (
                <Typography variant="h2" sx={{ textAlign: 'center', mt: 4, color: 'black' }}>
                  Thank you. Somebody will be in touch soon.
                </Typography>
              ) : (
                <>
                  <Typography variant="h1" sx={{ textAlign: 'center', mt: 4, color: 'black' }}>
                    Get Started
                  </Typography>
                  <Stack
                    spacing={2}
                    component="form"
                    onSubmit={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      const formData = new FormData(event.target as HTMLFormElement);
                      companyName = formData.get('companyName') as string;
                      ownerName = formData.get('ownerName') as string;
                      unitCount = formData.get('unitCount') as string;
                      dieselVolume = formData.get('dieselVolume') as string;
                      setEmail(formData.get('email') as string);
                      phoneNumber = formData.get('phoneNumber') as string;
                      handleFormSubmit();
                    }}
                  >
                    <LabeledDivider>
                      <Box sx={{ color: 'black' }}>Company Information</Box>
                    </LabeledDivider>
                    <FormControl>
                      <FormLabel sx={{ color: 'black' }}>Company Legal Name</FormLabel>
                      <FilledInput
                        type="text"
                        name="companyName"
                        placeholder="Company Legal Name"
                      />
                    </FormControl>

                    <FormControl>
                    <FormLabel sx={{ color: 'black' }}>Business Location</FormLabel>
                    <Autocomplete
                      apiKey={'AIzaSyBK498qkRPPtFB5I_oMdeEjQYt0ud_8Wfg'}
                      onPlaceSelected={(place) => {
                        setLocationSelected(place.formatted_address as string);
                      }}
                      options={{
                        types: ['address'],
                      }}
                    />
                  </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: 'black' }}>Number of Units in your fleet?</FormLabel>
                      <FilledInput type="text" name="unitCount" placeholder="Number of Units" />
                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: 'black' }}>Monthly Diesel Volume</FormLabel>
                      <FilledInput type="text" name="dieselVolume" placeholder="1000 Litres" />
                    </FormControl>

                    <LabeledDivider>
                      <Box sx={{ color: 'black' }}>Personal Information</Box>
                    </LabeledDivider>

                    <FormControl>
                      <FormLabel sx={{ color: 'black' }}>Owner Name</FormLabel>
                      <FilledInput type="text" name="ownerName" placeholder="Owner Name" />
                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: 'black' }}>Email</FormLabel>
                      <FilledInput type="email" name="email" placeholder="Email" value={email} />
                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: 'black' }}>Phone Number</FormLabel>
                      <FilledInput type="text" name="phoneNumber" placeholder="(XXX)-XXX-XXXX" />

                      <LabeledDivider>
                        <Box sx={{ color: 'black' }}>Services</Box>
                      </LabeledDivider>

                      <FormControlLabel
                        checked={fuelCard}
                        onChange={(e, t) => setFuelCard(t)}
                        control={<Checkbox style={{ color: 'black' }} />}
                        label="Fuel Card"
                        componentsProps={{ typography: { color: 'black' } }}
                      />
                      <FormControlLabel
                        checked={factoring}
                        onChange={(e, t) => setFactoring(t)}
                        control={<Checkbox style={{ color: 'black' }} />}
                        label="Factoring"
                        componentsProps={{ typography: { color: 'black' } }}
                      />
                      <FormControlLabel
                        checked={fleetManagement}
                        onChange={(e, t) => setFleetManagement(t)}
                        control={<Checkbox style={{ color: 'black' }} />}
                        label="Fleet Management"
                        componentsProps={{ typography: { color: 'black' } }}
                      />
                    </FormControl>

                    <GoogleReCaptcha onVerify={handleVerify} />
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        mt: 2,
                        width: '50%',
                        alignSelf: 'center',
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Container>
        </GoogleReCaptchaProvider>
      </Header>
      <Footer />
    </div>
  );
}

export default GetStarted;
