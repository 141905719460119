import { PM } from '~/components/ui/typography';

export type AvatarProps = {
  name: string;
  position: string;
  imageSrc: string;
};

export const Avatar = ({ name, position, imageSrc }: AvatarProps) => {
  return (
    <div className="flex items-center gap-5">
      <figure className="max-h-14 max-w-14">
        <img src={imageSrc} alt="Avatar" />
      </figure>
      <div>
        <PM className="font-semibold">{name}</PM>
        <PM>{position}</PM>
      </div>
    </div>
  );
};
