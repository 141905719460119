import { Button } from '~/components/ui/button';
import { H2, Label, PL } from '~/components/ui/typography';
import { motion } from 'framer-motion';
import { FrameworkLink } from '~/framework/FrameworkLink';

export type AdvantageProps = {
  title: string;
  label: string;
  description: string;
  page: string;
  videoSrc: string;
};

export const Advantage = ({ title, label, description, page, videoSrc }: AdvantageProps) => {
  return (
    <div className="py-16">
      <div className="flex flex-col lg:flex-row gap-12 justify-between 2xl:justify-center">
        <motion.figure
          className="block mx-auto min-w-full sm:min-w-[400px] lg:min-w-[500px] h-full lg:h-auto min-h-[250px] sm:min-h-[300px] xl:min-w-[616px] bg-gray-500 xl:h-[600px] rounded-lg overflow-hidden"
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          viewport={{ once: true }}
        >
            <motion.video
              className="object-cover w-full h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              autoPlay
              loop
              muted
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </motion.video>
        </motion.figure>
        <div className="flex flex-col gap-8 justify-center text-center w-full lg:text-left lg:w-[616px]">
          <div className="flex flex-col gap-6">
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            >
              <Label>{label}</Label>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <H2>{title}</H2>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
            >
              <PL>{description}</PL>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <FrameworkLink
              to={page}
              >
            <Button buttonSize="large" buttonType="invisible" textColor='primary'>
              Learn More →
            </Button>
            </FrameworkLink>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
