
import { useEffect, useState } from 'react';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { Transaction } from '~/data/Transaction';
import { useUser } from '~/context/UserContext';
import DBManager from '~/manager/DBManager';
import TransactionTable from '~/components/TransactionTable';
import { Button, Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import DrawerSubtle from '~/components/kit/application/overlays/DrawerSubtleActions';
import { DatePicker } from '@mui/x-date-pickers';
import { Card } from '~/data/Card';
import { set } from 'ts-pattern/dist/patterns';



export function Transactions() {

  const [transactionList, setTransactionList] = useState<Transaction[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const { user } = useUser();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  // Transaction Report
  const [getTransactionReport, setGetTransactionReport] = useState(false);
  const [transactionReportStartDate, setTransactionReportStartDate] = useState('');
  const [transactionReportEndDate, setTransactionReportEndDate] = useState('');
  const [allCards, setAllCards] = useState(true);
  const [selectCards, setSelectCards] = useState(false);
  const [cardArray, setCardArray] = useState<Card[]>([]);
  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const handlePageChange = async (newPage: number, newPageSize: number) => {
    console.log("Page Change:", newPage, newPageSize);
    setPage(newPage);
    setPageSize(newPageSize);
    const dbManager = new DBManager();
    const transactions = await dbManager.getTransactions(newPageSize, newPage, user?.companyID);
    setTransactionList(transactions.transactions);
    console.log('Total Transactions:', transactions.totalTransactions);
    setTotalRows(transactions.totalTransactions);
  }



  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const dbManager = new DBManager();
        const transactions = await dbManager.getTransactions(pageSize, page, user?.companyID);
        setTransactionList(transactions.transactions);
        console.log('Total Transactions:', transactions.totalTransactions);
        setTotalRows(transactions.totalTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    if (user?.companyID) { // Only fetch invoices if companyID exists
      fetchInfo();

    }
  }, [user?.companyID]); // Include companyID in the dependency array


  const handleGetReport = async () => {
    console.log('Get Report:', transactionReportStartDate, transactionReportEndDate, allCards, selectedCards);
    const dbManager = new DBManager();

    if (selectedCards.length == 0) {
      const cards = await dbManager.getCards(user?.companyID)
      const transactionReport = await dbManager.getTransactionReport(transactionReportStartDate, transactionReportEndDate, cards.cards.map(card => card.getCardNumber()), user?.companyID);
      console.log('Transaction Report:', transactionReport);
    
    
    }

    setGetTransactionReport(false);
    setSelectedCards([]);
    setAllCards(true);
    setSelectCards(false);
    setTransactionReportStartDate('');
    setTransactionReportEndDate('');

  }

  const handleCloseDrawer = () => {
    setGetTransactionReport(false);
    setSelectedCards([]);
    setAllCards(true);
    setSelectCards(false);
    setTransactionReportStartDate('');
    setTransactionReportEndDate('');
  }

  const reportDisabled = () => {
    if (transactionReportStartDate === '' || transactionReportEndDate === '') {
      return true;
    }
    if (selectCards && selectedCards.length === 0) {
      return true;
    }
    return false;
  }

  const handleCardSelected = (cardNumber: string | string[]) => {
    console.log('Card Selected:', cardNumber);
    setSelectedCards(cardNumber as string[]);
    
  }

  const handleTransactionReportOpened = async () => {
    console.log('Transaction Report Opened');
    setGetTransactionReport(true);
    const dbManager = new DBManager();  
    const cards = await dbManager.getCards(user?.companyID)

    // Sort cards by card number
    cards.cards.sort((a: Card, b: Card) => {
      return a.getCardNumber().localeCompare(b.getCardNumber());
    });

    setCardArray(cards.cards);
  }

  return (
    <PageContainer>
      <DashboardHeader title="Transactions" />

      <Button
        variant="contained"
        color="primary"
        style={{ width: '15%', alignSelf: 'flex-end' }}
        onClick={(event) => { handleTransactionReportOpened() }}
      >
        Transaction Report
      </Button>

      {getTransactionReport && (
        <DrawerSubtle
          drawerTitle='Transaction Report'
          confirmAction={handleGetReport}
          closeDrawer={handleCloseDrawer}
          disableAction={reportDisabled()}
          buttonTitle='Get Report'
        >
          <Stack
            spacing={2}
            component="form"
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
            sx={{
              maxWidth: '24rem',
              width: '100%',
              mb: 0,
            }}
          >

            <DatePicker
              label="Start Date"
              onChange={(date: any) => {
                setTransactionReportStartDate(date?.format('YYYY-MM-DD'));
              }}
              sx={{
                width: '100%',
              }}

            />

            <DatePicker
              label="End Date"
              onChange={(date: any) => {
                setTransactionReportEndDate(date?.format('YYYY-MM-DD'));
              }}
              sx={{
                width: '100%',
              }}

            />

            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: 'sm',
                },
              }}
              control={<Checkbox />}
              label="All Cards"
              value={allCards}
              checked={allCards}
              onChange={(e, checked) => {
                setAllCards(checked)
                setSelectCards(!checked)
              }}
            />

<FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: 'sm',
                },
              }}
              control={<Checkbox />}
              label="Select Cards"
              value={selectCards}
              checked={selectCards}
              onChange={(e, checked) => {
                setAllCards(!checked)
                setSelectCards(checked)
              }}
            />
          {selectCards && (
          <Select
            multiple = {true}
            value={selectedCards}
            onChange={(ev) => handleCardSelected(ev.target.value)}

          >
  {cardArray.map((card) => (
            <MenuItem key={card.getID()} value={card.getCardNumber()}>{card.getCardNumber()}</MenuItem>
          ))
        }


          </Select>
          
        )}


          </Stack>

        </DrawerSubtle>
      )}




      <TransactionTable
        tableName=""
        tableData={transactionList}
        paginationMode="server"
        totalRows={totalRows}
        handlePageChange={handlePageChange}
      />


    </PageContainer>
  );
}

export default Transactions;