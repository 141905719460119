
export interface CardData {
    id: string;
    cardNumber: string;
    unitNumber: string;
    driverName: string;
    driverID: string;
    supplier: string;
    status: string;
}


export class Card {
    private id: string;
    private cardNumber: string;
    private unitNumber: string;
    private driverName: string;
    private driverID: string;
    private supplier: string;
    private status: string;


    constructor(id: string, cardNumber: string, unitNumber: string, driverName: string, driverID: string, supplier: string, status: string) {
        this.id = id;
        this.cardNumber = cardNumber;
        this.unitNumber = unitNumber;
        this.driverName = driverName;
        this.driverID = driverID;
        this.supplier = supplier;
        this.status = status;
    }


    public getID(): string {
        return this.id;
    }

    public getCardNumber(): string {
        return this.cardNumber;
    }

    public getUnitNumber(): string {
        return this.unitNumber;
    }


    public getDriverName(): string {
        return this.driverName;
    }


    public getSupplier(): string {
        return this.supplier;
    }

    public getStatus(): string {
        return this.status;
    }

    public getDriverID(): string {
        return this.driverID;
    }

    public toJSON(): {
        id: string;
        cardNumber: string;
        unitNumber: string;
        driverName: string;
        driverID: string;
        supplier: string;
        status: string;
    } {
        return {
            id: this.id,
            cardNumber: this.cardNumber,
            unitNumber: this.unitNumber,
            driverName: this.driverName,
            driverID: this.driverID,
            supplier: this.supplier,
            status: this.status.charAt(0).toUpperCase() + this.status.slice(1).toLowerCase()
        };
    }


}