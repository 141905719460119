import * as React from "react"
import { FilledInput, FormControl, FormLabel } from "@mui/material"
// simple input with label
interface EmailInputProps {
  setEmail: (email: string) => void;
}

export default function EmailInput({ setEmail }: EmailInputProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <FormControl sx={{ flexGrow: 1 }}>
      <FilledInput 
        placeholder="What's your email?" 
        onChange={handleChange} 
      />
    </FormControl>
  );
}
