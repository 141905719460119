export const CheckIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_930_7283)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.788 0.649902C24.4156 0.649902 23.7303 0.721055 22.73 0.860752C21.7299 1.00097 21.0833 1.13668 20.7918 1.2651C20.4992 1.39351 19.9708 1.83917 19.2075 2.59698C18.4436 3.3576 17.3356 4.76118 15.8867 6.80686C14.4356 8.8548 13.0823 11.0166 11.8242 13.2939C10.5655 15.5721 9.39086 17.9373 8.30069 20.3892C7.2921 18.6625 6.38066 17.4917 5.56193 16.8759C4.74526 16.2577 4.0563 15.9494 3.49613 15.9494C3.05864 15.9494 2.51059 16.1826 1.84945 16.647C1.18915 17.1128 0.858887 17.5686 0.858887 18.0183C0.858887 18.3437 1.16188 18.8259 1.76788 19.4637C2.96809 20.7317 4.03049 22.0764 4.94988 23.5C5.51147 24.3514 5.88491 24.8737 6.0711 25.0643C6.25627 25.2529 6.82043 25.3499 7.76394 25.3499C9.07641 25.3499 9.85595 25.1816 10.1039 24.843C10.3497 24.5171 10.8044 23.5455 11.4676 21.9257C13.1063 17.8702 15.1438 13.9974 17.5792 10.3101C20.0168 6.6243 22.2493 3.83592 24.2818 1.94693C24.6866 1.58806 24.9299 1.35554 25.0155 1.24812C25.0988 1.14035 25.1413 1.03189 25.1413 0.918911C25.1411 0.740688 25.0231 0.649902 24.788 0.649902Z"
          fill="#09B285"
        />
      </g>
      <defs>
        <clipPath id="clip0_930_7283">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
