import { Box, Button, Portal, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React from 'react';
import {
  currencyColumnFactory,
  dateColumnFactory,
  genericColumnFactory,
  timeColumnFactory
} from '~/components/ColumnDefinitions';

import { StatusBadge, StatusLabel } from '~/components/StatusBadge';
import { Table } from '~/components/Table';
import { clientFiltering } from '~/core/filters';
import { useFilters } from '~/hooks/useFilters';
import { Transaction } from '~/data/Transaction';
import { useState, useEffect } from 'react';
import { User } from '~/data/User';

export function UserTable({
  tableHeight,
  allowCheckboxSelection = false,
  tableName,
  tableData,
  setEditUser,
}: {
    tableHeight?: number;
    allowCheckboxSelection?: boolean;
    tableName: string;
    tableData: User[] | undefined; 
    setEditUser: (newState: boolean, userID: string) => void;  // Function type definition
}) {
  const { filters } = useFilters();
  const [transformedData, setTransformedData] = useState<any[]>([]); // Use state for transformedData

  useEffect(() => {
    // Update transformedData whenever tableData changes
    if (tableData) {
      console.log('Table Data:', tableData);
      console.log('Transformed Data:', tableData.map((item: User) => item.toJSON()));  
      setTransformedData(tableData.map((item: User) => item.toJSON()));
    } else {
      setTransformedData([]);
    }
  }, [tableData]); // Include tableData in dependency array


 

  const handleClick = (userId: string) => {
    console.log("Edit button clicked for user ID:", userId);
    setEditUser(true, userId);
  };


  const columnDefinitions: GridColDef[] = [
      genericColumnFactory({
      field: 'name',
      headerName: 'Name',
    }),
    genericColumnFactory({
        field: 'username',
        headerName: 'Username',
      }),
      genericColumnFactory({
        field: 'email',
        headerName: 'Email',
      }),
      genericColumnFactory({
        field: 'active',
        headerName: 'Active',
        renderCell: (params) => (
          <Box
            sx={{
              lineHeight: 1.2,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StatusBadge status={params.value as StatusLabel} />
          </Box>
        ),
      }),
      genericColumnFactory({
        field: 'edit',
        headerName: '',
        renderCell: (params) => (
          <Box sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              component="a"
              onClick={() => handleClick(params.id as string)}  // Pass the unique identifier
            >
              Edit
            </Button>
          </Box>
        ),
      }),
  ];

  return (
    
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        gap: 1.5,
      }}
    >
                <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '2xl', sm: '3xl' },
            lineHeight: 1.25,
          }}
        >
          {tableName}
        </Typography>
      <Table
        tableHeight={tableHeight}
        columns={columnDefinitions}
        rows={clientFiltering(transformedData, filters)}
        initialState={{
          sorting: { sortModel: [{ field: 'name', sort: 'desc' }] },
        }}
        pagination
        hideFooter={false}
        disableColumnFilter
        slots={{ toolbar: CustomQuickSearch }}
        checkboxSelection={allowCheckboxSelection}
        getRowClassName={(params) => {
          if (params.row.status === 'failed') {
            return 'datagrid-row-error';
          }
          return '';
        }}
      />
    </Box>
  );
}

function CustomQuickSearch(props: any) {
  return (
    <React.Fragment>
      <Portal container={() => document.getElementById('filter-panel')!}>
        <GridToolbarQuickFilter
          variant="filled"
          placeholder="Search name, email, status..."
          sx={{
            width: 200,
            borderColor: 'gray.200',
            paddingBottom: 0,
            '.MuiInputBase-root': {
              fontSize: 'xs',
              height: 30,
              paddingX: 0.5,
            },
            '.MuiInputBase-input': {
              paddingY: 0,
            },
            '.MuiSvgIcon-root': {
              height: 16,
              width: 16,
            },
          }}
        />
      </Portal>
    </React.Fragment>
  );
}

export default UserTable;
