import { PropsWithChildren } from 'react';
import { cn } from '~/lib/utils/style';

export const H1 = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <h1 className={cn('text-4xl md:text-[56px] font-semibold leading-tight text-black', className)}>
      {children}
    </h1>
  );
};

export const H2 = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <h2 className={cn('text-3xl md:text-5xl font-bold md:leading-tight text-black', className)}>
      {children}
    </h2>
  );
};

export const H3 = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <h3 className={cn('text-2xl md:text-[40px] font-bold leading-tight text-black', className)}>
      {children}
    </h3>
  );
};

export const H4 = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return <h4 className={cn('text-xl md:text-3xl font-bold text-black', className)}>{children}</h4>;
};

export const H5 = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return <h5 className={cn('text-lg md:text-2xl font-bold text-black', className)}>{children}</h5>;
};

export const H6 = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return <h5 className={cn('text-base md:text-xl font-bold text-black', className)}>{children}</h5>;
};

export const PL = ({
  children,
  className,
  dangerouslySetInnerHTML,
}: PropsWithChildren<
  {
    className?: string;
  } & React.HTMLProps<HTMLParagraphElement>
>) => {
  return (
    <p
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      className={cn('text-base md:text-lg text-bg-primary font-normal', className)}
    >
      {children}
    </p>
  );
};

export const PM = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return <p className={cn('font-normal text-bg-primary', className)}>{children}</p>;
};

export const Label = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return <p className={cn('uppercase text-primary font-bold', className)}>{children}</p>;
};

export const Tag = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <div
      className={cn(
        'uppercase bg-primary text-sm font-semibold py-1 px-3 rounded-xl w-max text-white',
        className,
      )}
    >
      <p>{children}</p>
    </div>
  );
};
