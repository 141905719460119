import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as React from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '../theme';
import { UserProvider } from '~/context/UserContext';  // Import the provider
import { ThemeCustomizationProvider } from '~/context/ThemeCustomizationProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <ThemeCustomizationProvider>
      <ThemeProvider>
        <UserProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            {children}
          </LocalizationProvider>
        </UserProvider>
      </ThemeProvider>
    </ThemeCustomizationProvider>
  );
}
