


export class Spender {
    private id: string;
    private cardNumber: string;
    private transactions: number;
    private amount: number;

    constructor(cardNumber: string, transactions: number, amount: number) {
        // generate a random id
        this.id = Math.random().toString(36).substr(2, 9);
        this.cardNumber = cardNumber;
        this.transactions = transactions;
        this.amount = amount;
    }

    public toJSON(): {
        id: string;
        cardNumber: string;
        transactions: number;
        amount: number;

    } {
        return {
            id: this.id,
            cardNumber: this.cardNumber,
            transactions: this.transactions,
            amount: this.amount
        };
    }



}
