import { cn } from '~/lib/utils/style';
import { FeatureId } from '~/components/pages/fleeting-management/offer-features-data';

export type OfferImageProps = {
  imageId: FeatureId;
};

const imagesData: Record<FeatureId, string> = {
  recoverRevenue:
    'https://plus.unsplash.com/premium_photo-1725793870057-7bc3ba446336?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  saveTime:
    'https://plus.unsplash.com/premium_photo-1725793869107-b0c525b2c8f3?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  streamline:
    'https://plus.unsplash.com/premium_photo-1725793868596-d2d4b94fcd93?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  optimization:
    'https://plus.unsplash.com/premium_photo-1725793870248-f6c0f54a8239?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
};

export const OfferImage = ({ imageId }: OfferImageProps) => {
  return (
    <div className="sticky top-0 max-h-screen grid items-center">
      <figure
        className={cn(
          'hidden relative lg:block mx-auto min-w-full sm:min-w-[400px] lg:min-w-[500px] h-full lg:h-auto min-h-[250px] sm:min-h-[300px] xl:min-w-[570px] bg-gray-500 xl:h-[600px] rounded-lg overflow-hidden',
        )}
      >
        {Object.keys(imagesData).map((imageKey, index) => (
          <img
            key={index}
            src={imagesData[imageKey as FeatureId]}
            className={cn(
              'absolute inset-0 size-full object-cover transition-opacity duration-300',
              imageId === imageKey ? 'opacity-100' : 'opacity-0',
            )}
            alt="Motion picture"
          />
        ))}
      </figure>
    </div>
  );
};
