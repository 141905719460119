import { ReactNode } from 'react';
import { cn } from '~/lib/utils/style';

export type FeatureProps = {
  children: ReactNode;
  reversed?: boolean;
};

export const Feature = ({ children, reversed }: FeatureProps) => {
  return (
    <div className="flex flex-col lg:flex-row gap-12 justify-between 2xl:justify-center">
      <figure
        className={cn(
          'block mx-auto min-w-full sm:min-w-[400px] lg:min-w-[500px] h-full lg:h-auto min-h-[250px] sm:min-h-[300px] xl:min-w-[570px] bg-gray-500 xl:h-[600px] rounded-lg overflow-hidden',
          reversed && 'lg:order-1',
        )}
      >
        {/* <img src={imageSrc} className='object-cover' alt='Motion picture' /> */}
      </figure>
      <div className="flex flex-col gap-8 justify-center text-center w-full lg:text-left lg:w-[616px]">
        {children}
      </div>
    </div>
  );
};
