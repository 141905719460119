
export interface LocationData {
    locationID: string;
    locationName: string;
    locationAddress: string;
    locationCity: string;
    locationStateProv: string;
    locationZip: string;
    locationSupplier: string;
    dieselPrice: string;
    DEFAvailable: boolean;
    latitude: number;
    longitude: number;

}


export class Location {
    private locationID: string;
    private locationName: string;
    private locationAddress: string;
    private locationCity: string;
    private locationStateProv: string;
    private locationZip: string;
    private locationSupplier: string;
    private dieselPrice: string;
    private DEFAvailable: boolean;
    private latitude: number;
    private longitude: number;

    constructor(locationID: string, locationName: string, locationAddress: string, locationCity: string, locationStateProv: string, locationZip: string, locationSupplier: string, dieselPrice: string, DEFAvailable: boolean, latitude: number, longitude: number) {
        // generate a random id
        this.locationID = locationID
        this.locationName = locationName;
        this.locationAddress = locationAddress;
        this.locationCity = locationCity;
        this.locationStateProv = locationStateProv;
        this.locationZip = locationZip;
        this.locationSupplier = locationSupplier;
        this.dieselPrice = dieselPrice;
        this.DEFAvailable = DEFAvailable;
        this.latitude = latitude;
        this.longitude = longitude;
    }

    getLocationID(): string {
        return this.locationID;
    }

    getLocationName(): string {
        return this.locationName;
    }

    getLocationAddress(): string {
        return this.locationAddress;
    }

    getLocationCity(): string {
        return this.locationCity;
    }

    getLocationStateProv(): string {
        return this.locationStateProv;
    }

    getLocationZip(): string {
        return this.locationZip;
    }

    getDieselPrice(): string {
        return this.dieselPrice;
    }

    getDEFAvailable(): boolean {
        return this.DEFAvailable;
    }

    getLatitude(): number {
        return this.latitude;
    }

    getLongitude(): number {
        return this.longitude;
    }

    getLocationSupplier(): string {
        return this.locationSupplier;
    }


    public toJSON(): {
        id: string;
        locationName: string;
        locationAddress: string;
        locationSupplier: string;
        dieselPrice: string;
        DEFAvailable: string;
        } {
        return {
        id: this.locationID,
        locationName: this.locationName,
        locationAddress: this.locationAddress + ', ' + this.locationCity + ', ' + this.locationStateProv + ', ' + this.locationZip,
        locationSupplier: this.locationSupplier,
        dieselPrice: this.dieselPrice,
        DEFAvailable: this.DEFAvailable ? 'Yes' : 'No',
        };
      }

}
