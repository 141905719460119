import { Feature } from '~/components/ui/feature';
import { H2, PL, Tag } from '~/components/ui/typography';

export const Payout = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient2 from-gradient1">
      <div className="container">
        <Feature>
          <div className="flex flex-col items-center lg:items-start gap-6">
            <Tag>Instant</Tag>
            <H2>
              Instant Payout: Get paid quickly for your transportation services with REV Capital.
            </H2>
            <PL>
              At REV Capital, we understand the importance of quick access to funds. That's why we
              offer instant payouts to our clients, ensuring that you get paid promptly for your
              transportation services.
            </PL>
          </div>
        </Feature>
      </div>
    </div>
  );
};
