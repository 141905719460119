
import { Box, Button, Typography } from '@mui/material';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { useState, useEffect } from 'react';
import DBManager from '~/manager/DBManager';
import { useUser } from '~/context/UserContext';
import { styled } from '@mui/system';
import FeaturesSmallColumn from '~/components/kit/marketing/page-sections/FeaturesSmallColumn';
import { TbCalendarTime, TbHeadset, TbLocation, TbMapPin, TbRoute, TbTool, TbWallet } from 'react-icons/tb';

export function Dispatch() {
    const { user } = useUser();
    const [onWaitlist, setOnWaitlist] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const dbManager = new DBManager();
            if (!user?.companyID) {
                return;
            }
            const waitlistValue = await dbManager.getWaitlist('dispatch', user?.companyID)
            setOnWaitlist(waitlistValue);
        };

        fetchData();
    }, []);

    const joinWaitlist = async () => {
        const dbManager = new DBManager();
        if (!user?.companyID) {
            return;
        }
        await dbManager.updateWaitlist('dispatch', user?.companyID);
        setOnWaitlist(true);
    }

    
    const GradientText = styled('span')({
        background: 'linear-gradient(to right, #0974f1, #07f49e)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    });


    return (
        <PageContainer>


        <Typography
            variant="h1"
            sx={{ fontSize: { xs: '2xl', sm: '3xl' }, textAlign: 'center' }}
        >
            Join the Waitlist for <br />
            <Typography
                variant="h1"
                sx={{ fontSize: { xs: '2xl', sm: '3xl' }, textAlign: 'center', display: 'inline' }}
            >
                <GradientText>Dispatch</GradientText>
            </Typography>
        </Typography>

        <Box>
    <FeaturesSmallColumn 
    
    features={[
        {
            title: 'Automate Dispatching',
            description: 'Streamline scheduling and job assignments to ensure timely deliveries and maximize efficiency.',
            icon: <TbCalendarTime />,
        },
        {
            title: 'Real-time Delivery Tracking',
            description: 'Monitor shipments and driver locations in real-time for enhanced visibility and customer satisfaction.',
            icon: <TbMapPin />,
        },
        {
            title: 'Route Optimization',
            description: 'Optimize delivery routes to reduce fuel costs and delivery times, improving profitability.',
            icon: <TbRoute />,
        },
        {
            title: '24/7 Support',
            description: 'Receive round-the-clock assistance from our dispatch experts to handle any operational challenges.',
            icon: <TbHeadset />,
        },
        
    ]}
    />
</Box>


        {(!onWaitlist) && (
            <Button
                variant='contained'
                color='primary'
                onClick={joinWaitlist}
                // Center the button on the page
                sx={{ margin: 'auto', width: '25%' }}
            >
                Join Waitlist
            </Button>
        )}

        {(onWaitlist) && (

            <Typography
                // center text on page
                sx={{ textAlign: 'center' }}
            >
                You are on the waitlist for Motion Dispatch. We will notify you once it is available.
            </Typography>


        )}
    </PageContainer>
    );
}

export default Dispatch;