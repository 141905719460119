import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useUser } from '~/context/UserContext';
import StripeManager from '~/manager/StripeManager';
import CircularProgress from '@mui/material/CircularProgress';
import { StatusBadge } from './StatusBadge';

interface PaymentCardProp {
    currency: string;
}

export function PaymentCard({ currency }: PaymentCardProp) {
    const { user } = useUser();
    const [paymentMethod, setPaymentMethod] = useState<any>({});
    const [setupPaymentMethod, setSetupPaymentMethod] = useState<any>({});

    const [isSetupPaymentMethod, setIsSetupPaymentMethod] = useState(false);
    const [isSetup, setIsSetup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const checkoutURL = async (currency: string) => {
        const stripeManager = new StripeManager();
        if (user?.stripeID) {
            const checkoutURL = await stripeManager.createSetupCheckoutSession(user.stripeID, currency);
            return checkoutURL;
        }
        return '';
    };

    const getPaymentMethod = async (currency: string) => {
        const stripeManager = new StripeManager();
        if (user?.stripeID) {
            const paymentMethod = await stripeManager.getPaymentMethod(user.stripeID, currency);
            console.log("Currency:", currency);
            if (paymentMethod.setupPaymentMethod) {
                setSetupPaymentMethod(paymentMethod.setupPaymentMethod);
                setIsSetupPaymentMethod(true);
                console.log('Setup Payment Method:', paymentMethod.setupPaymentMethod);
            } else {
                setIsSetupPaymentMethod(false);
                setSetupPaymentMethod({});
            }




            if (paymentMethod.currentPaymentMethod) {
                setPaymentMethod(paymentMethod.currentPaymentMethod);
                setIsSetup(true);
                console.log('Current Payment Method:', paymentMethod.currentPaymentMethod);
            } else {
                setIsSetup(false);
                setPaymentMethod({});
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getPaymentMethod(currency);
    }, [currency]); // Add the currency as a dependency to avoid infinite loops


    const cancelSetup = async () => {
        const stripeManager = new StripeManager();
        if (user?.stripeID) {
            await stripeManager.cancelSetup(user.stripeID, currency);
            setIsLoading(true);
            getPaymentMethod(currency);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                border: (theme) => `1px solid ${theme.palette.gray['200']}`,
                padding: 0.5,

                borderRadius: 1.5,
            }}
        >
            <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%' }}>
                <Box sx={{ padding: 1.5, width: '100%' }}>
                    <CardContent sx={{ paddingX: 1, paddingY: 1, paddingBottom: 2 }}>
                        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Typography variant="h2">
                                {'Manage Your ' + currency.toUpperCase() + ' Billing Method'}
                            </Typography>

                            {(!isLoading) && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={async () => {
                                        const url = await checkoutURL(currency);
                                        window.location.href = url;
                                    }}
                                >
                                    {isSetup ? 'Update' : 'Add'} Payment Method
                                </Button>
                            )}
                        </Stack>

                    </CardContent>

                    {isLoading && (

                        <CircularProgress />
                    )}


                    {(!isLoading && isSetup) && (
                        <Box>
                            <Typography variant="h3">Current Payment Method</Typography>

                            {paymentMethod['type'] == 'card' && (
                                <Box>
                                    <Box>
                                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Stack spacing={1}>
                                                <Typography>x{paymentMethod['Last4']}</Typography>
                                                <Typography>{paymentMethod['ExpiryMonth']}/{paymentMethod['ExpiryYear']}</Typography>
                                                <Typography>{paymentMethod['Brand']}</Typography>
                                            </Stack>
                                            <StatusBadge status="Active" customSx={{ flexShrink: 0 }} />
                                        </Stack>
                                    </Box>
                                </Box>


                            )}

                            {paymentMethod['type'] == 'acss_debit' && (

                                <Box>
                                    <Stack spacing={1} direction='row' justifyContent='space-between' alignItems='center'>
                                        <Stack spacing={1}>
                                            <Typography>{paymentMethod['BankName']}</Typography>
                                            <Typography>x{paymentMethod['Last4']}</Typography>
                                        </Stack>
                                        {/* Prevent StatusBadge from expanding */}
                                        <Box sx={{ flexShrink: 0 }}>
                                            <StatusBadge status='Active' />
                                        </Box>
                                    </Stack>
                                </Box>


                            )}
                        </Box>
                    )}

                    {(!isLoading && isSetupPaymentMethod) && (

                        <Stack spacing={1}>

                            <Box>
                                <Typography variant="h3">Action Required: Verify Payment Method</Typography>

                                {setupPaymentMethod['type'] == 'card' && (
                                    <Box>
                                        <Stack spacing={1}>
                                            <Typography>x{setupPaymentMethod['Last4']}</Typography>
                                            <Typography>{setupPaymentMethod['ExpiryMonth']}/{setupPaymentMethod['ExpiryYear']}</Typography>
                                            <Typography>{setupPaymentMethod['Brand']}</Typography>
                                        </Stack>
                                    </Box>
                                )}

                                {setupPaymentMethod['type'] == 'acss_debit' && (

                                    <Box>
                                        <Stack spacing={1} direction='row' justifyContent='space-between'>
                                            <Stack spacing={1}>
                                                <Typography>{setupPaymentMethod['BankName']}</Typography>
                                                <Typography>x{setupPaymentMethod['Last4']}</Typography>
                                            </Stack>
                                            <Box>
                                                <Stack spacing={1} direction='row'>

                                                    <Button
                                                        variant="outlined"
                                                        color='error'
                                                        onClick={cancelSetup}
                                                    >
                                                        Cancel
                                                    </Button>


                                                    <Button
                                                        variant="outlined"
                                                        color='success'
                                                        onClick={async () => {
                                                            if (setupPaymentMethod['nextAction']) {
                                                                window.open(setupPaymentMethod['nextAction'], '_blank');
                                                            }
                                                        }}
                                                    >
                                                        Verify
                                                    </Button>

                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Box>


                                )}
                            </Box>


                        </Stack>
                    )}



                </Box>
            </Card>
        </Box>
    );
}
