import { Card, CardData } from "~/data/Card";
import { Invoice, InvoiceData } from "~/data/Invoice";
import { MoneyCode, MoneyCodeData } from "~/data/MoneyCode";
import { Transaction, TransactionData } from "~/data/Transaction";
import { User, UserData } from "~/data/User";
import AuthManager from "./AuthManager";
import { Location, LocationData } from "~/data/Location";


class DBManager {
    environment: string = window.location.hostname === 'localhost' 
        ? 'dev' 
        : window.location.hostname.includes('lazarus') 
        ? 'staging' 
        : 'production';

    apiLink: string = this.environment === 'dev' 
        ? 'http://127.0.0.1:5000' 
        : this.environment === 'staging' 
        ? 'https://staging.api.withmotion.com' 
        : 'https://api.withmotion.com';

    getAccessToken = () => {
        return localStorage.getItem('accessToken');
    }

    async getInvoices(companyID?: string): Promise<any> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getInvoices?companyID=${companyID}`;
    
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

    
                const data = await response.json(); // Assuming the server responds with JSON
                const invoiceObject = JSON.parse(data.body);
                console.log('Invoice Object:', invoiceObject);
                const invoiceList: Invoice[] = [];
                // Loop through each transaction in the JSON array
                invoiceObject.invoices.forEach((invoice: InvoiceData) => {
                    const {
                        id,
                        companyID,
                        s3Key,
                        creationDate,
                        currency,
                        amount,
                        status,
                        dueDate,
                        name
                    } = invoice;
                
                
                    // Create a new Invoice object
                    const createdInvoice = new Invoice(
                        id,
                        companyID,
                        s3Key,
                        creationDate,
                        currency,
                        amount as unknown as number,
                        status,
                        dueDate
                    );
                    invoiceList.push(createdInvoice);
                });
                
                console.log("Invoice List:", invoiceList);

                if (invoiceList) { // Check if data has invoices key
                    return invoiceList; // Return the array of invoices
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }

    async getInvoiceCount(companyID?: string): Promise<any> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getInvoiceCount?companyID=${companyID}`;
    
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

    
                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const invoiceObject = JSON.parse(data.body);
            
                if (invoiceObject) { // Check if data has invoices key
                    return invoiceObject.invoiceCount; // Return the array of invoices
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }


    async getMoneyCodes(companyID: string, pageSize: number, page: number): Promise<{ codes: MoneyCode[], total: number }> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getMoneyCodes?companyID=${companyID}&pageSize=${pageSize}&page=${page}`;
    
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

    
                const data = await response.json(); // Assuming the server responds with JSON
                const moneyCodes = JSON.parse(data.body);
                const codeList: MoneyCode[] = [];
                // Loop through each transaction in the JSON array
                moneyCodes.moneyCodes.forEach((moneyCode: MoneyCodeData) => {
                    const {
                        id,
                        code,
                        amountUsed,
                        amountCreated,
                        created,
                        notes
                    } = moneyCode;
                
                
                    // Create a new Invoice object
                    const createdCode = new MoneyCode(
                        id,
                        code,
                        amountUsed,
                        amountCreated,
                        created,
                        notes
                    );
                    codeList.push(createdCode);
                });
                
                if (codeList) { // Check if data has invoices key
                    return {codes: codeList, total: moneyCodes.totalCodes}; // Return the array of invoices
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }



    async getCardCount(companyID?: string): Promise<any> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getCardCount?companyID=${companyID}`;
    
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const cardObject = JSON.parse(data.body);
            
                if (cardObject) { // Check if data has invoices key
                    return cardObject.cardCount; // Return the array of invoices
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }

    async getFuelReport(companyID?: string, dateRange?: number, country?: string): Promise<{ date: string[], quantity: number[] }> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getFuelReport?companyID=${companyID}&dateRange=${dateRange}&country=${country}`;
            console.log('Getting fuel report for company:', companyID);
            console.log('Date range:', dateRange);
            console.log('Country:', country);
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

    
                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Fuel Data:', data);

                if (data) { // Check if data has invoices key
                    console.log(data.body.date, data.body.quantity);
                    return {date: data.body.date,quantity: data.body.quantity}; // Return the date and quantity
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }
    
    async getRecentTransactions(companyID?: string): Promise<{ transactions: Transaction[]}> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getRecentTransactions?companyID=${companyID}`;
  
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    },
                    credentials: 'include'
                });
    
                this.checkResponse(response);

    
                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const body = JSON.parse(data.body);
                console.log('Transaction List:', body.transactions);
                // Create array of Transaction objects

                const transactions: Transaction[] = [];

                // Loop through each transaction in the JSON array
                body.transactions.forEach((transactionData: string[]) => {
                  // Extract data from the array
                  console.log(transactionData)
                  const [
                    id,
                    transactionDate,
                    transactionTime,
                    product,
                    price,
                    amount,
                    quantity,
                    cardNumber,
                    location,
                    country
                  ] = transactionData;
              
                  // Create a new Transaction object
                  const transaction = new Transaction(
                    id,
                    transactionDate,
                    transactionTime,
                    cardNumber,
                    product,
                    parseFloat(quantity), // Convert quantity to number
                    parseFloat(price), // Convert price to number
                    parseFloat(amount), // Convert amount to number
                    location,
                    country);    
                    console.log('Transaction Parsed:', transaction);
                  // Add the new Transaction to the transactions array
                  transactions.push(transaction);
                });

                if (transactions) { // Check if data has invoices key
                    return {transactions: transactions}; // Return the date and quantity
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }


    async getUsers(companyID?: string): Promise<{ users: User[]}> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getUsers?companyID=${companyID}`;
  
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

    
                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const body = JSON.parse(data.body);
                console.log('User List:', body.users);
                // Create array of Transaction objects

                const usersList: User[] = [];

                body.users.forEach((userData: UserData) => {
                    // Directly access properties from the userData object
                    const {
                        id,
                        email,
                        firstname,
                        lastname,
                        active,
                        phonenumber,
                        username
                    } = userData;
                    
                    // Create a new User object and add it to the usersList array
                    const user = new User(
                        id,
                        email,
                        firstname,
                        lastname,
                        active, // Already a boolean according to your sample data
                        phonenumber,
                        username
                    );
                    
                    usersList.push(user);
                });

                if (usersList) { // Check if data has invoices key
                    return {users: usersList}; // Return the date and quantity
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }

    async updateUser(user: User | undefined, password: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/updateUser`;

        if (!user) {
            throw new Error('User is required');
        }

        console.log('Updating user with id:', user.getId());
        try {
            console.log('User:', user);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    id: user.getId(),
                    email: user.getEmail(),
                    firstName: user.getFirstName(), // Changed 'firstname' to 'firstName'
                    lastName: user.getLastName(),   // Changed 'lastname' to 'lastName'
                    isActive: user.isActive(),      // Use 'isActive' consistently
                    phoneNumber: user.getPhoneNumber(), // Changed 'phonenumber' to 'phoneNumber'
                    username: user.getUsername(),
                    password: password
                })
                
            });

            this.checkResponse(response);

            const data = await response.json(); // Assuming the server responds with JSON
            console.log('Updated User:', data);
            const bodyObject = JSON.parse(data.body);

            if (bodyObject) {
                console.log('Updated User:', bodyObject);
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }


    async createUser(user: User | undefined, password: string, companyID: string | undefined): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/createUser`;

        if (!user) {
            throw new Error('User is required');
        }

        if (!companyID) {
            throw new Error('Company ID is required');
        }

        try {
            console.log('User:', user);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyID: companyID,
                    email: user.getEmail(),
                    firstName: user.getFirstName(), // Changed 'firstname' to 'firstName'
                    lastName: user.getLastName(),   // Changed 'lastname' to 'lastName'
                    isActive: user.isActive(),      // Use 'isActive' consistently
                    phoneNumber: user.getPhoneNumber(), // Changed 'phonenumber' to 'phoneNumber'
                    username: user.getUsername(),
                    password: password
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON
            console.log('Created User:', data);
            const bodyObject = JSON.parse(data.body);

            if (bodyObject) {
                console.log('Created User:', bodyObject);
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }

    async getCards(companyID?: string): Promise<{ cards: Card[]}> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getCards?companyID=${companyID}`;
  
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);

                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const body = JSON.parse(data.body);
                console.log('Card List:', body.cards);
                // Create array of Transaction objects

                const cardList: Card[] = [];


                body.cards.forEach((cardData: CardData) => {
                    // Directly access properties from the userData object
                    const {
                        id,
                        cardNumber,
                        unitNumber,
                        driverName,
                        driverID,
                        supplier,
                        status
                    } = cardData;
                    
                    // Create a new Card object and add it to the usersList array
                    const card = new Card(
                        id,
                        cardNumber,
                        unitNumber,
                        driverName,
                        driverID,
                        supplier,
                        status
                    );
                    
                    cardList.push(card);
                });


                if (cardList) { // Check if data has invoices key
                    return {cards: cardList}; // Return the date and quantity
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }


    async updateCard(card: Card, companyID: string | undefined, PIN: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/updateCard`;


        if (!companyID) {
            throw new Error('Company ID is required');
        }

        try {
            console.log('Card:', card);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyID: companyID,
                    cardNumber: card.getCardNumber(),   
                    unitNumber: card.getUnitNumber(),
                    driverName: card.getDriverName(),
                    driverID: card.getDriverID(),
                    supplier: card.getSupplier(),
                    status: card.getStatus(),
                    PIN: PIN
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON
            console.log('Updated Card:', data);
            const bodyObject = JSON.parse(data.body);

            if (bodyObject) {
                console.log('Updated Card:', bodyObject);
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }

    async sendResetCode(email: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/sendResetCode`;
        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON
            const bodyObject = JSON.parse(data.body);
            console.log('Data:', data);

            if (bodyObject) {
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }


    async checkResetCode(email: string, code: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/verifyResetCode`;
        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    email: email,
                    code: code
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON
            const bodyObject = JSON.parse(data.body);

            if (bodyObject) {
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }

    async changePassword(email: string, password: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/changePassword`;
        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON
            const bodyObject = JSON.parse(data.body);

            if (bodyObject) {
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }


    async getTransactions(pageSize: number, page: number, companyID?: string): Promise<{ transactions: Transaction[], totalTransactions: number}> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getTransactions?companyID=${companyID}&pageSize=${pageSize}&page=${page}`;
  
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });

                this.checkResponse(response);

                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const body = JSON.parse(data.body);
                console.log('Transaction List:', body.transactions);
                // Create array of Transaction objects

                const transactions: Transaction[] = [];
               
                body.transactions.forEach((transactionData: any[]) => {
                    // Use array destructuring instead;
                    const [
                        id,
                        transactionDate,
                        transactionTime,
                        product,
                        price,
                        amount,
                        quantity,
                        cardNumber,
                        location,
                        country
                    ] = transactionData;
                
                    console.log('Transaction Data:', transactionData);
                    
                    // Create a new Transaction object assuming proper types exist.
                    const transaction = new Transaction(
                        id,
                        transactionDate,
                        transactionTime,
                        cardNumber,
                        product,
                        parseFloat(quantity.toString()),   // Ensure parsing from possible numeric inputs
                        parseFloat(price.toString()),      // Ensure parsing from possible numeric inputs
                        parseFloat(amount.toString()),     // Ensure parsing from possible numeric inputs
                        location,
                        country
                    );
                    transactions.push(transaction);
                });
                
                  if (transactions) { // Check if data has invoices key
                      return {transactions: transactions, totalTransactions: body.totalTransactions}; // Return the date and quantity
                  } else if (data.error) {
                      throw new Error(data.error);
                  } else {
                      throw new Error("Unexpected response format");
                  }
      
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }


    async getDownloadLink(invoiceID: string): Promise<string> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getDownloadLink?invoiceID=${invoiceID}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });

                this.checkResponse(response);


                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const body = JSON.parse(data.body);
                return body.downloadLink;

            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }
    }


    async getTransactionReport( startDate: string, endDate: string, cardNumbers: string[], companyID?: string): Promise<void> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getTransactionReport?companyID=${companyID}&startDate=${startDate}&endDate=${endDate}&cardNumbers=${cardNumbers}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });

                this.checkResponse(response);


                console.log('Getting Transaction Report:', response);   
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = "transactionreport.xlsx";  // Setting filename to download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);  // Clean up the URL object
                return ;
            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }
    }

    async createMoneyCode(companyID: string, codeAmount: number, codeNotes: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/createMoneyCode`;

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyID: companyID,
                    codeAmount: codeAmount,
                    codeNotes: codeNotes
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON

            const bodyObject = JSON.parse(data.body);

            if (bodyObject) {
                console.log('Updated User:', bodyObject);
                return {code: data.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }

    async updateNotification(companyID: string, transactionNotification?: boolean, transactionLimit?: number, marketingNotification?: boolean): Promise<any> {
        const url = `${this.apiLink}/updateNotification`;

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyID: companyID,
                    transactionNotification: transactionNotification,
                    marketingNotification: marketingNotification,
                    transactionLimit: transactionLimit
                })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON

            const bodyObject = JSON.parse(data.body);
            console.log('Updated User:', bodyObject);
            if (bodyObject) {
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
                return ;
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }



    async changePasswordConfirmed(email: string, oldPassword: string, newPassword: string): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/changePasswordConfirmed`;
        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    email: email,
                    oldPassword: oldPassword,
                    newPassword: newPassword
                })
                
            });

            this.checkResponse(response);

            
            const bodyObject = await response.json(); // Assuming the server responds with JSON
            console.log('Data:', bodyObject);

            if (bodyObject) {
                return {code: bodyObject.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }

    async createLead(companyName: string, locationSelected: string, ownerName: string, unitCount: string, dieselVolume: string, email: string, phoneNumber: string, fuelCard: boolean, factoring: boolean, fleetManagement: boolean): Promise<{code: number, message: string}> {
        const url = `${this.apiLink}/createLead`;
        console.log('Creating lead:', companyName);
        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyName: companyName,
                    locationSelected: locationSelected,
                    ownerName: ownerName,
                    unitCount: unitCount,
                    dieselVolume: dieselVolume,
                    email: email,
                    phoneNumber: phoneNumber,
                    fuelCard: fuelCard,
                    factoring: factoring,
                    fleetManagement: fleetManagement
                })
                
            });

            this.checkResponse(response);

            
            const bodyObject = await response.json(); // Assuming the server responds with JSON
            console.log('Data:', bodyObject);

            if (bodyObject) {
                return {code: bodyObject.statusCode, message: bodyObject.message as string};
                // You can now use `bodyObject.accessToken` and `bodyObject.refreshToken` as needed
            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }

    async getOverview(companyID?: string, dateRange?: number): Promise<any> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getOverview?companyID=${companyID}&dateRange=${dateRange}`;
    
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });
    
                this.checkResponse(response);
    
                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                const overviewObject = JSON.parse(data.body);
            
                if (overviewObject) { // Check if data has invoices key
                    return overviewObject; // Return the array of invoices
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }
    
            } catch (error) {
                throw error;
            }
    
        } catch (error) {
            throw error;
        }
    }
    
    async updateWaitlist(waitlist: string, companyID: string): Promise<any> {
        const url = `${this.apiLink}/updateWaitlist`;

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyID: companyID,
                    waitlist: waitlist
                  })
                
            });

            this.checkResponse(response);

            
            const data = await response.json(); // Assuming the server responds with JSON

            const bodyObject = (data);

            if (bodyObject) {

            } else {
                throw new Error("Unexpected response format");
            }
           
        } catch (error) {
            console.error('Error during updating waitlist:', error);
            throw error; 
        }
    }

    async getWaitlist(waitlist: string, companyID: string): Promise<any> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getWaitlist?companyID=${companyID}&waitlist=${waitlist}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });

                this.checkResponse(response);

                console.log('Getting Waitlist:', response);
                const data = await response.json(); // Assuming the server responds with JSON

                const waitlistValue = (data.waitlist);
                return waitlistValue;

            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }
    }

    async getLocations(companyID?: string): Promise<any> {
        try {
            // Build the URL with the query parameter
            const url = `${this.apiLink}/getLocations?companyID=${companyID}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getAccessToken()}`
                    } 
                });

                this.checkResponse(response);
                const data = await response.json(); // Assuming the server responds with JSON
           
                const locations: Location[] = [];
                data.locations.forEach((locationData: LocationData) => {

                    const {
                        locationID,
                        locationName,
                        locationAddress,
                        locationCity,
                        locationStateProv,
                        locationZip,
                        dieselPrice,
                        DEFAvailable,
                        latitude,
                        longitude,
                        locationSupplier
                    } = locationData;
                            
                    
                    // Create a new Transaction object assuming proper types exist.
                    const transaction = new Location(
                        locationID,
                        locationName,
                        locationAddress,
                        locationCity,
                        locationStateProv,
                        locationZip,
                        locationSupplier,
                        dieselPrice,
                        DEFAvailable,
                        latitude,
                        longitude
                    );
                    locations.push(transaction);
                });

                if (locations) { // Check if data has invoices key
                    return locations; // Return the date and quantity
                } else if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Unexpected response format");
                }

            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }
    }


    async requestCards(cardCount: number, companyID: string, cardType: string): Promise<any> {

        const url = `${this.apiLink}/requestCards`;

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getAccessToken()}`
                },
                body: JSON.stringify({
                    companyID: companyID,
                    cardCount: cardCount,
                    cardType: cardType
                  })
                
            });

            this.checkResponse(response);
        } catch (error) {
            throw error;
        }
    };

    async checkResponse(response: Response) {
        // Check if response code is 401 (Unauthorized) 
        if (response.status === 401) {
            // Redirect to login page
            const manager = new AuthManager();
            manager.logout();
            window.location.href = '/login';
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }


}

export default DBManager;
