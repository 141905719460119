import { H2, H3, H4, Label, PL, PM } from '~/components/ui/typography';
import { Button } from '../../ui/button';
import { streamlineData } from '~/lib/constants';

export const Streamline = () => {
  return (
    <div className="py-20 bg-gradient-to-b from-gradient3 to-gradient2">
      <div className="container">
        <div className="flex flex-col items-center mb-12">
          <H3 className="text-center">Why 1000s of Companies Prefer Motion for Complex Logistics?</H3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 xl:gap-x-6 justify-items-center w-full">
          {streamlineData.map((streamline) => {
            const { title, key, text, icon } = streamline;
            return (
              <div
                className="flex flex-col gap-6 w-[80%] sm:w-96 text-center items-center lg:items-start lg:text-left"
                key={key}
              >
                {icon()}
                <H4>{title}</H4>
                <PM>{text}</PM>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
