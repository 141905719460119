import { Menu } from '~/components/ui/menu';
import { H1, PL, Tag } from '~/components/ui/typography';

export const Header = () => {
  return (
    <div>
      <Menu />
      <div className="pt-32 md:pt-52 pb-20">
        <div className="container">
          <div className="flex flex-col gap-6 text-center items-center pb-20 max-w-[775px] mx-auto">
            <Tag>Announcements</Tag>
            <H1>Insights and updates from across the team.</H1>
            <PL>
              Learn more about Attio, CRM, and GTM from members of our team and industry-leading
              experts.
            </PL>
          </div>
        </div>
      </div>
    </div>
  );
};
