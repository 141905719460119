import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const FrameworkLink = React.forwardRef(
  (props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      // Scroll to the top before navigating to the new route
      window.scrollTo(0, 0);

      // If there's an onClick handler from the parent component, call it
      if (props.onClick) {
        props.onClick(event);
      }
    };

    return (
      <Link {...props} ref={ref} onClick={handleClick} />
    );
  }
);

export { FrameworkLink };
