import { Link } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { PL, PM } from './typography';

export const richTextComponents = {
  block: {
    h1: ({ children }: PropsWithChildren) => (
      <h1 className="text-4xl font-bold my-4">{children}</h1>
    ),
    h2: ({ children }: PropsWithChildren) => (
      <h2 className="text-3xl font-bold my-4">{children}</h2>
    ),
    h3: ({ children }: PropsWithChildren) => (
      <h3 className="text-2xl font-bold my-4">{children}</h3>
    ),
    blockquote: ({ children }: PropsWithChildren) => (
      <blockquote className="border-l-4 pl-4 my-4 italic text-gray-700 border-gray-300">
        {children}
      </blockquote>
    ),
    normal: ({ children }: PropsWithChildren) => <PM className="text-black">{children}</PM>,
    string: ({ children }: PropsWithChildren) => <PL className="text-black">{children}</PL>,
  },

  list: {
    bullet: ({ children }: PropsWithChildren) => (
      <ul className="list-disc ml-8 my-4">{children}</ul>
    ),
    number: ({ children }: PropsWithChildren) => (
      <ol className="list-decimal ml-8 my-4">{children}</ol>
    ),
  },

  listItem: {
    bullet: ({ children }: PropsWithChildren) => <li className="mb-2">{children}</li>,
  },

  marks: {
    link: ({ children, value }: { children: ReactNode; value: { href: string } }) => {
      return (
        <Link href={value.href} className="text-blue-500 hover:text-blue-700">
          {children}
        </Link>
      );
    },
  },
};
