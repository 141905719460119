
class AuthManager {
    environment: string = window.location.hostname === 'localhost' 
        ? 'dev' 
        : window.location.hostname.includes('lazarus') 
        ? 'staging' 
        : 'production';

    apiLink: string = this.environment === 'dev' 
        ? 'http://127.0.0.1:5000' 
        : this.environment === 'staging' 
        ? 'https://staging.api.withmotion.com' 
        : 'https://api.withmotion.com';

    async logout(): Promise<any> {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    async login(): Promise<any> {
        const accessToken = localStorage.getItem('accessToken');
        console.log('Logging in with access token:', accessToken);
        try {
            const url = `${this.apiLink}/getUser`;
            console.log('URL:', url);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        Token: accessToken
                    })
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                
                const data = await response.json(); // Assuming the server responds with JSON
                console.log('Data:', data);
                if (data) {
                    return data;
                }  
            } catch (error) {
                throw error;
            }

        } catch (error) {
            throw error;
        }
    }

    async refreshToken(): Promise<any> {
        const refreshToken = localStorage.getItem('refreshToken');
        const url = `${this.apiLink}/refresh`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            const data = await response.json();
            if (response.status === 200) {
                localStorage.setItem('accessToken', data.accessToken);
            } else {
                throw new Error("Unable to refresh token.");
            }
        } catch (error) {
            console.error('Refresh Token Error:', error);
            // Handle logout, clear tokens, redirect to login, etc.
        }
    };


    async getTokens(email: string, password: string, rememberMe: boolean): Promise<any> {
        const url = `${this.apiLink}/getTokens`;
        console.log('URL:', url);
        console.log('Logging in with email:', email);
        try {
            console.log("URL HERE", url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Email: email,
                    Password: password,
                    RememberMe: rememberMe
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            
            const data = await response.json(); // Assuming the server responds with JSON
            console.log('Token Data:', data);
            if (data) {
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);            
                return ;
            }
           
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    }
}

export default AuthManager;
