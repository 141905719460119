import { PL, Tag } from '~/components/ui/typography';
import { CheckIcon } from '~/components/icons/check-icon';
import { Button } from './button';

const priceCardData = [
  {
    key: 'factoringValue',
    title: 'Maximum Value Factoring:',
    text: "Looking for the industry's lowest rates and maximum value for your trusted customer base? Our recourse factoring package is perfect for you.",
  },
  {
    key: 'eliminateHassle',
    title: 'Eliminate the Hassle of Collections:',
    text: "You get the cash from your invoices same-day, and we'll still handle the collections for up to 90 days.",
  },
  {
    key: 'transferFunds',
    title: 'Transfer funds 24/7:',
    text: 'Easily access and transfer funds anytime, anywhere with our eCapital Connect online portal.',
  },
];

export const PriceCard = () => {
  return (
    <div className="max-w-[500px] w-full p-6 lg:pt-11 lg:px-8 lg:pb-8 bg-white border-2 border-solid border-primary rounded-3xl">
      <Tag className="mb-6 w-full text-center">Best For Fleets</Tag>
      <div className="flex flex-col gap-6 text-black mb-8">
        <p className="uppercase font-normal text-center">
          <span className="font-bold">Recourse</span> <br />
          freight factoring
        </p>
        <div className="text-center">
          <p className="uppercase text-sm">Rates as low as</p>
          <span className="text-6xl font-bold">1.15%</span>
        </div>
        <PL>
          With our recourse factoring package, you are ultimately held responsible for unpaid
          invoices if your customers fail to pay after our maximum attempts to collect via invoice
          buy-back.
        </PL>
      </div>
      <div className="flex flex-col gap-3 mb-8">
        {priceCardData.map((card) => {
          return (
            <div key={card.key} className="flex gap-4">
              <div>
                <CheckIcon />
              </div>
              <p className="text-black">
                <span className="font-semibold">{card.title}</span> {card.text}
              </p>
            </div>
          );
        })}
      </div>
      <Button className="w-full" buttonSize="large" buttonType="solid" textColor="white">
        Get Started
      </Button>
    </div>
  );
};
