import { Button } from '~/components/ui/button';
import { H2, PL } from '~/components/ui/typography';
import { cn } from '~/lib/utils/style';

export type CallToActionProps = {
  title: string;
  text: string;
  className?: string;
};

export const CallToAction = ({ className, title, text }: CallToActionProps) => {
  return (
    <div className={cn('py-16 bg-gradient-to-b to-gradient2 from-gradient1', className)}>
      <div className="container">
        <div className="flex flex-col items-center gap-6 text-center">
          <H2>{title}</H2>
          <PL dangerouslySetInnerHTML={{ __html: text }} className="font-medium" />
          <div className="flex flex-col sm:flex-row gap-4 m-auto lg:m-0">
            <Button buttonSize="large" buttonType="solid" textColor='white'>
              Join Us
            </Button>
            <Button buttonSize="large" buttonType="invisible" textColor='primary'>
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
