import './ui.scss';

export const ResponsiveGrid = () => {
  return (
    <section className="responsiveGrid">
      {/* First Column */}
      <div className="col-span-1 hidden xl:block">
        <div className="w-full h-[400px] bg-gray-300 rounded-lg mb-4"></div>
      </div>

      {/* Second Column */}
      <div className="col-span-1 hidden sm:block">
        <div className="w-full h-[250px] bg-gray-300 rounded-lg mb-4"></div>
        <div className="w-full h-[250px] bg-gray-300 rounded-lg"></div>
      </div>

      {/* Central Column */}
      <div className="col-span-1">
        <div className="max-w-full sm:max-w-[400px] mx-6 sm:p-0 min-w-80 min-h-[250px] sm:h-[600px] bg-gray-300 rounded-lg sm:mx-auto"></div>
      </div>

      {/* Fourth Column */}
      <div className="col-span-1 hidden sm:block">
        <div className="w-full h-[250px] bg-gray-300 rounded-lg mb-4"></div>
        <div className="w-full h-[250px] bg-gray-300 rounded-lg"></div>
      </div>

      {/* Fifth Column */}
      <div className="col-span-1 hidden xl:block">
        <div className="w-full h-[400px] bg-gray-300 rounded-lg"></div>
      </div>
    </section>
  );
};
