import * as React from "react"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material"
import CardList from "./CardList";
import CardOverview from "./CardOverview";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function CardStackedLayout() {

  const [activeTab, setActiveTab] = React.useState(0)
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
   
      <Box
        sx={{
          display: "flex",
          bgcolor: "background.paper",
          borderBottomWidth: 1,
          borderBottomColor: "gray.200",
          borderBottomStyle: "solid",
        }}
      >
        <Container>
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab label="Overview" />
            <Tab label="Cards" />
          </Tabs>
        </Container>
      </Box>
        
      <CustomTabPanel value={activeTab} index={0}>
        <CardOverview/>
      </CustomTabPanel>
   
      <CustomTabPanel value={activeTab} index={1}>
        
        <CardList/>
      </CustomTabPanel>
        
    </Box>
  )
}
