import { Menu } from '~/components/ui/menu';
import { Button } from '~/components/ui/button';
import { H1, PL } from '~/components/ui/typography';
import EmailInput from '~/components/EmailInput';
import { Stack, Typography } from '@mui/material';
import { FrameworkLink } from '~/framework/FrameworkLink';
import LandingImage from '~/assets/Landing.png';
import EssoLogo from '~/assets/esso.png';
import PipelineLogo from '~/assets/pipeline.png';
import SuperpassLogo from '~/assets/superpass.png';
import { useState } from 'react';

export type HeaderProps = {
  description?: string;
  title?: string;
  careersHeader?: boolean;
};

export const Header = ({
  description = `One platform for fleet fuel cards, invoice factoring, and fleet management so you can grow your fleet without any hassle.`,
  title = 'Fuel Up. Get Paid. Manage Your Fleet. All in One Place.',
  careersHeader = false,
}: HeaderProps) => {

  const [email, setEmail] = useState("");

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };



  return (
    <div className="lg:h-screen flex flex-col">
      <Menu />
      <div className="flex-1 pt-40 md:pt-0">
        <div className="container h-full">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-10 h-full py-12 lg:scale-90">
            <div className="flex flex-col gap-6 justify-center md:w-[616px] text-center lg:text-left">
              <H1>{title}</H1>
              <PL>{description}</PL>
              {careersHeader ? (
                <Button buttonSize="large" buttonType="solid" textColor="white">
                  See open positions
                </Button>
              ) : (
                <Stack direction="row" justifyContent="left" spacing={1} alignItems="center">
                  <EmailInput setEmail={handleEmailChange}/>
                    <FrameworkLink to={`/get-started?email=${email}`}>
                    <Button buttonSize="medium" buttonType="solid" textColor="white">
                      Get Started →
                    </Button>
                    </FrameworkLink>
                </Stack>

              )}
            </div>
            <div className="w-full h-full min-h-[300px] sm:min-h-[350px] md:min-h-[450px] max-h-[700px] max-w-full lg:max-w-[50%] rounded-xl flex flex-col items-center justify-center">
              <img
              src={LandingImage}
              className="w-full h-full object-contain rounded-xl"
              />
              <Typography variant="h3" className="text-center mt-4" paddingBottom={1}>
                Available at these stations:
              </Typography>
                <Stack direction="row" spacing={4} alignItems="center">
                <img src={EssoLogo} alt="Esso Logo" className="w-auto h-12 grayscale rounded-lg" />
                <img src={SuperpassLogo} alt="Petro Logo" className="w-auto h-12 grayscale rounded-lg" />
                <img src={PipelineLogo} alt="Pipeline Logo" className="w-auto h-12 grayscale rounded-lg" />
                </Stack>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
