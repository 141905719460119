import { TbChevronDown } from 'react-icons/tb';

import { DealIcon, GrowthIcon, ProfitIcon, SavingsIcon } from '~/components/icons';

export const mainMenuItems = [
  { key: 'home', label: 'Home', url: '/' },
  { key: 'fuelCard', label: 'Fuel Card', url: '/fuel-card' },
  { key: 'factoring', label: 'Factoring', url: '/factoring' },
  { key: 'fleetManagement', label: 'Fleet Management', url: '/fleet-management' },
  { key: 'company', label: 'Company', url: '', image: TbChevronDown },
];

export const secondaryMenuItems = [
  { key: 'aboutUs', label: 'About Us', url: '/about-us', description: 'Discover our mission and story.' },
  { key: 'blog', label: 'Blog', url: '/blog', description: 'Read our latest insights and news.' },
  { key: 'careers', label: 'Careers', url: '/careers', description: 'Explore opportunities to join us.' },
];

export const advantages = [
  {
    title: 'Maximize Savings with Fleet Fuel Cards',
    label: 'Fuel cards',
    page: '/fuel-card',
    button: 'Learn more',
    videoSrc: '/fuel.mp4',
    description:
      'Our fleet fuel cards  (accepted at Petro-Canada, Esso, and Pipeline Commercial) give you access to competitive fuel pricing across a broad network of stations. You gain full visibility into your fuel usage with detailed fuel tracking and expense management tools. We help you control costs, reduce administrative overhead, and keep your fleet moving efficiently without worrying about rising fuel prices.',
  },
  {
    title: 'Cash flow you need when you need it',
    label: 'Invoice factoring',
    button: 'Take action',
    page: '/factoring',
    videoSrc: '/factoring.mp4',
    description:
      'Submit your invoices easily, get quick approval, and receive fast payouts to steady your cash flow. You can cover expenses like fuel, payroll, and maintenance without disruptions by eliminating payment delays.',
  },
  {
    title: 'Unparalleled visibility into fleet vehicle location, utilization, and health.',
    label: 'Fleet management',
    page: '/fleet-management',
    button: 'Explore more',
    videoSrc: '/fleet.mp4',
    description:
      'Our fleet management solutions offer real-time vehicle tracking, maintenance scheduling, and route optimization—all in one platform. With Motion, you can minimize downtime, improve delivery accuracy, and make sure that your trucks always run at peak performance. We help you streamline your operations, reduce costs, and gain full control over your fleet’s efficiency.',
  },

];

export const accordionData = [
  {
    key: 'item1',
    title: 'What benefits do I get by combining fuel cards, invoice factoring, and fleet management in one platform?',
    text: `Our services help you centralize critical fleet operations and streamline your workflow. You’ll save time and money by managing fuel expenses, payments, and fleet performance in one place. 
We also reduce operational costs with more accurate tracking and reporting. It improves cash flow and empowers you to run your fleet smoothly without unnecessary delays or inefficiencies.`,
  },
  {
    key: 'item2',
    title: 'Can I use Motion’s services if I already have a fuel card provider or fleet management system in place?',
    text: `Yes.
Motion’s platform is flexible and can integrate with your existing fuel card provider or fleet management system. You can keep using what works for you while using our invoice factoring and other services. 
We help you fill in the gaps and make sure that all your operations are efficient and connected.`,
  },
  {
    key: 'item3',
    title: 'Is Motion suitable for trucking companies of all sizes?',
    text: `Yes.
Motion is designed to cater to trucking companies of all sizes, from small fleets to large operations. Our platform scales with your business, providing essential tools like fuel management, invoice factoring, and fleet tracking, regardless of fleet size. 
You’ll get the same level of support and efficiency no matter how many trucks you manage.`,
  },
  {
    key: 'item4',
    title: 'How secure is my company’s data when using Motion’s platform for logistics operations?',
    text: `Your data’s security is our top priority. Motion’s platform uses advanced encryption and industry-standard security protocols to protect your fleet and financial information. 
    We guarantee our customers that sensitive data, such as transaction records, fleet routes, and financials, are kept safe from unauthorized access. .`,
  },
];

export const streamlineData = [
  {
    key: 'savings',
    icon: SavingsIcon,
    title: 'Fuel Cards to Optimize Your Fleet Operations',
    text: `Motion gives you access to three of the most trusted fuel networks: Petro-Canada, Esso, and Pipeline Commercial. 
This flexibility ensures that your drivers can fuel up at 900+ locations in North America, always at competitive rates.`,
  },
  {
    key: 'profit',
    icon: ProfitIcon,
    title: 'Financial Solutions for Your Business Growth',
    text: 'Accelerate your growth with invoice factoring and unlock working capital at competitive rates. Say goodbye to long waiting periods and take control of your cash flow today.',
  },
  {
    key: 'growth',
    icon: GrowthIcon,
    title: 'Efficient Fleet Management for Better Performance',
    text: `Join leading companies in the logistics sector that trust Motion to streamline operations and boost profitability. Motion’s solutions reduce costs, increase efficiency, and enable smarter, data-driven decisions to drive growth and competitiveness.`,
  },
  {
    key: 'deal',
    icon: DealIcon,
    title: 'Trusted by 1000+ Companies in the Logistics Sector',
    text: `Join leading companies in the logistics industry that trust Motion to streamline operations, reduce costs, and increase profitability. Leverage Motion’s innovative solutions to enhance efficiency and stay ahead in a competitive market`,
  },
];

