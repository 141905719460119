export const CheckmarkIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9337 15.5667C12.4003 15.0333 11.6003 15.0333 11.067 15.5667C10.5337 16.1 10.5337 16.9 11.067 17.4333L15.067 21.4333C15.3337 21.7 15.6003 21.8333 16.0003 21.8333C16.4003 21.8333 16.667 21.7 16.9337 21.4333L26.267 10.7667C26.667 10.1 26.667 9.29999 26.0003 8.89999C25.467 8.49999 24.667 8.49999 24.267 9.03333L16.0003 18.5L12.9337 15.5667Z"
        fill="#3AEC55"
      />
      <path
        d="M28.0003 15.1666C27.2003 15.1666 26.667 15.7 26.667 16.5C26.667 22.3666 21.867 27.1666 16.0003 27.1666C10.1337 27.1666 5.33366 22.3666 5.33366 16.5C5.33366 13.7 6.40033 11.0333 8.40033 9.03329C10.4003 6.89996 13.067 5.83329 16.0003 5.83329C16.8003 5.83329 17.7337 5.96663 18.5337 6.09996C19.2003 6.36663 20.0003 5.96663 20.267 5.16663C20.5337 4.36663 20.0003 3.83329 19.3337 3.56663H19.2003C18.1337 3.29996 17.067 3.16663 16.0003 3.16663C8.66699 3.16663 2.66699 9.16663 2.66699 16.6333C2.66699 20.1 4.13366 23.5666 6.53366 25.9666C9.06699 28.5 12.4003 29.8333 15.867 29.8333C23.2003 29.8333 29.2003 23.8333 29.2003 16.5C29.3337 15.7 28.667 15.1666 28.0003 15.1666Z"
        fill="#3AEC55"
      />
    </svg>
  );
};
