import { FaXTwitter } from 'react-icons/fa6';
import { BiLogoFacebookCircle, BiLogoInstagram, BiLogoLinkedinSquare } from 'react-icons/bi';

type ImageProps = {
  url?: string;
  src: string;
  alt?: string;
};

type Links = {
  title: string;
  url: string;
};

type SocialMediaLinks = {
  url: string;
  icon: React.ReactNode;
};

type ColumnLinks = {
  links: Links[];
};

type Contact = {
  label: string;
  phone: string;
  email: string;
};

type Props = {
  logo: ImageProps;
  contact: Contact;
  columnLinks: ColumnLinks[];
  socialMediaLinks: SocialMediaLinks[];
  footerText?: string;
  footerLinks: Links[];
};

export type Footer3Props = React.ComponentPropsWithoutRef<'section'> & Partial<Props>;

export const Footer = (props: Footer3Props) => {
  const { logo, contact, columnLinks, socialMediaLinks, footerText, footerLinks } = {
    ...Footer3Defaults,
    ...props,
  } as Props;
  return (
    <footer className="px-[5%] py-12 md:py-18 lg:py-20 bg-black rounded-t-[50px]">
    <div className="container">
      <div className="grid grid-cols-1 gap-x-[4vw] gap-y-12 pb-12 md:gap-y-16 md:pb-18 lg:grid-cols-[1fr_0.5fr] lg:gap-y-4 lg:pb-20">
        <div>
          <div className="rb-6 mb-6 md:mb-8">
            <a href={logo.url}>
              <img src={logo.src} alt={logo.alt} className="inline-block w-32 h-auto md:w-40" />
            </a>
          </div>

          <div className="rb-6 mb-6 md:mb-8">
            <div>
              <p className="mb-1 text-sm font-semibold text-white">{contact.label}</p>
              <p className="flex flex-col text-sm underline decoration-white underline-offset-1 md:mb-6 text-white">
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </p>
            </div>
          </div>
          <div className="grid grid-flow-col grid-cols-[max-content] items-start justify-start gap-x-3 gap-y-0">
            {socialMediaLinks.map((link, index) => (
              <a key={index} href={link.url} className="text-white">
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 items-start gap-x-6 gap-y-10 md:grid-cols-2 md:gap-x-8 md:gap-y-4">
          {columnLinks.map((column, index) => (
            <ul key={index}>
              {column.links.map((link, linkIndex) => (
                <li key={linkIndex} className="py-2 text-sm font-semibold text-white">
                  <a href={link.url}>{link.title}</a>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
      <div className="h-px w-full bg-white" />
      <div className="flex flex-col-reverse items-start justify-between pb-4 pt-6 text-sm text-white md:flex-row md:items-center md:pb-0 md:pt-8">
        <p className="mt-8 md:mt-0">{footerText}</p>
        <ul className="grid grid-flow-row grid-cols-[max-content] justify-center gap-x-0 gap-y-4 text-sm md:grid-flow-col md:gap-x-6 md:gap-y-0">
          {footerLinks.map((link, index) => (
            <li key={index} className="underline text-white">
              <a href={link.url}>{link.title}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </footer>

  );
};

export const Footer3Defaults: Footer3Props = {
  logo: {
    url: '#',
    src: '/Logo/MotionMain-White.svg',
    alt: 'Logo image',
  },
  contact: {
    label: 'Contact:',
    phone: '416-613-3835',
    email: 'info@withmotion.com',
  },
  columnLinks: [
    {
      links: [
        { title: 'Home', url: '/' },
        { title: 'Fuel Card', url: '/fuel-card' },
        { title: 'Factoring', url: '/factoring' },
        { title: 'Fleet Management', url: '/fleet-management' },
      ],
    },
    {
      links: [
        { title: 'About Us', url: '/about-us' },
        { title: 'Blog', url: '/blog' },
        { title: 'Careers', url: '/careers' },
      ],
    },
  ],
  socialMediaLinks: [
    {  
      url: 'https://instagram.com/WithMotion_Inc',
      icon: <BiLogoInstagram className="size-6" />,
    },
    {
      url: 'https://www.facebook.com/profile.php?id=61565793225567',
      icon: <BiLogoFacebookCircle className="size-6" />,
    },
    { url: 'https://x.com/WithMotion_Inc', icon: <FaXTwitter className="size-6 p-0.5" /> },
    {
      url: 'https://www.linkedin.com/company/withmotion/',
      icon: <BiLogoLinkedinSquare className="size-6" />,
    },
  ],
  footerText: '© 2024 With Motion. All rights reserved.',
  footerLinks: [
    { title: 'Privacy Policy', url: '/terms-and-conditions' },
    { title: 'Terms of Service', url: '/terms-and-conditions' },
  ],
};
