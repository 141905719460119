export const getData = (date: string | null) => {
  const newDate = new Date(date ?? '');

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return newDate.toLocaleDateString('en-US', options);
};
