import { ReactNode } from 'react';
import './button.scss';
import { cn } from '~/lib/utils/style';

export type ButtonProps = {
  children: ReactNode;
  buttonType: 'invisible' | 'solid';
  buttonSize: 'large' | 'medium';
  textColor: 'white' | 'black' | 'primary';
  className?: string;
};

export const Button = ({ children, buttonType, buttonSize, className, textColor}: ButtonProps) => {
  return (
    <button
      className={cn(`button`, className, {
        [`text-${textColor} btn-${buttonType}`]: true,
        [`text-${textColor} btn-${buttonSize}`]: true,
      })}
    >
      {children}
    </button>
  );
};
