import { Box } from '@mui/material';
import * as React from 'react';
import { FiHome } from 'react-icons/fi';
import { TbCalendar, TbCards, TbCode, TbCreditCardFilled, TbCurrencyDollar, TbInvoice, TbList, TbMicrophone2, TbSettings, TbTicket } from 'react-icons/tb';

export function MobileNav() {
  return (
    <Box
      component="nav"
      sx={{
        display: { xs: 'flex', sm: 'none' },
        position: 'fixed',
        width: '100%',
        bottom: 0,
        zIndex: 5,
        bgcolor: 'background.paper',
        borderTopColor: 'gray.300',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        paddingBottom: `env(safe-area-inset-bottom, 0.5rem)`,
        displayPrint: 'none',
      }}
    >
      <Box
        component="ul"
        sx={{
          listStyle: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          marginBlock: 0,
          paddingInline: 0,
          width: '100%',
          '& > li:nth-of-type(n+2)': {
            borderLeftColor: 'gray.300',
            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
          },
        }}
      >
        <MobileNavItem href={'/dashboard/home'} name="home">
          <FiHome size={24} color="currentColor" />
        </MobileNavItem>
        <MobileNavItem href={'/dashboard/cards'} name="orders">
          <TbCreditCardFilled size={24} color="currentColor" />
        </MobileNavItem>
        <MobileNavItem href={'/dashboard/transactions'} name="events">
          <TbCurrencyDollar size={24} color="currentColor" />
        </MobileNavItem>
        <MobileNavItem href={'/dashboard/money-codes'} name="speakers">
          <TbCode size={24} color="currentColor" />
        </MobileNavItem>
        <MobileNavItem href={'/dashboard/invoices'} name="directory">
          <TbInvoice size={24} color="currentColor" />
        </MobileNavItem>
        <MobileNavItem href={'/dashboard/account'} name="directory">
          <TbSettings size={24} color="currentColor" />
        </MobileNavItem>
      </Box>
    </Box>
  );
}

function MobileNavItem({
  active,
  href,
  children,
  name,
}: {
  active?: boolean;
  href?: string;
  children: React.ReactNode;
  name: string;
}) {
  return (
    <Box
      component="li"
      sx={{
        color: 'gray.600',
        width: '100%',
      }}
    >
      <Box
        href={href}
        component="a"
        aria-label={name}
        sx={{
          color: active ? 'primary.600' : 'gray.600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          py: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
