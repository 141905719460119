
import { useEffect, useState } from 'react';
import CardTable from '~/components/CardTable';
import { PageContainer } from '~/components/PageContainer';
import { Card } from '~/data/Card';
import DBManager from '~/manager/DBManager';
import { useUser } from '~/context/UserContext';
import { DashboardHeader } from '~/components/DashboardHeader';
import DrawerSubtle from '~/components/kit/application/overlays/DrawerSubtleActions';
import { Box, Button, Divider, FilledInput, FormControl, FormLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { set } from 'ts-pattern/dist/patterns';
import SimpleNotification from '~/components/kit/application/overlays/SimpleNotification';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import ConfirmDialog from '~/components/ConfirmDialog';
import SimpleStackedLayout from '~/components/kit/application/app-layouts/SimpleStackedLayout';
import CardStackedLayout from '~/components/CardLayout';



export function Cards() {

  return (
    <PageContainer>
    <CardStackedLayout/>


   

    </PageContainer>
  );
}


export default Cards;