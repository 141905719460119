import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoadingScreen } from './components/LoadingScreen';
import { AppProviders } from './context/AppProviders';
import './fonts.css';
import * as Sentry from "@sentry/react";

import '@fontsource/montserrat/300.css'; // Weight 300.
import '@fontsource/montserrat/400.css'; // Weight 400.
import '@fontsource/montserrat/500.css'; // Weight 500.
import '@fontsource/montserrat/600.css'; // Weight 600.
import '@fontsource/montserrat/700.css'; // Weight 700.
import '@fontsource/montserrat/900.css'; // Weight 900.

import {
  AboutUsLanding,
  FactoringLanding,
  FleetManagementLanding,
  FuelCardLanding,
  HomeLanding,
  BlogLanding,
  TermsAndConditionsLanding,
  CareersLanding,
  BlogPageLanding,
  CareersPositionLanding,
} from './pages';

import { Error404 } from './routes/Error404';

import './index.css';
import Transactions from './routes/Transactions';
import Users from './routes/Users';
import { GetStarted } from './routes/GetStarted';
import MoneyCodes from './routes/MoneyCodes';
import Factoring from './routes/Factoring';
import FleetManagement from './routes/FleetManagement';
import { DashboardLayout } from './components/DashboardLayout';
import Cards from './routes/Cards';
import Invoices from './routes/Invoices';
import { Account } from './routes/Account';
import Login from './routes/Login';
import { Home } from './routes/Home';
import Locations from './routes/Locations';
import PaymentSettings from './routes/PaymentSettings';
import Dispatch from './routes/Dispatch';



export const ALL_ROUTES = [
  {
    path: '/',
    element: <HomeLanding />,
  },
  {
    path: '/fuel-card',
    element: <FuelCardLanding />,
  },
  {
    path: '/factoring',
    element: <FactoringLanding />,
  },
  {
    path: '/fleet-management',
    element: <FleetManagementLanding />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/get-started',
    element: <GetStarted />,
  },

  { path: '/about-us', element: <AboutUsLanding /> },
  { path: '/blog', element: <BlogLanding /> },
  {
    path: '/blog/:id',
    element: <BlogPageLanding />,
  },
  { path: '/terms-and-conditions', element: <TermsAndConditionsLanding /> },
  { path: '/careers', element: <CareersLanding /> },
  { path: '/careers/:id', element: <CareersPositionLanding /> },
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'invoices',
        element: <Invoices />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'locations',
        element: <Locations />,
      },
      {
        path: 'money-codes',
        element: <MoneyCodes />,
      },
      {
        path: 'factoring',
        element: <Factoring />,
      },
      {
        path: 'fleet-management',
        element: <FleetManagement />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'dispatch',
        element: <Dispatch />,
      },
      {
        path: 'payment-settings',
        element: <PaymentSettings/>
      }
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
];
const router = createBrowserRouter(ALL_ROUTES);
Sentry.init({
  dsn: "https://e0284e45d22f4fa6c34a44295807fb62@o4508008560197632.ingest.us.sentry.io/4508029030563840",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://withmotion.com', 'https://www.withmotion.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event) {
    // Check if the event is from localhost
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <AppProviders>
        <React.Suspense fallback={<LoadingScreen />}>
          <RouterProvider router={router} />
        </React.Suspense>
      </AppProviders>
  </React.StrictMode>
);
