import { Header, Offer } from '~/components/pages/fleeting-management';
import { CallToAction, Contact, FAQ, Footer } from '~/components/sections';

export const FleetManagementLanding = () => {
  return (
    <div>
      <Header />
      <CallToAction
        title="Welcome to Motion Fleet Management"
        text="Streamline your fleet operations with our innovative <br/> solutions"
        className="bg-gradient2 to-inherit from-inherit relative z-20"
      />
      <Offer />
      <FAQ className="bg-gradient-to-b to-gradient2 from-gradient1 rounded-none" />
      <Contact className="bg-gradient2 to-inherit from-inherit" />
      <Footer />
    </div>
  );
};
