import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import {Invoice} from '~/data/Invoice';

type UserType = {
    userID?: string;
    username?: string;
    email?: string;
    companyID?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    billingID?: string;
    transactionNotification?: boolean;
    transactionLimit?: number;
    marketingNotification?: boolean;
    moneyCodePermission?: boolean;
    accessToken?: string;
    refreshToken?: string;
    invoices?: Invoice[]; // Make invoices an array
    companyStatus?: string;
    stripeID?: string;
    cadPaymentMethod?: string;
    usdPaymentMethod?: string;
};

type UserContextType = {
    user: UserType | null;
    setUser: (user: UserType | null) => void;
    setTokens: (accessToken: string, refreshToken: string) => void;
    setUserInfo: (userID: string, username: string, email: string, companyID: string, firstName: string, lastName: string, companyName: string, billingID: string, 
        transactionNotification: boolean, marketingNotification: boolean, moneyCodePermission: boolean, companyStatus: string, transactionLimit: number,
         stripeID: string, cadPaymentMethod: string, usdPaymentMethod: string) => void;
    setUserInvoice: (invoices: Invoice[]) => void;
    setMarketingNotification: (marketingNotification: boolean) => void;
    setTransactionNotification: (transactionNotification: boolean, transactionLimit: number) => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{children: ReactNode}> = ({ children }) => {
    const [user, setUser] = useState<UserType | null>(() => {
        // Attempt to load the user from local storage when the component mounts
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        // Storing the user in local storage whenever the user state changes
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]); // useEffect will re-run when `user` changes

    const setTokens = (accessToken: string, refreshToken: string) => {
        setUser((currentUser) => ({
            ...currentUser,
            accessToken,
            refreshToken
        }));
    };

    const setUserInvoice = (invoices: Invoice[]) => {
        setUser((currentUser) => {
            return {
                ...currentUser,
                invoices: invoices  // Concatenate to add the new invoice
            };
        });
    };

    const setMarketingNotification = (marketingNotification: boolean) => {
        setUser((currentUser) => {
            return {
                ...currentUser,
                marketingNotification
            };
        });
    };

    const setTransactionNotification = (transactionNotification: boolean, transactionLimit: number) => {
        setUser((currentUser) => {
            return {
                ...currentUser,
                transactionNotification,
                transactionLimit
            };
        });

    };


    

    const setUserInfo = (userID: string, username: string, email: string, companyID: string, firstName: string, lastName: string, companyName: string, billingID: string, transactionNotification: boolean, marketingNotification: boolean,
         moneyCodePermission: boolean, companyStatus: string, transactionLimit: number, stripeID: string, cadPaymentMethod: string, usdPaymentMethod: string) => {
        setUser((currentUser) => ({
            ...currentUser,
            userID,
            username,
            email,
            companyID,
            firstName,
            lastName,
            companyName,
            billingID,
            transactionNotification,
            marketingNotification,
            moneyCodePermission,
            companyStatus,
            transactionLimit,
            stripeID,
            cadPaymentMethod,
            usdPaymentMethod
        }));
    };


    return (
        <UserContext.Provider value={{user, setUser, setTokens, setUserInfo, setUserInvoice, setMarketingNotification, setTransactionNotification}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) throw new Error("useUser must be used within a UserProvider");
    return context;
};
