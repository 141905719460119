import { Feature } from '~/components/ui/feature';
import { H2, PL, Tag } from '~/components/ui/typography';

export const Advantages = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient2 from-gradient1">
      <div className="container flex flex-col gap-14">
        <Feature>
          <>
            <div className="flex flex-col items-center lg:items-start gap-6">
              <Tag>future</Tag>
              <H2>Build the future</H2>
              <PL>
                Finance software hasn’t evolved in decades, making it hard for companies of all
                sizes to manage and deploy their resources effectively without waste.
                <br /> <br />
                We’re building finance automation that builds trust, helps companies grow, and frees
                people from busy work.
              </PL>
            </div>
          </>
        </Feature>
        <Feature reversed>
          <>
            <div className="flex flex-col items-center lg:items-start gap-6">
              <Tag>quality</Tag>
              <H2>Do your best work</H2>
              <PL>
                Launch your career at Ramp. Join a stellar team built around mentorship,
                empowerment, and ownership.
                <br /> <br />
                Growing without fear, winning the marathon sprint by sprint—these are just a few of
                the core values that shape us.
              </PL>
            </div>
          </>
        </Feature>
        <Feature>
          <>
            <div className="flex flex-col items-center lg:items-start gap-6">
              <Tag>benefits</Tag>
              <H2>Invest in people</H2>
              <PL>
                With team members across the globe, we regularly gather to connect in person, solve
                problems and learn from each other.
                <br /> <br />
                We offer wellness stipends, education support, flexible PTO, and much more to help
                you invest in personal growth and stay on top of your game.
              </PL>
            </div>
          </>
        </Feature>
        <figure className="block bg-gray-500 h-[350px] lg:h-[600px] w-full rounded-3xl overflow-hidden">
          {/* <img src={imageSrc} className='object-cover' alt='Motion picture' /> */}
        </figure>
      </div>
    </div>
  );
};
