import { TruckIcon } from '~/components/icons/truck-icon';
import { H2, PL } from '~/components/ui/typography';

export const Feature = () => {
  return (
    <div className="bg-gradient-to-b to-gradient1 from-gradient2 py-16">
      <div className="container">
        <div className="flex flex-col items-center lg:flex-row gap-12 justify-between 2xl:justify-center">
          <div className="flex flex-col text-center lg:text-left gap-6">
            <figure className="mx-auto lg:m-0">
              <TruckIcon />
            </figure>
            <H2>Discover Our Comprehensive Fuel Solutions</H2>
            <PL>
              We offer a robust network of fuel services designed to meet your needs. Experience
              seamless transactions and unparalleled support with our innovative solutions.
            </PL>
          </div>
          <figure className="block mx-auto min-w-full sm:min-w-[400px] lg:min-w-[500px] h-full lg:h-auto min-h-[250px] sm:min-h-[300px] xl:min-w-[616px] bg-gray-500 xl:h-[600px] rounded-lg overflow-hidden">
            {/* <img src={imageSrc} className='object-cover' alt='Motion picture' /> */}
          </figure>
        </div>
      </div>
    </div>
  );
};
