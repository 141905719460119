
export interface UserData {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    active: boolean; // Assuming the active property is a boolean as per your sample data
    phonenumber: string;
    username: string;
}


export class User {
    private id: string;
    private email: string;
    private firstName: string;
    private lastName: string;
    private active: boolean;
    private phoneNumber: string;
    private username: string;


    constructor(id: string, email: string, firstName: string, lastName: string, active: boolean, phoneNumber: string, username: string) {
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.active = active;
        this.phoneNumber = phoneNumber;
        this.username = username;
    }

    public getId(): string {
        return this.id;
    }

    public getEmail(): string {
        return this.email;
    }

    public getFirstName(): string {
        return this.firstName;
    }

    public getLastName(): string {
        return this.lastName;
    }

    public isActive(): boolean {
        return this.active;
    }

    public getPhoneNumber(): string {
        return this.phoneNumber;
    }

    public getUsername(): string {
        return this.username;
    }

    public setUsername(username: string): void {
        this.username = username;
    }

    public setEmail(email: string): void {
        this.email = email;
    }

    public setFirstName(firstName: string): void {
        this.firstName = firstName;
    }

    public setLastName(lastName: string): void {
        this.lastName = lastName;
    }

    public setActive(active: boolean): void {
        this.active = active;
    }

    public setPhoneNumber(phoneNumber: string): void {
        this.phoneNumber = phoneNumber;
    }


    public toJSON(): {
        id: string;
        email: string;
        name: string;
        active: string;
        phoneNumber: string;
        username: string;
    } {
        return {
            id: this.id,
            email: this.email,
            name: this.firstName + ' ' + this.lastName,
            active: this.active ? 'Active' : 'Inactive',
            phoneNumber: this.phoneNumber,
            username: this.username,
        };
    }



}