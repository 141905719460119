
export interface TransactionData {
  id: string;
  transactionDate: string;
  transactionTime: string;
  product: string;
  price: number;
  amount: number;
  quantity: number;
  cardNumber: string;
  location: string;
  country: string;
}



export class Transaction {
    private id: string;
    private transactionDate: string;
    private transactionTime: string;
    private cardNumber: string;
    private product: string;
    private quantity: number;
    private price: number;
    private amount: number;
    private location: string;
    private country: string = '';


    constructor(id: string, transactionDate: string, transactionTime: string, cardNumber: string, product: string, quantity: number, price: number, amount: number, location: string, country: string) {
        this.id = id;
        this.transactionDate = transactionDate;
        this.transactionTime = transactionTime;
        this.cardNumber = cardNumber;
        this.product = product;
        this.quantity = quantity;
        this.price = price;
        this.amount = amount;
        this.location = location;
        this.country = country;
    }

      // Method to generate the JSON-like object
  public toJSON(): {
    id: string;
    transactionDate: string;
    transactionTime: string;
    cardNumber: string;
    product: string;
    quantity: string;
    price: string;
    amount: string;
    location: string;
  } {
    return {
      id: this.id,
      transactionDate: this.transactionDate,
      transactionTime: this.transactionTime,
      cardNumber: this.cardNumber, // Replace with actual customer name logic
      quantity: this.quantity.toFixed(2) + (this.country === 'CA' ? ' L' : ' gal'),
      price: this.price.toFixed(4),
      amount: '$' + this.amount.toFixed(2),
      product: this.product, // Replace with actual status logic
      location: this.location,
    };
  }



}