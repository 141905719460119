import { H1, PL } from '~/components/ui/typography';
import { Button } from '~/components/ui/button';

export type CTAProps = {
  text: string;
  title: string;
};

export const CTA = ({ text, title }: CTAProps) => {
  return (
    <div className="container">
      <div className="flex flex-col gap-6 text-center pb-20 max-w-[775px] mx-auto">
        <H1>{title}</H1>
        <PL>{text}</PL>
        <div className="flex flex-col justify-center sm:flex-row gap-4 m-auto lg:m-0">
          <Button buttonSize="large" buttonType="solid" textColor='white'>
            Get Started
          </Button>
          <Button buttonSize="large" buttonType="invisible" textColor='primary'>
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};
