import { H2, PL } from '~/components/ui/typography';
import {
  FaMoneyBillWave,
  FaHeartbeat,
  FaPiggyBank,
  FaUmbrellaBeach,
  FaBaby,
  FaSpa,
  FaLaptopHouse,
  FaPaw,
} from 'react-icons/fa';
import { MdLocalHospital } from 'react-icons/md';

const benefitsData = [
  {
    id: 'salary',
    text: 'Competitive salary and equity',
    icon: <FaMoneyBillWave className="size-14 text-black" />,
  },
  {
    id: 'insurance',
    text: 'Comprehensive medical, dental, and vision insurance',
    icon: <FaHeartbeat className="size-14 text-black" />,
  },
  {
    id: '401k',
    text: '401(k) including an employer match',
    icon: <FaPiggyBank className="size-14 text-black" />,
  },
  {
    id: 'oneMedical',
    text: 'One Medical Membership',
    icon: <MdLocalHospital className="size-14 text-black" />,
  },
  {
    id: 'pto',
    text: 'Generous and flexible paid time off',
    icon: <FaUmbrellaBeach className="size-14 text-black" />,
  },
  {
    id: 'parentalLeave',
    text: 'Generous parental leave',
    icon: <FaBaby className="size-14 text-black" />,
  },
  {
    id: 'wellness',
    text: 'Monthly wellness stipend',
    icon: <FaSpa className="size-14 text-black" />,
  },
  {
    id: 'wfhStipend',
    text: 'Work from home stipend',
    icon: <FaLaptopHouse className="size-14 text-black" />,
  },
  {
    id: 'petInsurance',
    text: 'Insurance coverage for pets',
    icon: <FaPaw className="size-14 text-black" />,
  },
];

export const Benefits = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient1 from-gradient2">
      <div className="container">
        <H2 className="text-center mb-10">Benefits</H2>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-y-6">
          {benefitsData.map((benefit) => {
            return (
              <div
                className="flex flex-col gap-4 w-52 mx-auto text-center justify-center items-center"
                key={benefit.id}
              >
                {benefit.icon} <PL>{benefit.text}</PL>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
