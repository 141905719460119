export interface MoneyCodeData {
     id: string;
     code: string;
     amountUsed: number;
     amountCreated: number;
     created: string;
     notes: string;
}
export class MoneyCode {
    private id: string;
    private code: string;
    private amountUsed: number;
    private amountTotal: number;
    private creationDate: string;
    private notes: string;

    constructor(id: string, code: string, amountUsed: number, amountTotal: number, creationDate: string, notes: string) {
        this.id = id;
        this.code = code;
        this.amountUsed = amountUsed;
        this.amountTotal = amountTotal;
        this.creationDate = creationDate;
        this.notes = notes;
    }

 

    public toJSON(): {
        id: string;
        code: string;
        amountUsed: number;
        amountTotal: number;
        creationDate: string;
        notes: string;
        } {
        return {
            id: this.id,
            code: this.code,
            amountUsed: this.amountUsed,
            amountTotal: this.amountTotal,
            creationDate: this.creationDate,
            notes: this.notes
        };
      }



}
