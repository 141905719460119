import { Menu } from '~/components/ui/menu';
import { ResponsiveGrid } from '~/components/ui/responsive-grid';
import { CTA } from '~/components/ui/cta';

export const Header = () => {
  return (
    <div>
      <Menu />
      <div className="pt-32 md:pt-52 pb-20">
        <CTA
          title="Unlock Your Business Potential with REV Capital"
          text="Transportation factoring is the key to driving straight through financial roadblocks.
          Discover how REV Capital can help you streamline your cash flow and grow your business."
        />
        <ResponsiveGrid />
      </div>
    </div>
  );
};
