import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import { accordionData } from '~/lib/constants';

export const Accordion = () => {
  const [expanded, setExpanded] = useState<false | number>(0);

  return (
    <div className="md:max-w-[80%] mx-auto flex flex-col">
      {accordionData.map((item, index) => {
        const isOpen = index === expanded;
        return (
          <Fragment key={index}>
            <AnimatePresence initial={false}>
              <motion.header
                className="pb-4"
                initial={false}
                onClick={() => setExpanded(isOpen ? false : index)}
              >
                <div className="flex gap-2 justify-between items-center p-6 border border-primary rounded-lg border-dashed  font-medium">
                  <p className="text-black font-medium">{item.title}</p>
                  <div>
                    <motion.div
                      className="w-5 h-5 flex items-center justify-center cursor-pointer"
                      animate={{ rotate: isOpen ? 135 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <motion.div className="relative w-full">
                        <motion.span className="absolute top-0 left-0 w-full h-[2px] bg-black rotate-90" />
                        <motion.span className="absolute top-0 left-0 w-full h-[2px] bg-black" />
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
              </motion.header>
              {isOpen && (
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <motion.div
                    variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
                    transition={{ duration: 0.8 }}
                  >
                    <div className="pb-8 text-black">{item.text}</div>
                  </motion.div>
                </motion.section>
              )}
            </AnimatePresence>
          </Fragment>
        );
      })}
    </div>
  );
};
