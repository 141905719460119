import { Careers, Header } from '~/components/pages/about-us';
import { Footer } from '~/components/sections';

export const AboutUsLanding = () => {
  return (
    <div>
      <Header />
      <Careers />
      <Footer />
    </div>
  );
};
