import { Menu } from '~/components/ui/menu';

export const Header: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col">
      <Menu />
      <div className="pt-40 flex-1">
        <div className="container h-full">
          <div className="flex flex-col lg:flex-row items-center gap-10 h-full py-12 justify-center ">
            <div className="flex flex-col gap-6 justify-center md:w-[616px] text-center lg:text-left">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
