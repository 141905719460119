import { H2, PL, PM } from '~/components/ui/typography';
import avatar from '~/assets/contact-avatar.png';
import { StarIcon } from '~/components/icons';
import { Form } from '~/components/ui/form';
import { cn } from '~/lib/utils/style';

const STAR_COUNT = 5.0;

export type ContactProps = {
  className?: string;
};

export const Contact = ({ className }: ContactProps) => {
  return (
    <div
      className={cn(
        'bg-gradient-to-b to-gradient1 from-gradient2 rounded-b-[150px] py-16',
        className,
      )}
    >
      <div className="container">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-4">
          <div className="flex flex-col gap-6 text-center lg:text-left max-w-[430px] xl:max-w-[565px]">
            <H2>Join Our Fuel Network Today</H2>
            <PL className="font-normal">
              Unlock savings and support with our trusted fuel card services. <br /> Contact us to
              get started!
            </PL>
            <div className="flex gap-4 items-center justify-center sm:justify-start">
              <figure className="w-10 h-10">
                <img src={avatar} alt="Avatar" />
              </figure>
              <div className="flex flex-col gap-1 text-left">
                <PM className="font-medium">Alex Tremblot</PM>
                <PM>CFO at Tremblot Services</PM>
                <div className="flex gap-1">
                  {Array.from({ length: STAR_COUNT }, (_, index) => {
                    return <StarIcon key={index} />;
                  })}
                  <PM>{STAR_COUNT.toFixed(1)}</PM>
                </div>
              </div>
            </div>
            <p className="text-sm text-black font-medium max-w-[350px] m-auto lg:m-0">
              Excellent service. Lorem ipsum dolor vit vet m ipsum dolor vit vetm ipsum dolor vit
              vetm ipsum dolor vit vetm ipsum dolor vit vetm ipsum dolor vit vetm ipsum dolor vit
              vet
            </p>
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
};
