import { Box } from '@mui/material';
import { createElement, useCallback, useEffect, useState } from 'react';
import MotionWhite from '~/assets/Logo/MotionMainLG-White.svg';
import MotionBlack from '~/assets/Logo/MotionMainLG.svg';
import { Button } from '~/components/ui/button';
import { Divide as Hamburger } from 'hamburger-react';
import { FrameworkLink } from '~/framework/FrameworkLink';
import { mainMenuItems, secondaryMenuItems } from '~/lib/constants';
import { cn } from '~/lib/utils/style';
import css from './menu.module.scss';
import AnimateHeight, { Height } from 'react-animate-height';

export const Menu = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [height, setHeight] = useState<Height>(0);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrolling(true);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 10 || window.innerWidth < 768) {
      setOpen(false);
      setHeight(0);
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleHamburgerClick = useCallback(
    (e: boolean | ((prevState: boolean) => boolean)) => {
      setOpen(e);
      setHeight(height === 0 ? 'auto' : 0);
    },
    [height],
  );

  return (
    <div
      className={cn('z-30 fixed right-0 left-0', {
        'bg-black': scrolling, // Change background color with scrolling
      })}
      style={{
        transition: 'background-color 0.5s ease, border-radius 0.5s ease', // Transition for both background color and border-radius
        borderBottomLeftRadius: scrolling ? '50px' : '0px', // Rounded bottom left corner
        borderBottomRightRadius: scrolling ? '50px' : '0px', // Rounded bottom right corner
      }}
    >
      <div className="container">
        <div className="flex items-center justify-between md:justify-start gap-4 py-5 px-3 md:px-0 relative">
          <Box to="/" component={FrameworkLink} className="h-[40px] w-[100px]">
            <img src={scrolling ? MotionWhite : MotionBlack} alt="Logo" />
          </Box>
          <div className="block md:hidden">
            <Hamburger
              color={scrolling ? '#fff' : '#000'}
              toggled={isOpen}
              size={20}
              toggle={handleHamburgerClick}
            />
          </div>
          <ul
            className={cn('md:flex gap-2 hidden top-0 z-50 h-[44px] items-center', {
              'position-fixed': true, // Apply fixed positioning for scroll
            })}
          >
            {mainMenuItems.map((link) => (
              <li
                className={cn(scrolling ? css.whiteLink : css.link, {
                  [`${css.popup}`]: link.image,
                })}
                key={link.key}
              >
                <a href={link.url} className={cn(css.linkContent, { '!text-white': scrolling })}>
                  {link.label}
                  {link.image && createElement(link.image, { className: css.icon })}
                </a>
                {link.image && (
                  <div className={css.popupWrapper}>
                    <div className="grid gap-2">
                      {secondaryMenuItems.map((link) => {
                        return (
                          <div key={link.url} className="grid">
                            <a href={link.url}>
                              {link.label}
                              <p className={css.desc}>{link.description}</p>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="gap-4 hidden ml-auto lg:flex">
            <FrameworkLink to="/get-started">
              <Button
                className="text-primary"
                buttonSize="medium"
                buttonType="invisible"
                textColor="primary"
              >
                Get started
              </Button>
            </FrameworkLink>
            <FrameworkLink to="/login">
              <Button buttonSize="medium" buttonType="solid" textColor="white">
                Login
              </Button>
            </FrameworkLink>
          </div>
        </div>
        <AnimateHeight
          duration={500}
          height={height} // see props documentation below
        >
          <div className="flex flex-col gap-4 justify-between pb-6">
            <ul
              className={cn('flex flex-col gap-2', {
                'position-fixed': true, // Apply fixed positioning for scroll
              })}
            >
              {mainMenuItems.map((link) =>
                link.url.length > 0 ? (
                  <li className={cn(css.link)} key={link.key}>
                    <a
                      href={link.url}
                      className={cn(css.linkContent, { '!text-white': scrolling })}
                    >
                      {link.label}
                    </a>
                    {link.image && (
                      <div className={css.popupWrapper}>
                        <div className="grid gap-2">
                          {secondaryMenuItems.map((link) => {
                            return (
                              <div key={link.url} className="grid">
                                <a href={link.url}>
                                  {link.label}
                                  <p className={css.desc}>{link.description}</p>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </li>
                ) : (
                  <li key={link.key}>
                    <div
                      className={cn(
                        css.popup,
                        'cursor-pointer flex items-center p-2 rounded-lg font-semibold transition-all duration-300 ease-in-out text-black',
                        { '!text-white': scrolling },
                      )}
                    >
                      {link.label}
                      {link.image && createElement(link.image, { className: css.icon })}
                      {link.image && (
                        <div className={cn(css.popupWrapper, '!left-0 !ml-0')}>
                          <div className="grid gap-2">
                            {secondaryMenuItems.map((link) => {
                              return (
                                <div key={link.url} className="grid">
                                  <a href={link.url}>
                                    {link.label}
                                    <p className={css.desc}>{link.description}</p>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ),
              )}
            </ul>
            <div className="gap-4 flex flex-col sm:flex-row">
              <FrameworkLink to="/get-started">
                <Button
                  className="text-primary"
                  buttonSize="medium"
                  buttonType="invisible"
                  textColor="primary"
                >
                  Get started
                </Button>
              </FrameworkLink>
              <FrameworkLink to="/login">
                <Button buttonSize="medium" buttonType="solid" textColor="white">
                  Login
                </Button>
              </FrameworkLink>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};
