import { H2, H3, PL } from '~/components/ui/typography';
import { Accordion } from '~/components/ui/accordion';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils/style';

export type FAQProps = {
  className?: string;
};

export const FAQ = ({ className }: FAQProps) => {
  return (
    <div
      className={cn(
        'py-14 rounded-b-[150px]',
        className,
      )}
    >
      <div className="container">
        <div className="text-center mb-20">
          <H2>Frequently Asked Questions</H2>
        </div>
        <Accordion />
        <div className="flex flex-col gap-4 pt-20 text-center">
          <H3>Let Us Streamline Your Fleet Business</H3>
          <PL>
            Please send us a message and our team will get to you as soon as <br /> possible
          </PL>
          <div>
            <Button buttonType="solid" buttonSize="medium" textColor='white'>
              Connect with us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
