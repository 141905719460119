import { TbClock24, TbCurrencyDollar, TbShield } from 'react-icons/tb';
import { Feature } from '~/components/ui/feature';
import { H2, Label, PL, PM } from '~/components/ui/typography';

const benefitsData = [
  {
    key: 'insurance',
    icon: <TbCurrencyDollar className="text-black" />,
    title: 'Maximize your savings with no hidden fees.',
  },
  {
    key: 'support',
    icon: <TbClock24 className="text-black" />,
    title: '24/7 support ensures you’re never alone.',
  },
  {
    key: 'protection',
    icon: <TbShield className="text-black" />,
    title: 'Advanced fraud detection protects your business.',
  },
];

export const ReportingFeature = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient1 from-gradient2">
      <div className="container">
        <Feature>
          <>
            <div className="flex flex-col gap-6">
              <Label>Savings</Label>
              <H2>Unlock Unmatched Benefits with Our Fuel Services</H2>
              <PL>
                Experience seamless fuel management with our extensive network. Enjoy peace of mind
                knowing you're backed by robust support and security.
              </PL>
            </div>
            <div className="flex flex-col mx-auto lg:m-0 gap-4">
              {benefitsData.map((benefit) => {
                return (
                  <div className="flex items-center gap-4" key={benefit.key}>
                    {benefit.icon}
                    <PM>{benefit.title}</PM>
                  </div>
                );
              })}
            </div>
          </>
        </Feature>
      </div>
    </div>
  );
};
