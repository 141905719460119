import { H4, PM } from '~/components/ui/typography';
import { Button } from './button';

const formData = [
  {
    key: 'name',
    label: 'Name*',
    placeholder: 'Enter your name',
    type: 'text',
  },
  {
    key: 'email',
    label: 'Email*',
    placeholder: 'Enter your email',
    type: 'email',
  },
  {
    key: 'phone',
    label: 'Phone number',
    placeholder: '+1 (555) 000-0000',
    type: 'tel',
  },
  {
    key: 'city',
    label: 'City',
    placeholder: 'Enter your city',
    type: 'text',
  },
];

export const Form = () => {
  return (
    <div className="bg-[#F9FAFB] sm:max-w-[510px] sm:w-full rounded-3xl px-10 py-8 text-center">
      <H4 className="text-primary mb-6">Contact Us</H4>
      <div className="flex flex-wrap flex-col sm:flex-row justify-between gap-y-5">
        {formData.map((input) => {
          return (
            <div key={input.key} className="text-left">
              <p className="font-semibold text-sm text-darkGrey mb-1">{input.label}</p>
              <input
                className="border w-full border-[#D0D5DD] rounded-md py-2 px-3 placeholder-[#667085]"
                type={input.type}
                placeholder={input.placeholder}
              />
            </div>
          );
        })}
      </div>
      <div className="text-left mt-5">
        <p className="font-semibold text-sm text-darkGrey mb-1">Message</p>
        <textarea
          className="w-full h-24 border border-[#D0D5DD] rounded-md py-2 px-3 placeholder-[#667085] resize-none"
          placeholder="Message"
        />
      </div>
      <Button buttonSize="large" buttonType="solid" className="mt-5 w-full rounded-3xl" textColor='white'>
        Send us a message
      </Button>
      <PM className="text-[#232121] font-normal pt-4">
        Or,{' '}
        <a href="tel:+8888888888" className="underline font-medium">
          Call Us 888-888-8888
        </a>
      </PM>
    </div>
  );
};
