import { PriceCard } from '~/components/ui/price-card';
import { H2, PL, Tag } from '~/components/ui/typography';

export const Pricing = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient2 from-gradient1">
      <div className="container">
        <div className="flex flex-col items-center gap-4 mb-14">
          <Tag>Simple</Tag>
          <H2>Pricing Options</H2>
          <PL className="pt-1">Choose the plan that fits your needs and budget.</PL>
        </div>
        <div className="flex gap-6 justify-center flex-col items-center md:flex-row">
          <PriceCard />
          <PriceCard />
        </div>
      </div>
    </div>
  );
};
