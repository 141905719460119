import {
  Box,
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useState, useEffect, SyntheticEvent } from 'react';
import { TbCheck, TbEye, TbEyeOff, TbLock, TbRecycle, TbSend, TbSpeakerphone, TbSwitch } from 'react-icons/tb';
import { Logo } from '~/components/Logo';
import { FrameworkLink } from '~/framework/FrameworkLink';
import AuthManager from '~/manager/AuthManager';
import { useUser } from '~/context/UserContext'; // Import the user context hook
import { useFrameworkNavigate } from '~/framework/useFrameworkNavigate';
import { PageContainer } from '~/components/PageContainer';
import DBManager from '~/manager/DBManager';
import SimpleNotification from '~/components/kit/application/overlays/SimpleNotification';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import { toast } from 'react-toastify';

// auth form with 3rd party integrations included
export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { setUserInfo } = useUser();
  const navigate = useFrameworkNavigate();
  


  // Forgot Password Variables
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [sendCodeOpen, setSendCodeOpen] = useState(false);
  const [confirmCodeOpen, setConfirmCodeOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [changePassword, setChangePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [eightLong, setEightLong] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneCapital, setOneCapital] = useState(false);


  const handleLogin = async (email: string, password: string) => {
    // Handle login on button click
    const authManager = new AuthManager();
    console.log('Logging in with email:', email);
    try {
      await authManager.getTokens(email, password, rememberMe);

      const userBody = await authManager.login();
      console.log('User:', userBody);
      setUserInfo(userBody.userID, userBody.username, userBody.email, userBody.companyID, userBody.firstName, userBody.lastName, userBody.companyName, userBody.billingID, 
        userBody.transactionNotification, userBody.marketingNotification, userBody.moneyCodePermission,
         userBody.status, userBody.transactionLimit, userBody.stripeID, userBody.cadPaymentMethod, userBody.usdPaymentMethod);

      // Redirect to dashboard
      navigate('/dashboard/home');

    } catch (error) {

      toast.error('Login failed. Please check your email and password.' , {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });
      

      console.error("Login error:", error);
    }

  };

  const sendResetCode = async (email: string) => {
    const dbManager = new DBManager();
    const response = await dbManager.sendResetCode(email);

    if (response.code == 200) {
      setConfirmCodeOpen(true);
      setForgotPasswordOpen(false);
      setSendCodeOpen(false);

      toast.success('Reset code sent to email.' , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });

    }
    else {


      toast.error(response.message , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });

    }

  }

  const checkResetCode = async (email: string, code: string) => {

    const dbManager = new DBManager();
    const response = await dbManager.checkResetCode(email, code);

    if (response.code == 200) {
      setChangePasswordOpen(true);
      setConfirmCodeOpen(false);
      setSendCodeOpen(false);

      toast.success('Code confirmed.' , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });
    }
    else {

      toast.error(response.message , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });
    }

  }


  const passwordChanged = (password: string, confirmPassword: string) => {
    // Add close drawer logic here
    console.log('Password Values Changed');
    console.log('Password', password);
    setPassword(password);
    console.log('Confirm Password', confirmPassword);
    setConfirmPassword(confirmPassword);
    // Check for password match
    if (password == confirmPassword && password != '' && confirmPassword != '') {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

    // Check for 8 characters

    if (password.length >= 8) {
      setEightLong(true);
    } else {
      setEightLong(false);
    }

    // Check for one number

    if (/\d/.test(password)) {
      setOneNumber(true);
    } else {
      setOneNumber(false);
    }

    // Check for one capital

    if (/[A-Z]/.test(password)) {
      setOneCapital(true);
    } else {
      setOneCapital(false);
    }
  };

  const updatePassword = async (password: string, confirmPassword: string) => {
    const dbManager = new DBManager();
    const response = await dbManager.changePassword(forgotPasswordEmail, password);

    if (response.code == 200) {
      setChangePasswordOpen(false);
      setForgotPasswordEmail('');
      setResetCode('');
      setChangePassword('');
      setConfirmPassword('');

    toast.success('Password changed.' , {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      });
    }
    else {

      toast.error(response.message , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });

  }
}



  return (
    <PageContainer
      customSx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: 1,
          }}
        >
          <Logo height={20} />
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2xl', sm: '3xl' },
              lineHeight: 1.25,
            }}
          >
            Welcome back
          </Typography>

        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'max-content',
            borderRadius: 2,
            borderStyle: 'solid',
            borderWidth: 1,
            maxWidth: '420px',
            width: '100%',
            gap: 3,
            p: { xs: 0, sm: 4 },
            borderColor: { xs: 'transparent', sm: 'gray.200' },
            boxShadow: { xs: 0, sm: 1 },
            bgcolor: { xs: 'none', sm: 'background.paper' },
          }}
        >
          
          {!forgotPasswordOpen && !sendCodeOpen && !confirmCodeOpen && !changePasswordOpen  && (

            <Stack
              spacing={2}
              component="form"
              onSubmit={(event: SyntheticEvent) => {
                event.preventDefault();
                const formData = new FormData(event.target as HTMLFormElement);
                const email = formData.get('email') as string;
                const password = formData.get('password') as string;
                handleLogin(email, password);
              }}
              sx={{
                maxWidth: '24rem',
                width: '100%',
                mb: 0,
              }}
            >
              <FrameworkLink
                to="/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  color="secondary"
                  sx={{
                    fontWeight: 600,
                    padding: 0,
                    margin: 0,
                    justifyContent: 'left',
                  }}

                >
                  {"←"} Back
                </Button>


              </FrameworkLink>
              <FormControl>

                <FormLabel>Email</FormLabel>
                <FilledInput
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <FilledInput
                  type={passwordVisibility ? 'text' : 'password'} // Toggle between 'text' and 'password'
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="password"
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                      edge="end"
                    >
                      {passwordVisibility ? <TbEye /> : <TbEyeOff />}
                    </IconButton>
                  }
                />
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: 'sm',
                  flexWrap: 'wrap',
                }}
              >
                <FormControlLabel
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: 'sm',
                    },
                  }}
                  control={<Checkbox />}
                  label="Remember me?"
                  value={rememberMe}
                  checked={rememberMe}
                  onChange={(e, checked) => setRememberMe(checked)}
                />
                <Box
                  component="a"
                  href={undefined}
                  sx={{
                    color: 'primary.600',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    console.log("Forgot password link clicked");  // Example: Logging to the console
                    setForgotPasswordOpen(true);  // Existing action
                  }}                >
                  Forgot password?
                </Box>
              </Box>
              <Button disabled={(!validateEmail(email) || password.length == 0)} variant="contained" size="large" type="submit" startIcon={<TbLock />}>
                Login
              </Button>

            </Stack>
          )}

          {forgotPasswordOpen && !sendCodeOpen && (

            <Stack
              spacing={2}
              component="form"
              onSubmit={(event: SyntheticEvent) => {
                event.preventDefault();
                const formData = new FormData(event.target as HTMLFormElement);
                const email = formData.get('email') as string;
                sendResetCode(email);
              }}
              sx={{
                maxWidth: '24rem',
                width: '100%',
                mb: 0,
              }}
            >
              <FrameworkLink
                to="/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  color="secondary"
                  sx={{
                    fontWeight: 600,
                    padding: 0,
                    margin: 0,
                    justifyContent: 'left',
                  }}

                >
                  {"←"} Back
                </Button>


              </FrameworkLink>
              <FormControl>

                <FormLabel>Email</FormLabel>
                <FilledInput
                  type="email"
                  name="email"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                />
              </FormControl>


              <Button disabled={(!validateEmail(forgotPasswordEmail))} variant="contained" size="large" type="submit" startIcon={<TbSend />}>
                Send Reset Code
              </Button>

            </Stack>
          )}

          {confirmCodeOpen && (
            <Stack
            spacing={2}
            component="form"
            onSubmit={(event: SyntheticEvent) => {
              event.preventDefault();
              const formData = new FormData(event.target as HTMLFormElement);
              const code = formData.get('code') as string;
              checkResetCode(forgotPasswordEmail, code);
            }}
            sx={{
              maxWidth: '24rem',
              width: '100%',
              mb: 0,
            }}
          >
            <FrameworkLink
              to="/"
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                color="secondary"
                sx={{
                  fontWeight: 600,
                  padding: 0,
                  margin: 0,
                  justifyContent: 'left',
                }}

              >
                {"←"} Back
              </Button>


            </FrameworkLink>
            <FormControl>

              <FormLabel>Confirm Code</FormLabel>
              <FilledInput
                type="code"
                name="code"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
     
              >
              </FilledInput>
            </FormControl>

         



            <Button disabled={resetCode == ''} variant="contained" size="large" type="submit" startIcon={<TbCheck />}>
              Confirm
            </Button>

          </Stack>
        
        
        
        )}



          {changePasswordOpen && (
            <Stack
              spacing={2}
              component="form"
              onSubmit={(event: SyntheticEvent) => {
                event.preventDefault();
                const formData = new FormData(event.target as HTMLFormElement);
                const password = formData.get('password') as string;
                const confirmPassword = formData.get('confirmPassword') as string;
                updatePassword(password, confirmPassword);
              }}
              sx={{
                maxWidth: '24rem',
                width: '100%',
                mb: 0,
              }}
            >
              <FrameworkLink
                to="/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  color="secondary"
                  sx={{
                    fontWeight: 600,
                    padding: 0,
                    margin: 0,
                    justifyContent: 'left',
                  }}

                >
                  {"←"} Back
                </Button>


              </FrameworkLink>
              <FormControl>

                <FormLabel>Password</FormLabel>
                <FilledInput
                  type="text"
                  name="password"
                  value={changePassword}
                  onChange={(e) => {
                    const { value } = e.target;
                    setChangePassword(value); // Set state with the new value
                    passwordChanged(value, confirmPassword); // Call the function with the new value
                  }}                 
            
                >
                </FilledInput>
              </FormControl>

              <FormControl>

                <FormLabel>Confirm Password</FormLabel>
                <FilledInput
                  type="text"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    const { value } = e.target;
                    setConfirmPassword(value); // Set state with the new value
                    passwordChanged(password, value); // Call the function with the new value
                  }}       
         
                >
                </FilledInput>
              </FormControl>


              <FormControlLabel
                control={<Checkbox />}
                label="Password Match"
                checked={passwordMatch}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="8 Characters Long"
                checked={eightLong}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Number"
                checked={oneNumber}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="One Capital"
                checked={oneCapital}
              />



              <Button disabled={!passwordMatch || !eightLong || !oneNumber || !oneCapital} variant="contained" size="large" type="submit" startIcon={<TbSwitch />}>
                Change Password
              </Button>

            </Stack>


          )}


        </Box>
      </Box>



   
    </PageContainer>
  );
}

function validateEmail(email: string): boolean {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}




export default Login;