
import { useEffect, useState } from 'react';
import CardTable from '~/components/CardTable';
import { PageContainer } from '~/components/PageContainer';
import { Card } from '~/data/Card';
import DBManager from '~/manager/DBManager';
import { useUser } from '~/context/UserContext';
import DrawerSubtle from '~/components/kit/application/overlays/DrawerSubtleActions';
import { Box, Button, Checkbox, Divider, FilledInput, FormControl, FormControlLabel, FormLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import SimpleNotification from '~/components/kit/application/overlays/SimpleNotification';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import ConfirmDialog from '~/components/ConfirmDialog';
import { toast } from 'react-toastify';




export default function CardList() {



  const [cardList, setCardList] = useState<Card[]>([]);
  const [editCard, setEditCard] = useState(false);
  const { user } = useUser();

  // Request Card Variables
  const [requestCard, setRequestCard] = useState(false);
  const [cardCount, setCardCount] = useState(0);
  const [superpassSelected, setSuperpassSelected] = useState(true);
  const [pipelineSelected, setPipelineSelected] = useState(false);
  const [essoSelected, setEssoSelected] = useState(false);

  // Edit Card Variables
  const [selectedCard, setSelectedCard] = useState<Card | undefined>(undefined);
  const [cardNumber, setCardNumber] = useState('');
  const [driverName, setDriverName] = useState('');
  const [driverID, setDriverID] = useState('');

  const [unitNumber, setUnitNumber] = useState('');
  const [PIN, setPIN] = useState('');
  const [cardStatus, setCardStatus] = useState('');

  // Alert Variables

  // Cancel Card Variables
  const [cancelCardDialog, setCancelCardDialog] = useState(false);


  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const dbManager = new DBManager();
        const cardList = await dbManager.getCards(user?.companyID);
        setCardList(cardList.cards);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (user?.companyID) { // Only fetch invoices if companyID exists
      fetchInfo();

    }
  }, [user?.companyID]); // Include companyID in the dependency array



  const handleEditCard = (newState: boolean, userID: string) => {
    console.log('Edit Card:', newState, userID);
    setEditCard(true);

    // Find the card in the cardList
    const card = cardList.find((card) => card.getID() === userID);
    if (card) {
      setSelectedCard(card);
      setCardNumber(card.getCardNumber());
      setDriverName(card.getDriverName());
      setDriverID(card.getDriverID());
      setUnitNumber(card.getUnitNumber());
      setCardStatus(card.getStatus());
    }

  };

  const handleSaveCard = async () => {
    console.log('Save card clicked');


    // Check if PIN equals last four digits of card number
    if (PIN == cardNumber.slice(-4)) {
      toast.error('Cannot set PIN to last four digits of card number', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });

      return
    } else if (PIN.length != 4 && PIN.length != 0) {

      toast.error('PIN must be 4 digits', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });

      return

    }
    else {

      // Check if PIN is numeric
      if (isNaN(Number(PIN))) {
   
        toast.error('PIN must be numeric', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light"
          });

        return
      }


      // Save the card
      const dbManager = new DBManager();
      // Unwrap selectedCard

      if (selectedCard) {
        setEditCard(false); // Close the drawer
        const newCard = new Card(selectedCard.getID(), cardNumber, unitNumber, driverName, driverID, selectedCard.getSupplier(), cardStatus);
        const response = await dbManager.updateCard(newCard, user?.companyID, PIN);

        toast.success('Card updated successfully', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light"
          });

        // Update the card in the list
        const newCardList = cardList.map((card) => {
          if (card.getID() === newCard.getID()) {
            return newCard;
          } else {
            return card;
          }
        });

        setCardList(newCardList);


      }


    }



  };


  const editDisabled = () => {
    return false;
  }

  const showCancelCardDialog = () => {
    console.log('Cancel card clicked');
    setCancelCardDialog(true);
  }

  const handleCancelCard = async () => {
    console.log('Cancel card confirmed');

    if (selectedCard) {
      const dbManager = new DBManager();
      const newCard = new Card(selectedCard.getID(), cardNumber, unitNumber, driverName, driverID, selectedCard.getSupplier(), 'CANCELLED');
      const response = await dbManager.updateCard(newCard, user?.companyID, PIN);

      // Update the card in the list
      const newCardList = cardList.map((card) => {
        if (card.getID() === newCard.getID()) {
          return newCard;
        } else {
          return card;
        }
      });

      setCardList(newCardList);

    }
    setCancelCardDialog(false);
    setEditCard(false); // Close the drawer
    setSelectedCard(undefined);
    setCardNumber('');
    setDriverName('');
    setDriverID('');
    setUnitNumber('');
    setPIN('');
  }



  const closeAlert = () => {
  }

  const handleCloseDrawer = () => {
    // Add close drawer logic here
    console.log('Close drawer');
    setEditCard(false); // Close the drawer
    // Reset all the fields
    setSelectedCard(undefined);
    setCardNumber('');
    setDriverName('');
    setUnitNumber('');
    setPIN('');
  };


  const handleRequestClose = () => {
    setRequestCard(false);
    setCardCount(0);
  }

  const handleRequestCard = () => {
    console.log('Request Cards:', cardCount, superpassSelected, essoSelected, pipelineSelected);

    const dbManager = new DBManager();
    if (user?.companyID) {
    if (superpassSelected) {
      dbManager.requestCards(cardCount, user?.companyID, 'Superpass');
    }
    if (essoSelected) {
      dbManager.requestCards(cardCount, user?.companyID, 'Esso');
    }
    if (pipelineSelected) {
      dbManager.requestCards(cardCount, user?.companyID, 'Pipeline');
    }

  }
    toast.success('Cards Requested', {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      theme: "light"
      });

    setRequestCard(false);
    setCardCount(0);
  }

  const requestDisabled = () => {
    if (cardCount <= 0) {
      return true;
    }
    return false;
  }

  return (
    <Box>


      {requestCard && (
        <DrawerSubtle
          drawerTitle='Request Cards'
          confirmAction={handleRequestCard}
          closeDrawer={handleRequestClose}
          disableAction={requestDisabled()}
          buttonTitle='Request'
        >
          <Stack
            spacing={2}
            component="form"
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
            sx={{
              maxWidth: '24rem',
              width: '100%',
              mb: 0,
            }}
          >
            <FormControl>

              <FormLabel>Number of Cards</FormLabel>
              <FilledInput
                type="number"
                name="cardCount"
                value={cardCount}
                onChange={(e) => setCardCount(Number(e.target.value))}
              />
            </FormControl>

            <Divider />
            <FormLabel>Supplier</FormLabel>

            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: 'sm',
                },
              }}
              control={<Checkbox />}
              label="Superpass"
              value={superpassSelected}
              checked={superpassSelected}
              onChange={(e, checked) => {
                setSuperpassSelected(checked)
                setEssoSelected(false)
                setPipelineSelected(false)
              }}
            />
            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: 'sm',
                },
              }}
              control={<Checkbox />}
              label="Esso"
              value={essoSelected}
              checked={essoSelected}
              onChange={(e, checked) => {
                setEssoSelected(checked)
                setSuperpassSelected(false)
                setPipelineSelected(false)
              }}
            />
            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: 'sm',
                },
              }}
              control={<Checkbox />}
              label="Pipeline"
              value={pipelineSelected}
              checked={pipelineSelected}
              onChange={(e, checked) => {
                setPipelineSelected(checked)
                setEssoSelected(false)
                setSuperpassSelected(false)
              }}
            />




          </Stack>

        </DrawerSubtle>
      )}

      {editCard && (
        <DrawerSubtle
          drawerTitle='Edit Card'
          confirmAction={handleSaveCard}
          closeDrawer={handleCloseDrawer}
          disableAction={editDisabled()}
          buttonTitle='Save'
        >
          <ConfirmDialog
            title="Are you sure?"
            description="You are about to cancel this card, once you cancel this card cannot be reactivated. Are you sure you want to cancel this card?"
            isOpen={cancelCardDialog}
            onClose={closeAlert}
            primaryButtonLabel='Cancel Card'
            secondaryButtonLabel='Close'
            primaryButtonColor='error'
            onSubmit={handleCancelCard}
            onSecondaryAction={() => setCancelCardDialog(false)}
          >
            <Stack spacing={2}>

            </Stack>
          </ConfirmDialog>

          <Stack
            spacing={2}
            component="form"
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
            sx={{
              maxWidth: '24rem',
              width: '100%',
              mb: 0,
            }}
          >
            <Typography variant="h3">{cardNumber}</Typography>
            <Button
              variant="contained"
              color='error'
              size='medium'
              sx={{ mt: 'auto' }}
              onClick={showCancelCardDialog}
            >
              Cancel Card
            </Button>

            <FormControl>

              <FormLabel>Driver Name</FormLabel>
              <FilledInput
                type="driverName"
                name="driverName"
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
              />
            </FormControl>

            <FormControl>

              <FormLabel>Driver ID</FormLabel>
              <FilledInput
                type="DriverID"
                name="Driver ID"
                value={driverID}
                onChange={(e) => setDriverID(e.target.value)}
              />

            </FormControl>

            <FormControl>

              <FormLabel>Unit #</FormLabel>
              <FilledInput
                type="unitNumber"
                name="unitNumber"
                value={unitNumber}
                onChange={(e) => setUnitNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>

              <FormLabel>PIN</FormLabel>
              <FilledInput
                type="PIN"
                name="PIN"
                value={PIN}
                onChange={(e) => setPIN(e.target.value)}
              />

            </FormControl>

            <FormControl>

              <FormLabel>Status</FormLabel>
              <Select
                value={cardStatus}
                onChange={(ev) => setCardStatus(ev.target.value as string)}
              >
                <MenuItem key='ACTIVE' value='ACTIVE'>Active</MenuItem>
                <MenuItem key='INACTIVE' value='INACTIVE'>InActive</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DrawerSubtle>
      )}

      <Stack spacing={2} direction='row' justifyContent='space-between'>
        <Box id="filter-panel" />
        <Button
          variant="contained"
          color='primary'
          onClick={() => setRequestCard(true)}
        >
          Request Cards
        </Button>

      </Stack>

 
      <CardTable
        tableName=''
        tableData={cardList}
        setEditCard={handleEditCard}
        allowEdit={user?.companyStatus == 'ACTIVE'}



      />

    </Box>

  );
}