import React from 'react';
import { TbRotate, TbRotateClockwise2 } from 'react-icons/tb';

export const LandscapeWarning: React.FC = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      backgroundColor: '#ffff',
      color: '#000',
      fontSize: '24px',
      textAlign: 'center',
      padding: '20px'
    }}>
      Please rotate your device to landscape mode for the best experience.
      <TbRotateClockwise2 />
    </div>
  );
};
