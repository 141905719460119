import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { TbCube3dSphere, TbDeviceMobile, TbLayout, TbPalette } from 'react-icons/tb';

export default function FeaturesSmallColumn({
  features = [
    {
      icon: <TbCube3dSphere />,
      title: 'Beautiful default theme',
      description:
        'With harmonic scales and high-level utility abstractions like Tailwind and Styled System.',
    },
    {
      icon: <TbDeviceMobile />,
      title: 'Fully responsive',
      description: 'With mobile sizes ergonomically built with the responsive object syntax.',
    },
    {
      icon: <TbPalette />,
      title: 'Completely themeable',
      description: 'With TypeScript defined theme tokens, variants, and scales.',
    },
    {
      icon: <TbLayout />,
      title: 'Modern techniques',
      description: 'Grid and Flex native, limited margins and padding.',
    },
  ],
}: {
  features: FeatureProps[];
}) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, minmax(0, 1fr))', // Single column layout on extra small screens
          sm: 'repeat(2, minmax(0, 1fr))', // Two columns for small screens and above
        },
        gap: 4,
        px: { xs: 0, sm: 2 },
        py: 2,
      }}
    >
      {features.map((feature: FeatureProps) => {
        return (
          <Feature
            key={feature.title}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        );
      })}
    </Box>
  );
}

function Feature({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center the content horizontally
        textAlign: 'center', // Center the text inside the Typography components
        fontSize: 'md',
        lineHeight: 1.5,
        gap: 1,
        maxWidth: '65%', // Set a smaller width for each feature
        mx: 'auto', // Ensure it remains centered
      }}
    >
      <Box>{icon}</Box>
      <Typography
        sx={{
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  );
}


type FeatureProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};
