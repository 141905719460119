import { Box } from '@mui/material';
import { styled } from '@mui/system';
import MotionWhite from '~/assets/Logo/MotionMainLG-White.svg';
import MotionBlack from '~/assets/Logo/MotionMainLG.svg';

export function Logo({
  height = '40px',
  width = '100px',
  color = 'black', // You can use this if you want to pass a different colored SVG
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
}) {
  const LogoImage = styled('img')({
    height: '200%',
    width: '200%',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width,
        height: height,
        margin: '0 !important',
      }}
    >
      <LogoImage src={color == 'black' ? MotionBlack : MotionWhite} alt="Logo" />
    </Box>
  );
}

export default Logo;
