export interface InvoiceData {
    id: string;
    companyID: string;
    name: string;
    creationDate: string;  // Assuming dates are handled as strings
    currency: string;
    amount: string;        // Could include currency symbols so kept as string
    status: string;
    s3Key: string;
    remaining: string;
    dueDate: string;
}
export class Invoice {
    private id: string;
    private companyID: string;
    private name: string;
    private creationDate: string;
    private currency: string;
    private amount: number;
    private status: string;
    private s3Key: string;
    private dueDate: string;

    constructor(id: string, companyID: string, s3Key: string, creationDate: string, currency: string, amount: number, status: string, dueDate: string) {
        this.id = id;
        this.companyID = companyID;
        this.s3Key = s3Key;
        this.creationDate = creationDate;
        this.currency = currency;
        this.amount = amount;
        this.status = status;
        this.dueDate = dueDate;
        this.name = this.extractNameFromS3Key(s3Key);
    }

    private extractNameFromS3Key(s3Key: string): string {
        return 's3Key.sp';
    }

    public getID(): string {
        return this.id;
    }

    public getCompanyID(): string {
        return this.companyID;
    }

    public getName(): string {
        return this.name;
    }

    public getCreationDateFormatted(): string {
        return this.creationDate;  // Simplified version
    }

    public getCurrency(): string {
        return this.currency;
    }

    public getFormattedAmount(): string {
        return `$${this.amount} ${this.currency === 'CA' ? 'CAD' : 'USD'}`;
    }

    public getStatus(): string {
        return this.status;
    }

    public getS3Key(): string {
        return this.s3Key;
    }
    
    public getDueDateFormatted(): string {
        return this.dueDate;  // Simplified version
    }

    public toJSON(): {
        id: string;
        companyID: string;
        name: string;
        creationDate: string;
        currency: string;
        amount: string;
        status: string;
        dueDate: string;
        } {
        return {
            id: this.id,
            companyID: this.companyID,
            name: this.name,
            creationDate: this.getCreationDateFormatted(),
            currency: this.currency,
            amount: '$' + this.amount.toLocaleString('en-US') + ' ' + (this.currency),
            status: this.status,
            dueDate: this.getDueDateFormatted()
        };
      }



}
