import { Box, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { addDays, subDays } from 'date-fns';
import { useState, useEffect } from "react";
import { useUser } from "~/context/UserContext";
import DBManager from "~/manager/DBManager";
import StatCardIcon from "./kit/application/elements/StatCardIcon";
import { ChartCard } from "./ChartCard";
import { SimpleLineChart } from "./SimpleLineChart";
import { formatAbbreviatedNumber, formatShortDate } from "~/core/formatters";
import { SimpleBarChart } from "./SimpleBarChart";
import TopSpenderTable from "./TopSpenderTable";
import { Spender } from "~/data/Spender";

const COMMON_X_CONFIG = {
  id: 'categories',
  dataKey: 'date',
  scaleType: 'band',
} as const;

interface Data {
  [key: string]: number;
}


export default function CardOverview() {
  const [alignment, setAlignment] = useState<string | null>('left');
  const [startDate, setStartDate] = useState<Date>(() => new Date());
  const [endDate, setEndDate] = useState<Date>(() => new Date());
  const [dateValue, setDateValue] = useState<number>(7);
  const [country, setCountry] = useState<string | null>('left');
  const { user } = useUser();

  //  Dashboard
  const [caTotalSpend, setCaTotalSpend] = useState<number>(0);
  const [caTotalSpendPercentChange, setCaTotalSpendPercentChange] = useState<number>(0);
  const [caAvgDailySpend, setCaAvgDailySpend] = useState<number>(0);
  const [caAvgDailySpendPercentChange, setCaAvgDailySpendPercentChange] = useState<number>(0);
  const [caFuelVolume, setCaFuelVolume] = useState<number>(0);
  const [caFuelVolumePercentChange, setCaFuelVolumePercentChange] = useState<number>(0);

  const [usTotalSpend, setUsTotalSpend] = useState<number>(0);
  const [usTotalSpendPercentChange, setUsTotalSpendPercentChange] = useState<number>(0);
  const [usAvgDailySpend, setUsAvgDailySpend] = useState<number>(0);
  const [usAvgDailySpendPercentChange, setUsAvgDailySpendPercentChange] = useState<number>(0);
  const [usFuelVolume, setUsFuelVolume] = useState<number>(0);
  const [usFuelVolumePercentChange, setUsFuelVolumePercentChange] = useState<number>(0);

  // Chart
  const [dateSeries, setDateSeries] = useState<any[]>([]);
  const [caVolumeTimeSeries, setCaVolumeTimeSeries] = useState<any[]>([]);
  const [usVolumeTimeSeries, setUsVolumeTimeSeries] = useState<any[]>([]);

  const [costDateSeries, setCostDateSeries] = useState<any[]>([]);
  const [caCostTimeSeries, setCaCostTimeSeries] = useState<any[]>([]);
  const [usCostTimeSeries, setUsCostTimeSeries] = useState<any[]>([]);

  // Top Spender
  const [caTopSpender, setCaTopSpender] = useState<Spender[]>([]);
  const [usTopSpender, setUsTopSpender] = useState<Spender[]>([]);
  
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setAlignment(newAlignment);
  };

  const handleSpenderAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setCountry(newAlignment);
  };

  const updateDates = (daysAmount: number) => {
    const end = new Date();
    const start = subDays(end, daysAmount);
    setEndDate(end);
    setStartDate(start);
    getOverview(daysAmount);
  };

  useEffect(() => {
    switch (alignment) {
      case 'left':
        updateDates(7);
        setDateValue(7);
        break;
      case 'middle':
        updateDates(14)
        setDateValue(14);
        break;
      case 'right':
        updateDates(28);
        setDateValue(28);

        break;
      default:
        break;
    }
  }, [alignment]);



  const getOverview = async (days: number) => {
    const dbManager = new DBManager();
    const data = await dbManager.getOverview(user?.companyID, days)
    setCaTotalSpend(data.caTotalSpend);
    setCaTotalSpendPercentChange(data.caSpendPercentage);
    setCaAvgDailySpend(data.caAvgDailySpend);
    setCaAvgDailySpendPercentChange(data.caAvgSpendPercentage);
    setCaFuelVolume(data.caFuelVolume);
    setCaFuelVolumePercentChange(data.caVolumePercentage);

    setUsTotalSpend(data.usTotalSpend);
    setUsTotalSpendPercentChange(data.usSpendPercentage);
    setUsAvgDailySpend(data.usAvgDailySpend);
    setUsAvgDailySpendPercentChange(data.usAvgSpendPercentage);
    setUsFuelVolume(data.usFuelVolume);
    setUsFuelVolumePercentChange(data.usVolumePercentage);


    let result = generateDateAndValueArrays([data.caVolumeTimeSeries, data.usVolumeTimeSeries]);

    setDateSeries(result.dateArray);
    setCaVolumeTimeSeries(result.valueArrays[0]);
    setUsVolumeTimeSeries(result.valueArrays[1]);

    result = generateDateAndValueArrays([data.caCostTimeSeries, data.usCostTimeSeries]);
    setCostDateSeries(result.dateArray);
    setCaCostTimeSeries(result.valueArrays[0]);
    setUsCostTimeSeries(result.valueArrays[1]);
    console.log(data);

    // Loop through the top spenders and initialize the top spender table
    const caTopSpenders: Spender[] = [];
    const usTopSpenders: Spender[] = [];
    data.caTopCards.forEach((spender: any) => {
      caTopSpenders.push(new Spender(spender.cardNumber, spender.transactions, spender.amount));
    });
    data.usTopCards.forEach((spender: any) => {
      usTopSpenders.push(new Spender(spender.cardNumber, spender.transactions, spender.amount));
    });

    setCaTopSpender(caTopSpenders);
    setUsTopSpender(usTopSpenders);

  }


  const generateDateAndValueArrays = (datasets: Data[]) => {
    const allDates = new Set<string>();
    datasets.forEach(data => {
      Object.keys(data).forEach(date => allDates.add(date));
    });

    const sortedDates = Array.from(allDates).sort();

    const dateArray: string[] = sortedDates;
    const valueArrays: (number | null)[][] = datasets.map(() => []);

    dateArray.forEach(dateString => {
      datasets.forEach((data, index) => {
        valueArrays[index].push(data[dateString] || null);
      });
    });

    return {
      dateArray,
      valueArrays
    };
  };


  return (
    <Box
      width='100%'
    >
      <Box
        sx={{
          width: '100%',
          bgcolor: 'grey.100',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '2rem',
          borderRadius: '10px',
          flexWrap: 'wrap'
        }}>

        <Stack
          direction='column'
          justifyContent='space-between'
          width='100%'
        >

          <Stack
            direction='row'
            justifyContent='space-between'
            width='100%'
            sx={{ paddingRight: '10px', paddingTop: '10px' }}
          >
            <Typography
              fontWeight='600'
              fontSize='14pt'
            >
              Summary
            </Typography>

            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              size="small"
            >
              <ToggleButton value="left">7D</ToggleButton>
              <ToggleButton value="middle">14D</ToggleButton>
              <ToggleButton value="right">28D</ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Typography
            fontWeight='500'
            fontSize='10pt'
          >
            {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()} vs. Previous ${dateValue} days`}
          </Typography>
          <Stack
            direction='row'
            spacing={1}
            paddingBottom={1}
          >
            <StatCardIcon
              title='Total Spend'
              value={caTotalSpend}
              description=""
              valuePrefix="$"
              titleIcon=''
              percentChange={caTotalSpendPercentChange}
            />

            <StatCardIcon
              title='Avg. Daily Spend'
              value={caAvgDailySpend}
              valuePrefix="$"
              description=""
              percentChange={caAvgDailySpendPercentChange}
              titleIcon=''
            />

            <StatCardIcon
              title='Fuel Volume (L)'
              value={caFuelVolume}
              description=""
              titleIcon=''
              percentChange={caFuelVolumePercentChange}
            />
            <Divider orientation="vertical" />
            <StatCardIcon
              title='Total Spend (USD)'
              value={usTotalSpend}
              description=""
              valuePrefix="$"
              titleIcon=''
              percentChange={usTotalSpendPercentChange}
            />

            <StatCardIcon
              title='Avg. Daily Spend'
              value={usAvgDailySpend}
              description=""
              valuePrefix="$"
              percentChange={usAvgDailySpendPercentChange}
              titleIcon=''
            />

            <StatCardIcon
              title='Fuel Volume (gal)'
              value={usFuelVolume}
              description=""
              titleIcon=''
              percentChange={usFuelVolumePercentChange}
            />

          </Stack>



        </Stack>
      </Box>


      <Stack direction='row' spacing={2} paddingTop={2}>
        <ChartCard title="Fuel Cost" description={`Over the past ${dateValue} days`} >
          <SimpleBarChart
            xAxis={[
              {
                ...COMMON_X_CONFIG,
                data: costDateSeries,
                valueFormatter: formatShortDate,
                tickNumber: 2,
                tickLabelInterval(value: any, index: number) {
                  // only show the first and last ticks
                  return index === 0 || index === dateSeries.length - 1;
                },
              },
            ]}
    
            series={[
              {
                data: caCostTimeSeries,
                label: 'Canada Volume (CAD)',
              },
              {
                data: usCostTimeSeries,
                label: 'USA Volume (USD)',
              },
            ]}
          />
        </ChartCard>
        <ChartCard title="Fuel Volume" description={`Over the past ${dateValue} days`} >
          <SimpleBarChart
            xAxis={[
              {
                ...COMMON_X_CONFIG,
                data: dateSeries,
                valueFormatter: formatShortDate,
                tickNumber: 2,
                tickLabelInterval(value: any, index: number) {
                  // only show the first and last ticks
                  return index === 0 || index === dateSeries.length - 1;
                },
              },
            ]}
            series={[
              {
                data: caVolumeTimeSeries,
                label: 'Canada Volume (L)',
              },
              {
                data: usVolumeTimeSeries,
                label: 'USA Volume (gal)',
              },
            ]}
          />
        </ChartCard>
      </Stack>
      <Divider sx={{paddingTop: '10px'}}/>
          <Box>

          <Typography
            fontWeight='600'
            fontSize='14pt'
            paddingTop='1rem'>
            Top Spenders
            </Typography>


          <ToggleButtonGroup
              value={country}
              exclusive
              onChange={handleSpenderAlignment}
              size="small"
              sx={{paddingTop: '1rem'}}
            >
              <ToggleButton value="left">Canada</ToggleButton>
              <ToggleButton value="right">USA</ToggleButton>

            </ToggleButtonGroup>
      <TopSpenderTable 
      tableName=""
      tableData={country === 'left' ? caTopSpender : usTopSpender}
      
      />
      </Box>
    </Box>

  );
}
