import { getData } from '~/lib/utils/toLocaleDate';
import { H5, PM } from './typography';
import './ui.scss';

export type PositionCardProps = {
  positionTitle: string;
  location: string;
  positionType: string;
  slug: string;
  date: string;
};

export const PositionCard = ({
  positionTitle,
  location,
  positionType,
  date,
  slug,
}: PositionCardProps) => {
  return (
    <div className="card">
      <a href={`/careers/${slug}`} className="gap-2 grid">
        <H5>{positionTitle}</H5>
        <div className="text-lg">
          {positionType} | {location}
        </div>
        <PM>Date: {getData(date)}</PM>
        <div className="text-blue-600 hover:underline">Read more</div>
      </a>
    </div>
  );
};
