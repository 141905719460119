import { CubeIcon } from '~/components/icons';
import { Feature } from '~/components/ui/feature';
import { H2, PL, PM, Tag } from '~/components/ui/typography';

export const ClientPortal = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient1 from-gradient2">
      <div className="container">
        <Feature reversed>
          <>
            <div className="flex flex-col items-center lg:items-start gap-6">
              <Tag>Easy to use</Tag>
              <H2>Discover the Power of Our Easy-to-Use Client Portal</H2>
              <PL>
                Our client portal is designed with simplicity in mind, providing you with an
                intuitive and hassle-free experience.
              </PL>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 items-center">
                <CubeIcon />
                <PM>Efficiently manage your invoices and payments</PM>
              </div>
              <div className="flex gap-4 items-center">
                <CubeIcon />
                <PM>Access real-time financial reports for better decision-making</PM>
              </div>
              <div className="flex gap-4 items-center">
                <CubeIcon />
                <PM>Stay informed with instant notifications and updates</PM>
              </div>
            </div>
          </>
        </Feature>
      </div>
    </div>
  );
};
