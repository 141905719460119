import { BlogCard } from '~/components/ui/blog-card';
import { BlogPostType } from '~/pages';

export type BlogPostsProps = {
  data: BlogPostType[];
};

export const BlogPosts = ({ data }: BlogPostsProps) => {
  return (
    <div className="bg-gradient-to-b from-gradient2 to-gradient1 pb-20 rounded-b-[150px]">
      <div className="container">
        <div>
          {data.map((post, index) => {
            return (
              <BlogCard
                key={index}
                date={post.date}
                title={post.name}
                description={post.subtitle}
                link={post.slug}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
