
import { Box, Button, Typography } from '@mui/material';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { useState, useEffect } from 'react';
import DBManager from '~/manager/DBManager';
import { useUser } from '~/context/UserContext';
import { styled } from '@mui/system';
import FeaturesSmallColumn from '~/components/kit/marketing/page-sections/FeaturesSmallColumn';
import { TbHeadset, TbLocation, TbTool, TbWallet } from 'react-icons/tb';

export function FleetManagement() {
    const { user } = useUser();
    const [onWaitlist, setOnWaitlist] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const dbManager = new DBManager();
            if (!user?.companyID) {
                return;
            }
            const waitlistValue = await dbManager.getWaitlist('fleetmanagement', user?.companyID)
            setOnWaitlist(waitlistValue);
        };

        fetchData();
    }, []);

    const joinWaitlist = async () => {
        const dbManager = new DBManager();
        if (!user?.companyID) {
            return;
        }
        await dbManager.updateWaitlist('fleetmanagement', user?.companyID);
        setOnWaitlist(true);
    }

    
    const GradientText = styled('span')({
        background: 'linear-gradient(to right, #0974f1, #103783)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    });


    return (
        <PageContainer>


        <Typography
            variant="h1"
            sx={{ fontSize: { xs: '2xl', sm: '3xl' }, textAlign: 'center' }}
        >
            Join the Waitlist for <br />
            <Typography
                variant="h1"
                sx={{ fontSize: { xs: '2xl', sm: '3xl' }, textAlign: 'center', display: 'inline' }}
            >
                <GradientText>Fleet Management</GradientText>
            </Typography>
        </Typography>

        <Box>
    <FeaturesSmallColumn 
    
    features={[
        {
            title: 'Optimize Fleet Efficiency',
            description: 'Real-time tracking and analytics to ensure your fleet is operating at peak performance.',
            icon: <TbLocation/>,
        },
        {
            title: 'Reduce Downtime',
            description: 'Proactive maintenance alerts to prevent unexpected breakdowns and keep your vehicles on the road.',
            icon: <TbTool/>,
        },
        {
            title: 'Cost Control',
            description: 'Monitor fuel consumption and vehicle usage to manage costs effectively and improve profitability.',
            icon: <TbWallet/>,
        },
        {
            title: '24/7 Support',
            description: 'Get round-the-clock assistance from our fleet management experts to handle any issue that arises.',
            icon: <TbHeadset/>,
        },
    ]}
    />
</Box>


        {(!onWaitlist) && (
            <Button
                variant='contained'
                color='primary'
                onClick={joinWaitlist}
                // Center the button on the page
                sx={{ margin: 'auto', width: '25%' }}
            >
                Join Waitlist
            </Button>
        )}

        {(onWaitlist) && (

            <Typography
                // center text on page
                sx={{ textAlign: 'center' }}
            >
                You are on the waitlist for Motion Factoring. We will notify you once it is available.
            </Typography>


        )}
    </PageContainer>
    );
}

export default FleetManagement;