import { Menu } from '~/components/ui/menu';
import { H1, PL } from '~/components/ui/typography';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils/style';

export type HeaderProps = {
  title: string | null;
  text?: string | null;
  image?: string | null;
};

export const Header = ({ title, text, image }: HeaderProps) => {
  return (
    <div>
      <Menu />
      <div className="pt-32 md:pt-52 pb-20">
        <div className="container">
          <div
            className={cn('flex flex-col gap-6 text-center max-w-[775px] mx-auto', {
              'pb-20': text,
            })}
          >
            <H1>{title}</H1>
            {text && (
              <>
                <PL>{text}</PL>
                <div className="flex flex-col justify-center sm:flex-row gap-4 m-auto lg:m-0">
                  <Button buttonSize="large" buttonType="solid" textColor="white">
                    Get Started
                  </Button>
                  <Button buttonSize="large" buttonType="invisible" textColor="primary">
                    Contact Us
                  </Button>
                </div>
                <figure className="block bg-gray-500 h-[350px] lg:h-[600px] w-full rounded-3xl overflow-hidden">
                  {image && <img src={image} className="object-cover" alt="Motion picture" />}
                </figure>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
