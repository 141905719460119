
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { PaymentCard } from '~/components/PaymentCard';
import { TitleDescriptionBlock } from '~/components/TitleDescriptionBlock';

export function PaymentSettings() {


  return (
    <PageContainer>

      <DashboardHeader
        title="Payment Settings"
        subtitle="Update your payment settings."
      />

      <TitleDescriptionBlock
        title="Payment Methods"
        description=""
      />
      <PaymentCard currency="cad" />

      <PaymentCard currency="usd" />



    </PageContainer>
  );
}

export default PaymentSettings;