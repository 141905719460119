import { Box, Button, Portal, Stack, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React from 'react';
import {
    currencyColumnFactory,
    dateColumnFactory,
    genericColumnFactory,
    timeColumnFactory
} from '~/components/ColumnDefinitions';

import { StatusBadge, StatusLabel } from '~/components/StatusBadge';
import { Table } from '~/components/Table';
import { clientFiltering } from '~/core/filters';
import { useFilters } from '~/hooks/useFilters';
import { Transaction } from '~/data/Transaction';
import { useState, useEffect } from 'react';
import { Invoice } from '~/data/Invoice';
import { MoneyCode } from '~/data/MoneyCode';

export function MoneyCodeTable({
    tableHeight,
    allowCheckboxSelection = false,
    tableData,
    paginationMode = 'client',
    totalRows,
    handlePageChange
}: {
    tableHeight?: number;
    allowCheckboxSelection?: boolean;
    tableName: string;
    tableData: MoneyCode[] | undefined;
    pageSize?: number;
    page?: number;
    paginationMode?: 'client' | 'server';
    totalRows?: number;
    handlePageChange: (newPage: number, newPageSize: number) => void;
}) {
    const { filters } = useFilters();
    const [transformedData, setTransformedData] = useState<any[]>([]); // Use state for transformedData

    useEffect(() => {
        // Update transformedData whenever tableData changes
        if (tableData) {
            console.log('Table Data:', tableData);
            console.log('Transformed Data:', tableData.map((item: MoneyCode) => item.toJSON()));

            setTransformedData(tableData.map((item: MoneyCode) => item.toJSON()));
        } else {
            setTransformedData([]);
        }
        console.log('Pagination Mode:', paginationMode);
    }, [tableData]); // Include tableData in dependency array


    const paginationModelChanged = (model: any) => {
        handlePageChange(model.page, model.pageSize);
    }


    const columnDefinitions: GridColDef[] = [
        genericColumnFactory({
            field: 'creationDate',
            headerName: 'Date Created',
            flex: 1.5,
            minWidth: 150,
        }),
        genericColumnFactory({
            field: 'code',
            headerName: 'Code',
            flex: 1.5,
            minWidth: 150,
        }),

        genericColumnFactory({
            field: 'amountUsed',
            headerName: 'Amount Used',
            flex: 1.5,
            minWidth: 50,
            maxWidth: 100,
        }),
        genericColumnFactory({
            field: 'amountTotal',
            headerName: 'Amount Created',
            minWidth: 50,
            maxWidth: 125,
            flex: 2
        }),
     
        genericColumnFactory({
            field: 'notes',
            headerName: 'Notes',
            minWidth: 75,
            flex: 2,
        }),
    ];

    return (

        <Box
            sx={{
                flex: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                gap: 1.5,
            }}
        >
      
            <Table
                tableHeight={tableHeight}
                columns={columnDefinitions}
                rows={clientFiltering(transformedData, filters)}
                initialState={{
                    sorting: { sortModel: [{ field: 'dueDate', sort: 'desc' }] },
                }}
                pagination
                hideFooter={false}
                paginationMode={paginationMode}
                onPaginationModelChange={(model) => paginationModelChanged(model)}
                rowCount={totalRows || transformedData.length}
                disableColumnFilter
                slots={{ toolbar: CustomQuickSearch }}
                checkboxSelection={allowCheckboxSelection}
                getRowClassName={(params) => {
                    if (params.row.status === 'failed') {
                        return 'datagrid-row-error';
                    }
                    return '';
                }}
            />
        </Box>
    );
}

function CustomQuickSearch(props: any) {
    return (
        <React.Fragment>
            <Portal container={() => document.getElementById('filter-panel')!}>
                <GridToolbarQuickFilter
                    variant="filled"
                    placeholder="Search notes, code, ..."
                    sx={{
                        width: 200,
                        borderColor: 'gray.200',
                        paddingBottom: 0,
                        '.MuiInputBase-root': {
                            fontSize: 'xs',
                            height: 30,
                            paddingX: 0.5,
                        },
                        '.MuiInputBase-input': {
                            paddingY: 0,
                        },
                        '.MuiSvgIcon-root': {
                            height: 16,
                            width: 16,
                        },
                    }}
                />
            </Portal>
        </React.Fragment>
    );
}

export default MoneyCodeTable;
