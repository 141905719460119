import { Menu } from '~/components/ui/menu';
import { H1, PL } from '~/components/ui/typography';

export const Header = () => {
  return (
    <div className="flex flex-col">
      <Menu />
      <div className="pt-52">
        <div className="container md:pt-0">
          <H1 className="text-center mt-8">About Us</H1>
          <div className="flex flex-col lg:flex-row justify-between gap-7 items-center container mx-auto py-16 px-8 lg:px-0">
            <div className="lg:w-1/2 space-y-6 order-2 lg:order-1">
              <PL className="font-bold">
                Hi! We're a team of party people who think it should be easier to plan events.
              </PL>
              <PL>
                It's wild that you need six different apps to plan a party and get the word out.
                Texting, Stories, and group chats don't cut it, especially when you're inviting
                friends-of-friends. You deserve a party page that hypes up your event and makes it
                easier for guests to get the info they need. That's why we built Partiful.
              </PL>
            </div>
            <div className="bg-white w-[640px] order-1 lg:order-2 h-full min-h-[250px] sm:min-h-[300px] md:min-h-[400px] max-h-[650px] max-w-full lg:max-w-[45%] rounded-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};
