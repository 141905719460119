import { Button } from '~/components/ui/button';
import { H2, PL } from '~/components/ui/typography';

export const Careers = () => {
  return (
    <div className="bg-gradient-to-b from-gradient2 to-gradient1 rounded-b-[150px]">
      <div className="container">
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start container mx-auto py-16 px-8 lg:px-0">
          <div className="bg-white w-[640px] h-full min-h-[250px] sm:min-h-[300px] md:min-h-[400px] max-h-[650px] max-w-full lg:max-w-[45%] rounded-xl" />
          <div className="lg:w-1/2 space-y-6 mt-10 lg:mt-0">
            <H2>Love what we're doing? Join our team!</H2>
            <PL>
              We get to spend every day helping people have fun. We get to build something our
              friends use. We get invited to amazing parties. (We even get personal party budgets...
              you know... for product testing…)
            </PL>
            <Button
              buttonSize="large"
              buttonType="solid"
              className="bg-transparent border border-white mt-6 hover:bg-white hover:text-black"
              textColor='black'
            >
              Careers
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
