import { useEffect, useState } from 'react';
import client from '~/sanity-client';
import { BlogPostType } from './BlogLanding';
import { useLocation } from 'react-router-dom';
import { Footer, Header } from '~/components/sections';
import { Container, Stack } from '@mui/material';
import { PL } from '~/components/ui/typography';
import { getData } from '~/lib/utils/toLocaleDate';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { richTextComponents } from '~/components/ui/rich-components';

export const BlogPageLanding = () => {
  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const [data, setData] = useState<BlogPostType | null>(null);
  const [loading, setLoading] = useState(true);

  const GROQ_QUERY_BY_SLUG = `
    *[_type == "post" && slug.current == '${slug}'][0] {
      _id,
      "slug": slug.current,
      subtitle,
      date,
      name,
      "image": image.asset->url,
      content
    }`;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      return await client.fetch<BlogPostType | null>(GROQ_QUERY_BY_SLUG);
    };
    fetchData()
      .then((data) => setData(data))
      .finally(() => setLoading(false));
  }, [GROQ_QUERY_BY_SLUG]);

  if (loading) {
    return 'loading';
  }

  return (
    <div>
      <Header title={data?.name ?? ''} text={data?.subtitle ?? ''} image={data?.image ?? ''} />
      <Container fixed>
        <Stack className="m-auto w-[90%] md:w-[70%]" gap="10px">
          <PL>Date: {getData(data?.date ?? '')}</PL>
          <PortableText
            components={richTextComponents as Partial<PortableTextReactComponents>}
            value={data?.content ?? []}
          />
        </Stack>
      </Container>
      <Footer />
    </div>
  );
};
