import { useEffect, useState } from 'react';
import { DashboardHeader } from '~/components/DashboardHeader';
import { PageContainer } from '~/components/PageContainer';
import { useUser } from '~/context/UserContext';
import DBManager from '~/manager/DBManager';
import { Location } from '~/data/Location';
import LocationTable from '~/components/LocationTable';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import Map, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Pin from '~/components/pin';
import esso from '~/assets/esso.png';
import pipeline from '~/assets/pipeline.png';
import superpass from '~/assets/superpass.png';
import ta from '~/assets/TA.png';
import petro from '~/assets/petro.png';

export function Locations() {
  const { user } = useUser();
  const [locations, setLocations] = useState<Location[]>([]);
  const [pins, setPins] = useState<JSX.Element[]>([]);
  const [popupInfo, setPopupInfo] = useState<Location | undefined>(undefined);

  const [essoChecked, setEssoChecked] = useState(true);
  const [superpassChecked, setSuperpassChecked] = useState(true);
  const [pipelineChecked, setPipelineChecked] = useState(true);
  const [taChecked, setTAChecked] = useState(true);
  const [petroChecked, setPetroChecked] = useState(true);
  useEffect(() => {
    const fetchInfo = async () => {
      console.log("Fetching Locations");
      const dbManager = new DBManager();
      const locations = await dbManager.getLocations(user?.companyID);
      console.log(locations);
      setLocations(locations);
    };

    if (user?.companyID) {
      fetchInfo();
    }
  }, [user?.companyID]);

  useEffect(() => {
    const generatePins = () => {
      const newPins = locations
        .filter(location => {
          if (essoChecked && location.getLocationSupplier() === 'ESSO') return true;
          if (superpassChecked && location.getLocationSupplier() === 'SUPERPASS') return true;
          if (pipelineChecked && location.getLocationSupplier() === 'PIPELINE') return true;
          if (taChecked && location.getLocationSupplier() === 'TA') return true;
          if (petroChecked && location.getLocationSupplier() === 'PETRO') return true;
          return false;
        })
        .map((location, index) => (
          <Marker
            key={`marker-${index}`}
            longitude={location.getLongitude()}
            latitude={location.getLatitude()}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setPopupInfo(location);
            }}
          >
            <Pin
              color={
                location.getLocationSupplier() === 'ESSO'
                  ? 'blue'
                  : location.getLocationSupplier() === 'SUPERPASS'
                    ? 'red'
                    : location.getLocationSupplier() === 'PIPELINE'
                      ? 'yellow'
                      : location.getLocationSupplier() === 'TA'
                        ? 'darkblue'
                        : location.getLocationSupplier() === 'PETRO'
                          ? 'green'
                          : 'default'
              }
            />
          </Marker>
        ));
      setPins(newPins); // Update pins state
    };

    generatePins(); // Generate pins whenever locations change
  }, [locations, essoChecked, superpassChecked, pipelineChecked, taChecked, petroChecked]);

  return (
    <PageContainer>
      <DashboardHeader title="Locations" />
      <Box display="flex" flexDirection="column" overflow="hidden">
        <Typography
          variant="h2"
          sx={{
            '.MuiTypography-root': {
              fontSize: 'md',
            },
          }}
        >
          Locations:
        </Typography>


        <Typography
          variant="h3"
          sx={{
            '.MuiTypography-root': {
              fontSize: 'sm',
            },
          }}
        >
          Canada:
        </Typography>
        <Stack direction="row" spacing={1} padding={2}>
          {/* Checkbox to select supplier   */}
          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: 'md',
              },
            }}
            control={<Checkbox />}
            value={essoChecked}
            checked={essoChecked}
            label="Esso"
            onChange={(e, checked) => {
              setEssoChecked(checked)
            }}
          />

          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: 'md',
              },
            }}
            control={<Checkbox />}
            value={superpassChecked}
            checked={superpassChecked}
            label="Superpass"
            onChange={(e, checked) => {
              setSuperpassChecked(checked)
            }}
          />

          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: 'md',
              },
            }}
            control={<Checkbox />}
            value={pipelineChecked}
            checked={pipelineChecked}
            label="Pipeline"
            onChange={(e, checked) => {
              setPipelineChecked(checked)
            }}
          />
        </Stack>


        <Typography
          variant="h3"
          sx={{
            '.MuiTypography-root': {
              fontSize: 'sm',
            },
          }}
        >
          USA:
        </Typography>
        <Stack direction="row" spacing={1} padding={2}>

          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: 'md',
              },
            }}
            control={<Checkbox />}
            value={taChecked}
            checked={taChecked}
            label="TA"
            onChange={(e, checked) => {
              setTAChecked(checked)
            }}
          />
          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: 'md',
              },
            }}
            control={<Checkbox />}
            value={petroChecked}
            checked={petroChecked}
            label="Petro"
            onChange={(e, checked) => {
              setPetroChecked(checked)
            }}
          />
        </Stack>

        <Box height="85vh">
          <Map
            mapboxAccessToken="pk.eyJ1IjoiamVldmVubSIsImEiOiJjbTFncGs3bzUwNmZ1Mnhvc3lsejcyNWF3In0.cdPamYuGz_1hDzo-n3A2jA"
            initialViewState={{
              longitude: -99.9018,
              latitude: 41.4925,
              zoom: 3,
            }}
            style={{ width: '90%', height: '90%', borderRadius: '10px' }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
          >
            {pins}

            {popupInfo && (
              <Popup
                anchor="top"
                longitude={Number(popupInfo.getLongitude())}
                latitude={Number(popupInfo.getLatitude())}
                onClose={() => setPopupInfo(undefined)}
              >
                <div>
                  {popupInfo.getLocationSupplier() === 'ESSO' && (
                    <img src={esso} alt="Esso" style={{ width: '25%', height: 'auto', borderRadius: '10px' }} />
                  )}
                  {popupInfo.getLocationSupplier() === 'PIPELINE' && (
                    <img src={pipeline} alt="Pipeline" style={{ width: '25%', height: 'auto', borderRadius: '10px' }} />
                  )}
                  {popupInfo.getLocationSupplier() === 'SUPERPASS' && (
                    <img src={superpass} alt="SuperPass" style={{ width: '25%', height: 'auto', borderRadius: '10px' }} />
                  )}
                  {popupInfo.getLocationSupplier() === 'TA' && (
                    <img src={ta} alt="TA" style={{ width: '25%', height: 'auto', borderRadius: '10px' }} />
                  )}
                  {popupInfo.getLocationSupplier() === 'PETRO' && (
                    <img src={petro} alt="Petro" style={{ width: '25%', height: 'auto', borderRadius: '10px' }} />
                  )}

                  {popupInfo.getLocationSupplier()} {popupInfo.getLocationName()}
                  <br />
                  {popupInfo.getLocationAddress()}, {popupInfo.getLocationCity()}, {popupInfo.getLocationStateProv()} {popupInfo.getLocationZip()}
                  <br />
                  DEF at the Pump: {popupInfo.getDEFAvailable() ? "Yes" : "No"}
                </div>
              </Popup>
            )}
          </Map>
        </Box>


      </Box>
    </PageContainer>
  );
}

export default Locations;
