import { advantages } from '~/lib/constants';
import { Advantage } from '~/components/ui/advantage';

export const AdvantageSection = () => {
  return (
    <div className="bg-gradient-to-b to-gradient1 from-gradient2">
      <div className="container">
        {advantages.map((adv) => {
          return <Advantage key={adv.page} {...adv} />;
        })}
      </div>
    </div>
  );
};
