import { Footer } from '~/components/sections';
import { Menu } from '~/components/ui/menu';
import { H1, H3, PL, PM } from '~/components/ui/typography';

export const TermsAndConditionsPage = () => {
  return (
    <>
      <div className="flex flex-col">
        <Menu />
        <div className="pt-40 flex-1">
          <div className="container h-full flex flex-col py-16 gap-8 justify-center">
            <H1>Terms and Conditions</H1>
            <PL>
              By reviewing pages on Motion web site you agree to be bound <br /> by our Terms &
              Conditions
            </PL>
            <PM className="text-gray-400">Last updated: September 2024</PM>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b from-gradient2 to-gradient1 pt-10 pb-20 rounded-b-[150px]">
        <div className="container flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <H3>Cookie Policy</H3>
            <PL>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget odio ac est
              lacinia mollis sed sed mi. Aliquam eget consectetur nisl. Nullam ultricies fermentum
              interdum. Etiam urna nunc, gravida vel leo in, sollicitudin finibus nibh. Mauris
              rutrum metus ut faucibus sagittis. Donec vel tortor leo. Aenean tristique venenatis
              leo, et fringilla odio elementum vitae.
              <br /> <br />
              In porttitor laoreet malesuada. Phasellus sit amet metus maximus, mollis felis in,
              commodo velit. Proin gravida mi in nisl pharetra dapibus. Suspendisse porttitor
              commodo tincidunt. Pellentesque mattis volutpat fringilla. Proin et sagittis mauris.
              Suspendisse et feugiat arcu, sit amet mattis massa. Ut suscipit semper nulla, in
              pharetra sapien viverra et. Praesent in enim semper, sagittis elit vel, fermentum
              quam.
            </PL>
          </div>
          <div className="flex flex-col gap-4">
            <H3>Acceptable Use</H3>
            <PL>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget odio ac est
              lacinia mollis sed sed mi. Aliquam eget consectetur nisl. Nullam ultricies fermentum
              interdum. Etiam urna nunc, gravida vel leo in, sollicitudin finibus nibh. Mauris
              rutrum metus ut faucibus sagittis. Donec vel tortor leo. Aenean tristique venenatis
              leo, et fringilla odio elementum vitae.
              <br /> <br />
              In porttitor laoreet malesuada. Phasellus sit amet metus maximus, mollis felis in,
              commodo velit. Proin gravida mi in nisl pharetra dapibus. Suspendisse porttitor
              commodo tincidunt. Pellentesque mattis volutpat fringilla. Proin et sagittis mauris.
              Suspendisse et feugiat arcu, sit amet mattis massa. Ut suscipit semper nulla, in
              pharetra sapien viverra et. Praesent in enim semper, sagittis elit vel, fermentum
              quam.
            </PL>
          </div>
          <div className="flex flex-col gap-4">
            <H3>Privacy Policy</H3>
            <PL>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget odio ac est
              lacinia mollis sed sed mi. Aliquam eget consectetur nisl. Nullam ultricies fermentum
              interdum. Etiam urna nunc, gravida vel leo in, sollicitudin finibus nibh. Mauris
              rutrum metus ut faucibus sagittis. Donec vel tortor leo. Aenean tristique venenatis
              leo, et fringilla odio elementum vitae.
              <br /> <br />
              In porttitor laoreet malesuada. Phasellus sit amet metus maximus, mollis felis in,
              commodo velit. Proin gravida mi in nisl pharetra dapibus. Suspendisse porttitor
              commodo tincidunt. Pellentesque mattis volutpat fringilla. Proin et sagittis mauris.
              Suspendisse et feugiat arcu, sit amet mattis massa. Ut suscipit semper nulla, in
              pharetra sapien viverra et. Praesent in enim semper, sagittis elit vel, fermentum
              quam.
            </PL>
          </div>
          <div className="flex flex-col gap-4">
            <H3>Terms and Conditions</H3>
            <PL>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget odio ac est
              lacinia mollis sed sed mi. Aliquam eget consectetur nisl. Nullam ultricies fermentum
              interdum. Etiam urna nunc, gravida vel leo in, sollicitudin finibus nibh. Mauris
              rutrum metus ut faucibus sagittis. Donec vel tortor leo. Aenean tristique venenatis
              leo, et fringilla odio elementum vitae.
              <br /> <br />
              In porttitor laoreet malesuada. Phasellus sit amet metus maximus, mollis felis in,
              commodo velit. Proin gravida mi in nisl pharetra dapibus. Suspendisse porttitor
              commodo tincidunt. Pellentesque mattis volutpat fringilla. Proin et sagittis mauris.
              Suspendisse et feugiat arcu, sit amet mattis massa. Ut suscipit semper nulla, in
              pharetra sapien viverra et. Praesent in enim semper, sagittis elit vel, fermentum
              quam.
            </PL>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
