import { H2, H5, PL } from '~/components/ui/typography';

export const OurStory = () => {
  return (
    <div className="bg-gradient-to-b from-gradient2 to-gradient1 py-20">
      <div className="container flex flex-col gap-10">
        <div className="flex justify-between flex-wrap gap-10">
          <H2>Our Story</H2>
          <div className="flex flex-col gap-6 max-w-[600px]">
            <H5>
              It all started with a counter-intuitive product: a corporate card with spend
              management software that actually helps companies spend less of their two most
              valuable resources: time and money.
            </H5>
            <div className="flex gap-4 flex-col md:flex-row">
              <PL>
                Founded in 2019 and backed by leading investors including Founders Fund, Stripe,
                Goldman Sachs, Coatue Management, D1 Capital Partners, Redpoint Ventures and Thrive
                Capital, Ramp was recently named Fast Company’s #1 Most Innovative Company in North
                America.
              </PL>
              <PL>
                We are NYC’s fastest growing startup and America’s fastest growing corporate card.
                We’re building the ultimate platform for modern finance teams – and we’re just
                getting started.
              </PL>
            </div>
          </div>
        </div>
        <figure className="block bg-gray-500 h-[350px] lg:h-[600px] w-full rounded-3xl overflow-hidden">
          {/* <img src={imageSrc} className='object-cover' alt='Motion picture' /> */}
        </figure>
      </div>
    </div>
  );
};
