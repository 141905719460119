import { Feature } from '~/components/ui/feature';
import { H2, H6, PL, PM, Tag } from '~/components/ui/typography';

export const BusinessPotential = () => {
  return (
    <div className="py-16 bg-gradient-to-b to-gradient1 from-gradient2">
      <div className="container">
        <Feature reversed>
          <>
            <div className="flex flex-col items-center lg:items-start gap-6">
              <Tag>Competitive</Tag>
              <H2>Unlock Your Business Potential with Competitive Rates</H2>
              <PL>
                At REV Capital, we offer competitive rates that help you drive your business
                forward. Our flexible financing solutions are designed to meet your unique needs and
                fuel your growth.
              </PL>
            </div>
            <div className="flex gap-4">
              <div className="flex flex-col gap-2">
                <H6>Lorem Ipsum</H6>
                <PM>
                  Lorem Ipsum Dolor Sit Vet Lorem Ipsum Dolor Sit Vet Lorem Ipsum Dolor Sit Vet{' '}
                </PM>
              </div>
              <div className="flex flex-col gap-2">
                <H6>Lorem Ipsum</H6>
                <PM>
                  Lorem Ipsum Dolor Sit Vet Lorem Ipsum Dolor Sit Vet Lorem Ipsum Dolor Sit Vet{' '}
                </PM>
              </div>
            </div>
          </>
        </Feature>
      </div>
    </div>
  );
};
