import { ColumnDef } from '@tanstack/react-table';
import { PL } from '~/components/ui/typography';

export type ComparisonData = {
  feature: string;
  motion: boolean | string;
  avaal: boolean | string;
};

export const data: ComparisonData[] = [
  {
    feature: 'Transaction Charge',
    motion: '$0.00 / transaction',
    avaal: '$1.75 / transaction',
  },
  {
    feature: 'Credit Check',
    motion: false,
    avaal: true,
  },
  {
    feature: 'Billing Cycle',
    motion: 'Weekly',
    avaal: 'Twice a week',
  },
  {
    feature: 'Account Maintenance Fee',
    motion: '$0.00 / month',
    avaal: '$7.95 / month',
  },

  {
    feature: 'Password Protection',
    motion: true,
    avaal: 'With plugin',
  },
];

export const columns: ColumnDef<ComparisonData>[] = [
  {
    accessorKey: 'feature',
    header: '',
    cell: (info) => <PL className="text-left font-bold text-black">{info.getValue() as string}</PL>,
  },
  {
    accessorKey: 'motion',
    header: () => <span className="text-blue-600">Motion</span>,
    cell: (info) =>
      typeof info.getValue() === 'boolean' ? (info.getValue() ? '✅' : '❌') : info.getValue(),
  },
  {
    accessorKey: 'avaal',
    header: 'Avaal',
    cell: (info) =>
      typeof info.getValue() === 'boolean' ? (info.getValue() ? '✅' : '❌') : info.getValue(),
  },

];
