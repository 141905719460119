import { CheckmarkIcon } from '~/components/icons';
import { Button } from '~/components/ui/button';
import { Menu } from '~/components/ui/menu';
import { H2, PL, PM } from '~/components/ui/typography';

export const Header = () => {
  return (
    <div className="h-[850px] flex flex-col relative md:pt-0">
      <Menu />
      <div className="pt-32 flex-1 relative over">
        <div className="container h-full relative">
          <div className="absolute top-[-70px] left-36 w-64 h-32 bg-gray-300 rounded-3xl hidden xl:block z-10"></div>
          <div className="absolute top-52 left-[-130px] w-64 h-64 bg-gray-300 rounded-md hidden xl:block z-10"></div>
          <div className="absolute bottom-[-180px] left-64 w-64 h-64 bg-gray-300 rounded-md hidden xl:block z-10"></div>
          <div className="absolute top-[-40px] left-[49%] ml-[-135px] w-[173px] h-[173px] bg-gray-300 rounded-md hidden xl:block z-10"></div>
          <div className="absolute bottom-4 right-[432px] w-40 h-28 bg-gray-300 rounded-md hidden xl:block z-10"></div>
          <div className="absolute top-[-130px] right-[200px] w-56 h-56 bg-gray-300 rounded-md hidden xl:block z-10"></div>
          <div className="absolute top-56 right-[-50px] w-56 h-32 bg-gray-300 rounded-md hidden xl:block z-10"></div>
          <div className="absolute bottom-[-60px] right-[-60px] w-64 h-64 bg-gray-300 rounded-md hidden xl:block z-10"></div>

          <div className="max-w-[768px] mx-auto h-full flex flex-col justify-center text-center gap-6">
            <H2>Recover Your Lost Revenue with Fleetio</H2>
            <PL>
              Don't let inefficiencies in your maintenance system drain your profits. Fleetio
              provides the tools and insights you need to optimize your fleet operations and save
              money.
            </PL>
            <div className="flex flex-col justify-center sm:flex-row gap-4 mx-auto lg:m-0">
              <Button buttonSize="large" buttonType="solid" textColor="white">
                Learn More
              </Button>
              <Button buttonSize="large" buttonType="invisible" textColor="primary">
                Contact Us
              </Button>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
              {[0, 1, 2].map((item, i) => {
                return (
                  <PM key={i} className="flex font-semibold gap-2 items-center">
                    <CheckmarkIcon />
                    Lorem ipsum dolor sit
                  </PM>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
